import { FormHelperText, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { FrameMaterial, ReverseFrameMaterial } from "./utils";
import { useState } from "react";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../../FormScreen";
import { WindowsAndDoorsInformation } from "../../../../types/RenovationQuestionnaire";
import NovoTextField from "../../../../components/common/NovoTextField";

export default function DoorsAndWindowsScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [windowsFrameError, setWindowsFrameError] = useState<string>();
  const [otherFrameTypesError, setOtherFrameTypesError] = useState<string>();
  const [windowInstallationYearError, setWindowInstallationYearError] = useState<string>();
  const [doorsMaterialError, setDoorsMaterialError] = useState<string>();
  const [windowsAndDoorsInformation, setWindowsAndDoorsInformation] = useState<WindowsAndDoorsInformation>(
    renovations.answers?.windowsAndDoorsInformation || {},
  );

  const minYear = renovations.constructionYear ?? 1900;
  const maxYear = new Date().getFullYear();

  const validate = () => {
    if (!windowsAndDoorsInformation.windowsFrame) {
      setWindowsFrameError("Bitte auswählen");
      return false;
    } else {
      setWindowsFrameError(undefined);
    }
    if (windowsAndDoorsInformation.otherFrameTypes === undefined) {
      setOtherFrameTypesError("Bitte auswählen");
      return false;
    } else {
      setOtherFrameTypesError(undefined);
    }
    if (!windowsAndDoorsInformation.windowInstallationYear) {
      setWindowInstallationYearError("Pflichtfeld");
      return false;
    } else if (windowsAndDoorsInformation.windowInstallationYear < minYear || windowsAndDoorsInformation.windowInstallationYear > maxYear) {
      setWindowInstallationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
      return false;
    } else {
      setWindowInstallationYearError(undefined);
    }
    if (!windowsAndDoorsInformation.doorsMaterial) {
      setDoorsMaterialError("Bitte auswählen");
      return false;
    } else {
      setDoorsMaterialError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, windowsAndDoorsInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 6: </strong>Informationen zu Fenstern und Haustür
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <NovoSelect
            label={"Material der Fensterrahmen"}
            dataCy={{ field: "window-material-select", values: Object.values(FrameMaterial) }}
            options={[...Object.values(FrameMaterial)]}
            value={windowsAndDoorsInformation.windowsFrame ? FrameMaterial[windowsAndDoorsInformation.windowsFrame as keyof typeof FrameMaterial] : ""}
            onUpdate={(value) => {
              const windowsFrame = ReverseFrameMaterial[value as keyof typeof ReverseFrameMaterial];
              setWindowsAndDoorsInformation({ ...windowsAndDoorsInformation, windowsFrame });
              setWindowsFrameError(undefined);
            }}
            error={!!windowsFrameError}
            helperText={windowsFrameError}
            required
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography fontSize={{ xs: 14, lg: 16 }}>Gibt es unterschiedliche Fenstertypen?</Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={windowsAndDoorsInformation.otherFrameTypes === undefined ? "" : windowsAndDoorsInformation.otherFrameTypes ? "yes" : "no"}
              onChange={(event, value) => {
                if (value === "yes") {
                  setWindowsAndDoorsInformation({ ...windowsAndDoorsInformation, otherFrameTypes: true });
                } else if (value === "no") {
                  setWindowsAndDoorsInformation({ ...windowsAndDoorsInformation, otherFrameTypes: false });
                } else {
                  setWindowsAndDoorsInformation({ ...windowsAndDoorsInformation, otherFrameTypes: undefined });
                }
                setOtherFrameTypesError(undefined);
              }}
            >
              <ToggleButton value='yes' data-cy='different-window-materials-toggle-yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Ja
              </ToggleButton>
              <ToggleButton value='no' data-cy='different-window-materials-toggle-no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Nein
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid
          size={{ xs: 12, lg: 6 }}
          offset={{ xs: 0, lg: 6 }}
          sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (otherFrameTypesError ? `1px solid ${theme.palette.error.main}` : "") }}
        >
          {!!otherFrameTypesError && <FormHelperText error>{otherFrameTypesError}</FormHelperText>}
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <NovoTextField
            fullWidth
            variant='outlined'
            id='windows-installation-year-input'
            data-cy='windows-installation-year-input'
            label='Baujahr Fenster'
            required={true}
            type='number'
            slotProps={{
              htmlInput: { min: minYear, max: maxYear },
            }}
            value={windowsAndDoorsInformation.windowInstallationYear ?? ""}
            onChange={(event) => {
              const windowInstallationYear = parseInt(event.target.value);
              setWindowsAndDoorsInformation({ ...windowsAndDoorsInformation, windowInstallationYear });
              setWindowInstallationYearError(undefined);
            }}
            error={!!windowInstallationYearError}
            helperText={windowInstallationYearError}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <NovoSelect
            label={"Material der Haustür"}
            dataCy={{ field: "door-material-select", values: Object.values(FrameMaterial) }}
            options={[...Object.values(FrameMaterial)]}
            value={windowsAndDoorsInformation.doorsMaterial ? FrameMaterial[windowsAndDoorsInformation.doorsMaterial as keyof typeof FrameMaterial] : ""}
            onUpdate={(value) => {
              const doorsMaterial = ReverseFrameMaterial[value as keyof typeof ReverseFrameMaterial];
              setWindowsAndDoorsInformation({ ...windowsAndDoorsInformation, doorsMaterial });
              setDoorsMaterialError(undefined);
            }}
            error={!!doorsMaterialError}
            helperText={doorsMaterialError}
            required
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Fenster & Haustür'
      progressValue={50}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Über die letzten Jahrzehnte hat sich
      <br />
      die Energieeffizienz von Fenstern
      <br />
      stark erhöht.
      <br />
      <br />
      Durch die Angabe des Baujahres
      <br />
      der Fenster können wir den Ist-Zustand
      <br />
      der Fenster besser beurteilen und
      <br />
      die potentielle Energieeinsparung
      <br />
      durch neue Fenster berechnen.
    </Typography>
  );
}
