import { apiEndpoint } from "../utils/params";
import { UpsertQuestionnaireRequest } from "../types/RenovationQuestionnaire";
import axios from "axios";
import { useState } from "react";
import { retrieveRum } from "../AwsRum";

export const API_ENDPOINT = `${apiEndpoint()}/renovation-questionnaires`;
const LOCAL_STORAGE_KEY = "unsubmittedRenovationId";
const headers = { "Content-Type": "application/json" };

export default function useResumeRenovations() {
  const [resuming, setResuming] = useState(false);

  async function resumeRenovations(leadId?: string): Promise<UpsertQuestionnaireRequest | null> {
    setResuming(true);
    if (leadId) {
      clearLocal();
      return getByLeadId(leadId);
    }
    const id = fromLocal();
    if (id) {
      return getById(id);
    }
    setResuming(false);
    return null;
  }

  async function getByLeadId(leadId: string): Promise<UpsertQuestionnaireRequest | null> {
    return axios
      .get<UpsertQuestionnaireRequest>(`${API_ENDPOINT}?leadId=${leadId}`, { headers })
      .then((response) => response.data)
      .catch((e) => {
        console.error(e);
        retrieveRum()?.recordError(e);
        return null;
      })
      .finally(() => {
        setResuming(false);
      });
  }

  async function getById(id: string): Promise<UpsertQuestionnaireRequest | null> {
    return await axios
      .get<UpsertQuestionnaireRequest>(`${API_ENDPOINT}/${id}`, { headers })
      .then((response) => response.data)
      .catch((e) => {
        console.error(e);
        retrieveRum()?.recordError(e);
        return null;
      })
      .finally(() => {
        setResuming(false);
      });
  }

  function fromLocal() {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  function toLocal(id: string) {
    localStorage.setItem(LOCAL_STORAGE_KEY, id);
  }

  function clearLocal() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  return { resumeRenovations, toLocal, clearLocal, resuming };
}
