export enum Purpose {
  "Modernisierung/Erweiterung" = "Modernisierung-Erweiterung",
  "Vermietung/Verkauf" = "Vermietung-Verkauf",
  "Aushangpflicht" = "Aushangpflicht",
  "Sonstiges" = "Sonstiges",
}
const isPurpose = (maybePurpose: string): maybePurpose is keyof typeof Purpose => {
  return Object.keys(Purpose).includes(maybePurpose);
};
export const getPurpose = (key: string) => {
  const purpose: string | undefined = isPurpose(key) ? Purpose[key] : undefined;
  return purpose;
};

export enum BuildingType {
  "Einfamilienhaus" = "Einfamilienhaus",
  "Zweifamilienhaus" = "Zweifamilienhaus",
  "Mehrfamilienhaus" = "Mehrfamilienhaus",
  "Wohnteil gemischt genutztes Gebäude" = "Wohnteil gemischt genutztes Gebäude",
  "Sonstiges" = "Sonstiges",
}
const isBuildingType = (maybeBuildingType: string): maybeBuildingType is keyof typeof BuildingType => {
  return Object.keys(BuildingType).includes(maybeBuildingType);
};
export const getBuildingType = (key: string) => {
  const buildingType: string | undefined = isBuildingType(key) ? BuildingType[key] : undefined;
  return buildingType;
};

export enum GermanState {
  "Baden-Württemberg" = "Baden-Württemberg",
  "Bayern" = "Bayern",
  "Berlin" = "Berlin",
  "Brandenburg" = "Brandenburg",
  "Bremen" = "Bremen",
  "Hamburg" = "Hamburg",
  "Hessen" = "Hessen",
  "Mecklenburg-Vorpommern" = "Mecklenburg-Vorpommern",
  "Niedersachsen" = "Niedersachsen",
  "Nordrhein-Westfalen" = "Nordrhein-Westfalen",
  "Rheinland-Pfalz" = "Rheinland-Pfalz",
  "Saarland" = "Saarland",
  "Sachsen" = "Sachsen",
  "Sachsen-Anhalt" = "Sachsen-Anhalt",
  "Schleswig-Holstein" = "Schleswig-Holstein",
  "Thüringen" = "Thüringen",
}
const isGermanState = (maybeGermanState: string): maybeGermanState is keyof typeof GermanState => {
  return Object.keys(GermanState).includes(maybeGermanState);
};
export const getGermanState = (key: string) => {
  const germanState: string | undefined = isGermanState(key) ? GermanState[key] : undefined;
  return germanState;
};

export enum Reforms {
  "Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten" = "Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten",
  "Ich weiß es nicht" = "Ich weiß es nicht",
  "Nein" = "Nein",
}

export enum Month {
  "Januar" = "01",
  "Februar" = "02",
  "März" = "03",
  "April" = "04",
  "Mai" = "05",
  "Juni" = "06",
  "Juli" = "07",
  "August" = "08",
  "September" = "09",
  "Oktober" = "10",
  "November" = "11",
  "Dezember" = "12",
}

const isMonth = (maybeMonth: string): maybeMonth is keyof typeof Month => {
  return Object.keys(Month).includes(maybeMonth);
};
export const getMonth = (key: string) => {
  const month: string | undefined = isMonth(key) ? Month[key] : undefined;
  return month;
};
export const getMonthKey = (value: string): keyof typeof Month | undefined => {
  const monthKey: keyof typeof Month | undefined = Object.keys(Month).find((key) => Month[key as keyof typeof Month] === value) as keyof typeof Month;
  return monthKey;
};

export enum HeatingSystem {
  "Heizöl in kWh Heizwert" = "Heizöl in kWh Heizwert",
  "Heizöl in kWh Brennwert" = "Heizöl in kWh Brennwert",
  "Erdgas in kWh Heizwert" = "Erdgas in kWh Heizwert",
  "Erdgas in kWh Brennwert" = "Erdgas in kWh Brennwert",
  "Flüssiggas in kWh Heizwert" = "Flüssiggas in kWh Heizwert",
  "Steinkohle in kWh Heizwert" = "Steinkohle in kWh Heizwert",
  "Braunkohle in kWh Heizwert" = "Braunkohle in kWh Heizwert",
  "Biogas in kWh Heizwert" = "Biogas in kWh Heizwert",
  "Biogas in kWh Brennwert" = "Biogas in kWh Brennwert",
  "Biogas, gebäudenah erzeugt in kWh Heizwert" = "Biogas, gebäudenah erzeugt in kWh Heizwert",
  "Biogas, gebäudenah erzeugt in kWh Brennwert" = "Biogas, gebäudenah erzeugt in kWh Brennwert",
  "biogenes Flüssiggas in kWh Heizwert" = "biogenes Flüssiggas in kWh Heizwert",
  "Bioöl in kWh Heizwert" = "Bioöl in kWh Heizwert",
  "Bioöl in kWh Brennwert" = "Bioöl in kWh Brennwert",
  "Bioöl, gebäudenah erzeugt in kWh Heizwert" = "Bioöl, gebäudenah erzeugt in kWh Heizwert",
  "Bioöl, gebäudenah erzeugt in kWh Brennwert" = "Bioöl, gebäudenah erzeugt in kWh Brennwert",
  "Holz in kWh Heizwert" = "Holz in kWh Heizwert",
  "Holz in kWh Brennwert" = "Holz in kWh Brennwert",
  "Strom netzbezogen in kWh" = "Strom netzbezogen in kWh",
  "Strom gebäudenah erzeugt (aus Photovoltaik, Windkraft) in kWh" = "Strom gebäudenah erzeugt (aus Photovoltaik, Windkraft) in kWh",
  "Verdrängungsstrommix für KWK in kWh" = "Verdrängungsstrommix für KWK in kWh",
  "Wärme (Erdwärme, Geothermie, Solarthermie, Umgebungswärme) in kWh" = "Wärme (Erdwärme, Geothermie, Solarthermie, Umgebungswärme) in kWh",
  "Kälte (Erdkälte, Umgebungskälte) in kWh" = "Kälte (Erdkälte, Umgebungskälte) in kWh",
  "Abwärme aus Prozessen (prod) in kWh" = "Abwärme aus Prozessen (prod) in kWh",
  "Abwärme aus Prozessen (out) in kWh" = "Abwärme aus Prozessen (out) in kWh",
  "Wärme aus KWK, gebäudeintegriert oder gebäudenah in kWh" = "Wärme aus KWK, gebäudeintegriert oder gebäudenah in kWh",
  "Wärme aus Verbrennung von Siedlungsabfällen in kWh" = "Wärme aus Verbrennung von Siedlungsabfällen in kWh",
  "Nah_/Fernwärme aus KWK, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh" = "Nah_/Fernwärme aus KWK, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus KWK, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh" = "Nah_/Fernwärme aus KWK, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus KWK, erneuerbarer Brennstoff bzw. Energieträger in kWh" = "Nah_/Fernwärme aus KWK, erneuerbarer Brennstoff bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh" = "Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh" = "Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus Heizwerken, erneuerbarer Brennstoff bzw. Energieträger in kWh" = "Nah_/Fernwärme aus Heizwerken, erneuerbarer Brennstoff bzw. Energieträger in kWh",
}

export enum VentilationType {
  "Fensterlüftung" = "Fensterlüftung",
  "Schachtlüftung" = "Schachtlüftung",
  "Lüftungsanlage mit Wärmerückgewinnung" = "Lüftungsanlage mit Wärmerückgewinnung",
  "Lüftungsanlage ohne Wärmerückgewinnung" = "Lüftungsanlage ohne Wärmerückgewinnung",
}

export enum RenewableEnergySytem {
  "Solarthermie" = "Solarthermie",
  "Photovoltaik" = "Photovoltaik",
  "Wärmepumpe" = "Wärmepumpe",
  "Biomasse" = "Biomasse",
}

export enum RenewableEnergyUsage {
  "Heizung" = "Heizung",
  "Warmwasser" = "Warmwasser",
  "Strom" = "Strom",
}

export enum CoolingType {
  "Kühlung aus Strom" = "Kühlung aus Strom",
  "Gelieferte Kälte" = "Gelieferte Kälte",
  "Kühlung aus Wärme" = "Kühlung aus Wärme",
  "Passive Kühlung" = "Passive Kühlung",
}
