import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { RenovationQuestionnaire } from "../types/RenovationQuestionnaire";

const API_ENDPOINT = `${apiEndpoint()}/renovation-questionnaires`;

export function useFetchRenovationQuestionnaire() {
  const { authAxios, error, running, data } = useAuthAxios<RenovationQuestionnaire>();

  const getRenovation = useCallback(
    async (questionnaireId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${questionnaireId}`,
      }),
    [authAxios],
  );

  return { getRenovation, error, isLoading: running, renovation: data };
}
