import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { Lead, RemoveCustodiansRequest } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/lead`;

type RemoveCustodiansResponseType = {
  lead: Lead;
};
export default function useRemoveLeadCustodians() {
  const { authAxios, error, running, data } = useAuthAxios<RemoveCustodiansResponseType>();

  const removeLeadCustodians = useCallback(
    async (lead: Lead, data: RemoveCustodiansRequest) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/${lead.id}/remove-custodians`,
        data,
      }),
    [authAxios],
  );

  return { removeLeadCustodians, error, isLoading: running, lead: data?.lead };
}
