import { Button, ButtonProps } from "@mui/material";
import { calendarBookingLink } from "./links";

export interface NovoBookAppointmentButtonProps extends ButtonProps {
  tenantName?: string;
  children: React.ReactNode;
}

export const NovoBookAppointmentButton = ({ tenantName, children }: NovoBookAppointmentButtonProps) => {
  const link = calendarBookingLink(tenantName);
  return (
    <Button variant='contained' onClick={() => window.open(link, "_blank")}>
      {children}
    </Button>
  );
};
