import { Checkbox, FormControl, FormControlLabel, FormHelperText, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ChangeEvent, useState } from "react";
import NovoTextField from "../../../../components/common/NovoTextField";
import { HeatingSystemType } from "../../../../types/Schnellcheck";
import FormScreen from "../../FormScreen";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";
import { allHeatingTypes, getHeatingLabel } from "./utils";

export default function Screen2({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, next }: StatusQuoScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [heatingError, setHeatingError] = useState<string>();
  const [yearError, setYearError] = useState<string>();

  const buildingYear = schnellcheck?.answers?.houseDetails?.yearOfConstruction || 1800;
  const currentYear = new Date().getFullYear();

  const updateHeatingSystem = (value: HeatingSystemType) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseHeating: {
          ...schnellcheck.answers?.houseHeating,
          type: value,
        },
      },
    });
    setHeatingError(undefined);
  };

  const updateInstallationYear = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const yearOfInstalation = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseHeating: {
          ...schnellcheck.answers?.houseHeating,
          yearOfInstalation,
        },
      },
    });
    if (yearOfInstalation) {
      setYearError(undefined);
    }
  };

  const validate = () => {
    const { yearOfInstalation, type } = schnellcheck.answers?.houseHeating ?? {};
    if (!type) {
      setHeatingError("Pflichtfeld");
      return false;
    } else {
      setHeatingError(undefined);
    }

    if (!yearOfInstalation) {
      setYearError("Pflichtfeld");
      return false;
    } else if (yearOfInstalation < buildingYear || yearOfInstalation > currentYear) {
      setYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
      return false;
    } else {
      setYearError(undefined);
    }
    return true;
  };

  const validateAndSave = () => {
    if (validate()) {
      next();
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: { xs: 0, lg: 2 } }}>
          <Typography variant='body1' fontSize={isMobile ? 14 : 16}>
            <strong>Schritt 2: </strong>Informationen zum Heizungsystem
          </Typography>
        </Grid>
        <Grid size={12}>
          <FormControl required error={!!heatingError}>
            <Grid
              container
              spacing={{
                xs: 0,
                lg: 2,
              }}
              sx={{
                borderRadius: 1,
                borderWidth: heatingError ? 1 : 0,
                borderStyle: heatingError ? "solid" : "",
                borderColor: "error.main",
              }}
            >
              {allHeatingTypes().map((system) => (
                <Grid key={system} size={{ xs: 6, md: 4 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={`checkbox-${system}`}
                        sx={{ "&.Mui-checked": { color: "text.primary" } }}
                        id={`${system}-heating-system-option`}
                        checked={schnellcheck.answers?.houseHeating?.type === system}
                        data-cy={`${system}-heating-system-option`}
                      />
                    }
                    label={getHeatingLabel(system)}
                    sx={{ "& > span": { fontSize: { xs: 14, lg: 16 } } }}
                    onChange={() => updateHeatingSystem(system)}
                  />
                </Grid>
              ))}
              <Grid size={12} sx={{ mt: { xs: -1, lg: -2 } }}>
                {heatingError && <FormHelperText>{heatingError}</FormHelperText>}
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
            <NovoTextField
              variant='outlined'
              id='installation-year-input'
              data-cy='installation-year-input'
              value={schnellcheck.answers?.houseHeating?.yearOfInstalation ?? ""}
              label='Einbaujahr'
              onChange={updateInstallationYear}
              required={true}
              type='number'
              error={!!yearError}
              helperText={yearError}
              slotProps={{
                htmlInput: { min: buildingYear, max: currentYear },
                inputLabel: { shrink: !!schnellcheck.answers?.houseHeating?.yearOfInstalation },
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='20% geschafft'
      progressValue={20}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Für Heizungen ist zurzeit viel Förderung
      <br />
      verfügbar. Außerdem besteht für ältere
      <br />
      Heizanlagen zum Teil eine
      <br />
      Austausch-Verpflichtung.
      <br />
      <br />
      Wir helfen Ihnen herauszufinden, ob es
      <br />
      sich lohnt, die Effizienz Ihrer Heizung
      <br />
      zu steigern oder diese sogar zu erneuern.
    </Typography>
  );
}
