import Grid from "@mui/material/Grid2";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import { NovoGermanPhoneInput } from "../../../components/common/NovoGermanPhoneInput";
import FormScreen from "../FormScreen";
import { ConsentLabel } from "../../../components/ConsentLabel";
import { useState } from "react";
import NovoTextField from "../../../components/common/NovoTextField";
import { isTelephoneValid } from "../../../utils/utils";
import { isValidEmail } from "../../auth/emailValidator";

export default function CustomerScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [name, setName] = useState<string>(consumptionCertificate.leadName || "");
  const [nameError, setNameError] = useState<string>();
  const [email, setEmail] = useState<string>(consumptionCertificate.leadEmail || "");
  const [emailError, setEmailError] = useState<string>();
  const [telephone, setTelephone] = useState<string>(consumptionCertificate.leadTelephone || "+49");
  const [telephoneError, setTelephoneError] = useState<string>();
  const [termsConsent, setTermsConsent] = useState<boolean>(!!consumptionCertificate.consultantDataReviewConsent);
  const [termsConsentError, setTermsConsentError] = useState<string>();

  const validate = () => {
    if (!name) {
      setNameError("Pflichtfeld");
      return false;
    } else {
      setNameError(undefined);
    }
    if (!email) {
      setEmailError("Pflichtfeld");
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError("Bitte tragen Sie eine gültige E-Mail Adresse ein");
      return false;
    } else {
      setEmailError(undefined);
    }
    if (!telephone || telephone == "+49") {
      setTelephoneError("Pflichtfeld");
      return false;
    } else if (!isTelephoneValid(telephone)) {
      setTelephoneError("Bitte tragen Sie eine gültige Telefonnummer ein");
      return false;
    } else {
      setTelephoneError(undefined);
    }
    if (!termsConsent) {
      setTermsConsentError("Pflichtfeld");
      return false;
    } else {
      setTermsConsentError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState = {
        ...consumptionCertificate,
        leadName: name,
        leadEmail: email,
        leadTelephone: telephone,
        consultantDataReviewConsent: termsConsent,
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Kontaktdaten</strong>
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoTextField
            fullWidth
            variant='outlined'
            id='name-input'
            data-cy='name-input'
            value={name}
            type='text'
            label='Vor- und Nachname'
            onChange={(event) => {
              setName(event.target.value);
              setNameError(undefined);
            }}
            required={true}
            error={!!nameError}
            helperText={nameError}
            slotProps={{
              inputLabel: { shrink: !!name },
            }}
          />
        </Grid>
        <Grid size={6}>
          <NovoTextField
            fullWidth
            variant='outlined'
            id='email-input'
            data-cy='email-input'
            value={email}
            type='email'
            inputMode='email'
            label='Email Adresse'
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailError(undefined);
            }}
            required={true}
            error={!!emailError}
            helperText={emailError}
            slotProps={{
              inputLabel: { shrink: !!email },
            }}
          />
        </Grid>
        <Grid size={6}>
          <NovoGermanPhoneInput
            fullWidth
            variant='outlined'
            id='telephone-input'
            data-cy='telephone-input'
            value={telephone}
            type='tel'
            inputMode='tel'
            label='Telefonnummer'
            onChange={(event) => {
              setTelephone(event.target.value);
              setTelephoneError(undefined);
            }}
            required={true}
            error={!!telephoneError}
            helperText={telephoneError}
            slotProps={{
              inputLabel: { shrink: !!telephone },
            }}
          />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            sx={{ height: "100% " }}
            control={
              <Checkbox
                sx={{ color: termsConsentError ? "error.main" : "default" }}
                data-cy='terms-option-consent'
                name='checkbox-consent'
                required
                checked={termsConsent}
                onChange={(_, checked) => {
                  setTermsConsent(checked);
                  setTermsConsentError(undefined);
                }}
              />
            }
            label={<ConsentLabel fontSize={12} termsConsentError={!!termsConsentError} />}
          />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={validateAndSave} progressLabel='1 von 10' progressValue={5} content={content} isUpdateRunning={isUpdateRunning} />;
}
