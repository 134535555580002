import { Stack, Tooltip, Typography } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { PreferredHeating, RenovationPlans, ReversePreferredHeating, ReverseRenovationPlans } from "./utils";
import NovoSelect from "../../../../components/common/NovoSelect";
import NovoMultiSelect from "../../../../components/common/NovoMultiSelect";
import FormScreen from "../../FormScreen";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { CustomerPreferences } from "../../../../types/RenovationQuestionnaire";
import { useState } from "react";

export default function CustomerPreferencesScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [preferredHeatingError, setPreferredHeatingError] = useState<string>();
  const [renovationPlansError, setRenovationPlansError] = useState<string>();
  const [customerPreferences, setCustomerPreferences] = useState<CustomerPreferences>(renovations.answers?.customerPreferences || {});
  const validate = () => {
    if (!customerPreferences.preferredHeating) {
      setPreferredHeatingError("Bitte auswählen");
      return false;
    }
    if (!customerPreferences.renovationPlans || customerPreferences.renovationPlans.length === 0) {
      setRenovationPlansError("Bitte wählen Sie mindestens eine Option aus");
      return false;
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, customerPreferences } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 9: </strong>Informationen zu Sanierungswünschen
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack>
            <Stack direction='row'>
              <Typography fontSize={{ xs: 14, lg: 16 }}>Was ist Ihr zukünftig bevorzugtes Heizsystem?</Typography>
              <Tooltip title='Wir prüfen unabhängig von Ihrer Angabe, ob Ihre Heizung überhaupt getauscht werden muss.'>
                <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
              </Tooltip>
            </Stack>
            <NovoSelect
              label={"bevorzugtes Heizsystem"}
              dataCy={{ field: "preferred-heating-system-select", values: Object.values(PreferredHeating) }}
              options={[...Object.values(PreferredHeating)]}
              value={customerPreferences.preferredHeating ? PreferredHeating[customerPreferences.preferredHeating as keyof typeof PreferredHeating] : ""}
              onUpdate={(value) => {
                const preferredHeating = ReversePreferredHeating[value as keyof typeof ReversePreferredHeating];
                setCustomerPreferences({ ...customerPreferences, preferredHeating });
                setPreferredHeatingError(undefined);
              }}
              error={!!preferredHeatingError}
              helperText={preferredHeatingError}
              required
              sx={{ mt: 1 }}
            />
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack>
            <Stack>
              <Typography fontSize={{ xs: 14, lg: 16 }}>Welche Maßnahmen möchten Sie umsetzen?</Typography>
              <Typography fontSize={11} fontWeight={400}>
                Mehrfachauswahl möglich
              </Typography>
            </Stack>
            <NovoMultiSelect
              label={"gewünschte Maßnahmen"}
              data-cy='desired-renovations-select'
              options={[...Object.values(RenovationPlans)]}
              value={customerPreferences.renovationPlans?.map((key) => (key ? RenovationPlans[key as keyof typeof RenovationPlans] : "")) || []}
              onUpdate={(value) => {
                const renovationPlans = value.map((val) => ReverseRenovationPlans[val as keyof typeof ReverseRenovationPlans]);
                setCustomerPreferences({ ...customerPreferences, renovationPlans });
                setRenovationPlansError(undefined);
              }}
              error={!!renovationPlansError}
              helperText={renovationPlansError}
              sx={{ mt: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Sanierungswünsche'
      progressValue={75}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Wir wollen Ihnen einen
      <br />
      Sanierungsfahrplanerstellen, der zu
      <br />
      Ihnen passt!
      <br /> <br />
      Dafür ist es wichtig,
      <br />
      dass wir wissen, was Ihnen
      <br />
      wichtig ist und welche
      <br />
      Maßnahmen Sie sich vorstellen
      <br />
      können.
    </Typography>
  );
}
