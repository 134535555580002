import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { AddCustodiansRequest, Lead } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/lead`;

type AddCustodiansResponseType = {
  lead: Lead;
};
export default function useAddLeadCustodians() {
  const { authAxios, error, running, data } = useAuthAxios<AddCustodiansResponseType>();

  const addLeadCustodians = useCallback(
    async (lead: Lead, data: AddCustodiansRequest) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/${lead.id}/add-custodians`,
        data,
      }),
    [authAxios],
  );

  return { addLeadCustodians, error, isLoading: running, lead: data?.lead };
}
