import { apiEndpoint } from "../utils/params";
import { SchnellcheckQuestionnaire, UpsertQuestionnaireRequest } from "../types/Schnellcheck";
import { useUnauthAxios } from "./useUnauthAxios";
import { useCallback } from "react";

const API_ENDPOINT = `${apiEndpoint()}/questionnaires`;
const headers = { "Content-Type": "application/json" };

export default function useUpsertSchnellcheck() {
  const { unauthAxios, error, responseStatus, running, data } = useUnauthAxios<SchnellcheckQuestionnaire>();

  const upsertSchnellcheck = useCallback(
    async (request: UpsertQuestionnaireRequest) => {
      await unauthAxios({
        method: "POST",
        url: API_ENDPOINT,
        data: request,
        headers,
      });
    },
    [unauthAxios],
  );

  return { upsertSchnellcheck, responseStatus, upsertSchnellcheckError: error, upsertSchnellcheckRunning: running, upsertSchnellcheckResponse: data };
}
