import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { CardContent, FormControl, Skeleton } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { subMonths } from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import NovoSelect from "../../../components/common/NovoSelect";
import NovoTextField from "../../../components/common/NovoTextField";
import { ConsumptionCertBuildingImage, useFetchConsumptionCertBuildingImage } from "../../../hooks/useFetchConsumptionCertBuildingImage";
import { useUpdateConsumptionCertAnswers } from "../../../hooks/useUpdateConsumptionCertAnswers";
import { ConsumptionCertificate, ConsumptionCertificateAnswers } from "../../../types/ConsumptionCertificateState";

type ConsumptionCertificateEditableProps = {
  cert: ConsumptionCertificate;
  onChange?: (cert: ConsumptionCertificate) => void;
};

interface SectionTitleProps extends BoxProps {
  open?: boolean;
}

const CollapsibleSectionTitle = styled(Box)<SectionTitleProps>(({ open = false }) => ({
  "&:before": {
    content: open ? '"⏷"' : '"⏵"',
    cursor: "pointer",
    display: "inline-block",
    marginRight: "0.5rem",
    verticalAlign: "text-bottom",
  },
  "& .MuiTypography-root": {
    display: "inline-block",
    cursor: "pointer",
  },
}));

export const heatingSystemOptions = [
  "Heizöl in kWh Heizwert",
  "Heizöl in kWh Brennwert",
  "Erdgas in kWh Heizwert",
  "Erdgas in kWh Brennwert",
  "Flüssiggas in kWh Heizwert",
  "Steinkohle in kWh Heizwert",
  "Braunkohle in kWh Heizwert",
  "Biogas in kWh Heizwert",
  "Biogas in kWh Brennwert",
  "Biogas, gebäudenah erzeugt in kWh Heizwert",
  "Biogas, gebäudenah erzeugt in kWh Brennwert",
  "biogenes Flüssiggas in kWh Heizwert",
  "Bioöl in kWh Heizwert",
  "Bioöl in kWh Brennwert",
  "Bioöl, gebäudenah erzeugt in kWh Heizwert",
  "Bioöl, gebäudenah erzeugt in kWh Brennwert",
  "Holz in kWh Heizwert",
  "Holz in kWh Brennwert",
  "Strom netzbezogen in kWh",
  "Strom gebäudenah erzeugt (aus Photovoltaik, Windkraft) in kWh",
  "Verdrängungsstrommix für KWK in kWh",
  "Wärme (Erdwärme, Geothermie, Solarthermie, Umgebungswärme) in kWh",
  "Kälte (Erdkälte, Umgebungskälte) in kWh",
  "Abwärme aus Prozessen (prod) in kWh",
  "Abwärme aus Prozessen (out) in kWh",
  "Wärme aus KWK, gebäudeintegriert oder gebäudenah in kWh",
  "Wärme aus Verbrennung von Siedlungsabfällen in kWh",
  "Nah_/Fernwärme aus KWK, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus KWK, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus KWK, erneuerbarer Brennstoff bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Stein_/Braunkohle) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus Heizwerken, fossiler Brennstoff (Gasförmige und flüssige Brennstoffe) bzw. Energieträger in kWh",
  "Nah_/Fernwärme aus Heizwerken, erneuerbarer Brennstoff bzw. Energieträger in kWh",
];

interface NbrValidatorOptions {
  minVal?: number;
  maxVal?: number;
  optional?: boolean;
}

const numberValidator = (options?: NbrValidatorOptions) => (input?: number) => {
  if (!options?.optional && !input) {
    return "Pflichtfeld";
  }
  if (!input) {
    return undefined;
  }
  if (options?.minVal != undefined && options.minVal > input) {
    return `Mindestwert: ${options.minVal}`;
  }
  if (options?.maxVal != undefined && options.maxVal < input) {
    return `Maximalwert: ${options.maxVal}`;
  }
  return undefined;
};

type ConsumptionCertAnswersFormState = Pick<
  ConsumptionCertificateAnswers,
  | "area"
  | "verbrauch1"
  | "verbrauch2"
  | "verbrauch3"
  | "heatingSystemType"
  | "secondaryVerbrauch1"
  | "secondaryVerbrauch2"
  | "secondaryVerbrauch3"
  | "secondaryHeatingSystemType"
  | "yearOfBill"
  | "endMonthOfBill"
  | "yearOfHeatingInstalation"
  | "secondaryYearOfHeatingInstalation"
  | "numberOfApartments"
  | "yearOfConstruction"
>;

type ConsumptionCertAnswersFormErrorState = {
  [key in keyof ConsumptionCertAnswersFormState]: string;
};

type ConsumptionCertAnswersFormValidators = {
  // returns a string if there is an error, undefined otherwise
  [key in keyof ConsumptionCertAnswersFormState]: (input?: ConsumptionCertAnswersFormState[key]) => string | undefined;
};

export default function ConsumptionCertificateEditable({ cert, onChange }: ConsumptionCertificateEditableProps) {
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openHeating, setOpenHeating] = useState(false);
  const [openHotWater, setOpenHotWater] = useState(false);
  const [enablePersistChanges, setEnablePersistChanges] = useState(false);
  const [photoLoadInitialState, setPhotoLoadInitialState] = useState(true);

  const { putAnswers, running: isPutAnswersRunning, error: putAnswersError, updatedCert } = useUpdateConsumptionCertAnswers(cert.id);

  const [answersFormState, setAnswersFormState] = useState<ConsumptionCertAnswersFormState>(cert.answers || {});
  const [answersFormErrorState, setAnswersFormErrorState] = useState<ConsumptionCertAnswersFormErrorState>({});

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const secondaryHeatingSystemNbrValidator = (options?: NbrValidatorOptions): ((input: number) => string | undefined) => {
    return answersFormState.secondaryHeatingSystemType ? numberValidator(options) : (input) => (input ? "Bitte leer lassen" : undefined);
  };

  const validators: ConsumptionCertAnswersFormValidators = {
    area: numberValidator({ minVal: 0 }),
    numberOfApartments: numberValidator({ minVal: 0, maxVal: 4 }),
    yearOfConstruction: numberValidator({ minVal: 1900, maxVal: currentYear }),
    yearOfHeatingInstalation: numberValidator({ minVal: 1900, maxVal: currentYear }),
    yearOfBill: (input: number) => {
      return input < subMonths(currentDate, 18).getFullYear() ? "Rechnung zu alt" : currentYear < input ? `Maximalwert: ${currentYear}` : undefined;
    },
    endMonthOfBill: (input: string) => {
      if (!input) {
        return "Pflichtfeld";
      }
      if (!/^[0-9]+$/g.test(input)) {
        return "Eingabe ist keine Zahl";
      }

      const value = parseInt(input);
      if (answersFormState.yearOfBill) {
        const billDate = new Date(`${answersFormState.yearOfBill}-${`${value}`.padStart(2, "0")}`);
        return billDate.getTime() < subMonths(new Date(), 18).getTime() ? "Rechnung zu alt" : numberValidator({ maxVal: 12, minVal: 1 })(value);
      }
      return numberValidator({ maxVal: 12, minVal: 1 })(value);
    },
    verbrauch1: numberValidator({ minVal: 0 }),
    verbrauch2: numberValidator({ minVal: 0 }),
    verbrauch3: numberValidator({ minVal: 0 }),
    secondaryVerbrauch1: secondaryHeatingSystemNbrValidator({ minVal: 0 }),
    secondaryVerbrauch2: secondaryHeatingSystemNbrValidator({ minVal: 0 }),
    secondaryVerbrauch3: secondaryHeatingSystemNbrValidator({ minVal: 0 }),
    secondaryYearOfHeatingInstalation: secondaryHeatingSystemNbrValidator({ minVal: 1900, maxVal: currentYear }),
  };

  const updateNumberFieldValue = (field: keyof ConsumptionCertAnswersFormState) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[0-9]*$/g.test(value)) {
      updateFieldValueViaEvent(field)(event, value ? parseInt(value) : undefined);
    }
  };

  const updateFieldValue = (field: keyof ConsumptionCertAnswersFormState) => (input: string | boolean | number) => {
    setAnswersFormState({
      ...answersFormState,
      [field]: input,
    });
    setAnswersFormErrorState({
      ...answersFormErrorState,
      [field]: undefined,
    });
    setEnablePersistChanges(true);
  };

  const updateFieldValueViaEvent =
    (field: keyof ConsumptionCertAnswersFormState) => (event: ChangeEvent<HTMLInputElement>, value?: string | boolean | number) => {
      updateFieldValue(field)(value !== undefined ? value : event.target.value);
    };

  const isFormValid = (): boolean => {
    const validatorKeys = Object.keys(validators) as Array<keyof ConsumptionCertAnswersFormState>;
    let errors: ConsumptionCertAnswersFormErrorState = {};
    let valid = true;
    validatorKeys.forEach((key) => {
      let validationErr: string | undefined = undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validationErr = validators[key]?.(answersFormState[key] as any);
      if (validationErr) {
        valid = false;
      }
      errors = { ...errors, [key]: validationErr };
    });
    setAnswersFormErrorState(errors);
    return valid;
  };

  useEffect(() => {
    if (updatedCert && onChange) {
      onChange(updatedCert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedCert]);

  useEffect(() => {
    if (putAnswersError) {
      setEnablePersistChanges(true);
    }
  }, [putAnswersError]);

  const cancelEdit = () => {
    setEnablePersistChanges(false);
    setAnswersFormState(cert.answers || {});
    setAnswersFormErrorState({});
  };

  const submitForm = () => {
    if (!isFormValid() || isPutAnswersRunning) {
      return;
    }
    setEnablePersistChanges(false);
    putAnswers({ answers: { ...cert.answers, ...answersFormState }, updateCalculations: true });
  };

  const { fetchBuildingImage, buildingImage, isLoading: isLoadingBuildingImage } = useFetchConsumptionCertBuildingImage();

  useEffect(() => {
    if (isLoadingBuildingImage) {
      setPhotoLoadInitialState(false);
    }
  }, [isLoadingBuildingImage]);

  useEffect(() => {
    fetchBuildingImage(cert.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cert.id]);

  const clickGeneral = () => {
    setOpenGeneral(!openGeneral);
  };

  const clickHeating = () => {
    setOpenHeating(!openHeating);
  };

  const clickHotWater = () => {
    setOpenHotWater(!openHotWater);
  };

  return (
    <Card sx={{ p: 2 }}>
      <CardContent>
        <Stack rowGap={2}>
          <Box display={"flex"} flex={1}>
            <LoadingImage width={200} alt='Gebäudefoto' img={buildingImage} isLoading={isLoadingBuildingImage || photoLoadInitialState} />
            <Grid container rowSpacing={2} pl={4}>
              <Grid size={4}>
                <Typography variant='overline'>Straße & Hausnummer</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.address}
                </Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant='overline'>Ort</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.location}
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant='overline'>PLZ</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.postalCode}
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant='overline'>Bundesland</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.bundesland}
                </Typography>
              </Grid>
              <Grid size={1} />
              <Grid size={4}>
                <Typography variant='overline'>Anlass</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.reason}
                </Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant='overline'>Gebäudetyp</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.typeOfBuilding}
                </Typography>
              </Grid>
              <Grid size={2}>
                <FormControl>
                  <Typography variant='overline'>Wohnfläche</Typography>
                  <NovoTextField
                    sx={{ width: 80 }}
                    size='small'
                    value={answersFormState.area}
                    onChange={updateNumberFieldValue("area")}
                    error={!!answersFormErrorState.area}
                    helperText={answersFormErrorState.area}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid size={2}>
                <Stack>
                  <Typography variant='overline'>Wohnungen</Typography>
                  <FormControl>
                    <NovoTextField
                      sx={{ width: 80 }}
                      size='small'
                      disabled={!answersFormState.numberOfApartments}
                      value={answersFormState.numberOfApartments}
                      onChange={updateNumberFieldValue("numberOfApartments")}
                      error={!!answersFormErrorState.numberOfApartments}
                      helperText={answersFormErrorState.numberOfApartments}
                      required
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid size={1} />
              <Grid size={4}>
                <Stack>
                  <Typography variant='overline'>Baujahr</Typography>
                  <FormControl>
                    <NovoTextField
                      sx={{ width: 100 }}
                      size='small'
                      disabled={!answersFormState.yearOfConstruction}
                      value={answersFormState.yearOfConstruction}
                      onChange={updateNumberFieldValue("yearOfConstruction")}
                      error={!!answersFormErrorState.yearOfConstruction}
                      helperText={answersFormErrorState.yearOfConstruction}
                      required
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid size={3}>
                <Typography variant='overline'>Wärmeschutzverordnung</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert.answers.reform}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid size={12}>
              <CollapsibleSectionTitle open={openGeneral} onClick={clickGeneral}>
                <Typography variant='subtitle1' onClick={clickGeneral}>
                  Allgemein
                </Typography>
              </CollapsibleSectionTitle>
            </Grid>
            <Grid size={12}>
              <Collapse in={openGeneral}>
                <Grid container spacing={2} mx={2}>
                  <Grid size={4}>
                    <Typography variant='overline'>Art der Lüftung</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert.answers.ventilationType?.join() || "-"}
                    </Typography>
                  </Grid>
                  <Grid size={4}>
                    <Typography variant='overline'>Kühlung</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert.answers.coolingSystems?.map((cooling) => `${cooling.system}: ${cooling.surface}m²`).join() ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid size={4}>
                    <Typography variant='overline'>Erneuerbare Energien</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert.answers.renewable?.join() || "-"}
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Typography variant='overline'>Sanierungsmaßnahmen</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert.answers.renovationMeasures}
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid size={12}>
              <CollapsibleSectionTitle open={openHeating} onClick={clickHeating}>
                <Typography variant='subtitle1' onClick={clickHeating}>
                  Heizung
                </Typography>
              </CollapsibleSectionTitle>
            </Grid>
            <Grid size={12}>
              <Collapse in={openHeating}>
                <Grid container spacing={2} mx={2}>
                  <Grid size={4}>
                    <Stack>
                      <Typography variant='overline'>Heizsystem</Typography>
                      <NovoSelect
                        label=''
                        size='small'
                        value={answersFormState.heatingSystemType || ""}
                        options={heatingSystemOptions}
                        required={false}
                        onUpdate={updateFieldValue("heatingSystemType")}
                      />
                    </Stack>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Monat</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 70 }}
                          size='small'
                          value={answersFormState.endMonthOfBill}
                          onChange={updateFieldValueViaEvent("endMonthOfBill")}
                          error={!!answersFormErrorState.endMonthOfBill}
                          helperText={answersFormErrorState.endMonthOfBill}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Jahr</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          value={answersFormState.yearOfBill}
                          onChange={updateNumberFieldValue("yearOfBill")}
                          error={!!answersFormErrorState.yearOfBill}
                          helperText={answersFormErrorState.yearOfBill}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Verbrauch</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          value={answersFormState.verbrauch1}
                          onChange={updateNumberFieldValue("verbrauch1")}
                          error={!!answersFormErrorState.verbrauch1}
                          helperText={answersFormErrorState.verbrauch1}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2} />
                  <Grid size={4}>
                    <Stack>
                      <Typography variant='overline'>Jahr der Installation</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          value={answersFormState.yearOfHeatingInstalation}
                          onChange={updateNumberFieldValue("yearOfHeatingInstalation")}
                          error={!!answersFormErrorState.yearOfHeatingInstalation}
                          helperText={answersFormErrorState.yearOfHeatingInstalation}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {answersFormState.endMonthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? answersFormState.yearOfBill! - 1 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Verbrauch</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          value={answersFormState.verbrauch2}
                          onChange={updateNumberFieldValue("verbrauch2")}
                          error={!!answersFormErrorState.verbrauch2}
                          helperText={answersFormErrorState.verbrauch2}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2} />
                  <Grid size={4} />
                  <Grid size={2}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {answersFormState.endMonthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? answersFormState.yearOfBill! - 2 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Verbrauch</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          value={answersFormState.verbrauch3}
                          onChange={updateNumberFieldValue("verbrauch3")}
                          error={!!answersFormErrorState.verbrauch3}
                          helperText={answersFormErrorState.verbrauch3}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2} />
                  <Grid size={12} />
                  <Grid size={4}>
                    <Stack>
                      <Typography variant='overline'>Zweites Heizsystem (Optional)</Typography>
                      <NovoSelect
                        label=''
                        size='small'
                        value={answersFormState.secondaryHeatingSystemType || ""}
                        options={["", ...heatingSystemOptions]}
                        required={false}
                        onUpdate={updateFieldValue("secondaryHeatingSystemType")}
                      />
                    </Stack>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {answersFormState.endMonthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {answersFormState.yearOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Verbrauch</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          disabled={!answersFormState.secondaryHeatingSystemType}
                          size='small'
                          value={answersFormState.secondaryVerbrauch1}
                          onChange={updateNumberFieldValue("secondaryVerbrauch1")}
                          error={!!answersFormErrorState.secondaryVerbrauch1}
                          helperText={answersFormErrorState.secondaryVerbrauch1}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2} />
                  <Grid size={4}>
                    <Stack>
                      <Typography variant='overline'>Jahr der Installation</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          disabled={!answersFormState.secondaryHeatingSystemType}
                          value={answersFormState.secondaryYearOfHeatingInstalation}
                          onChange={updateNumberFieldValue("secondaryYearOfHeatingInstalation")}
                          error={!!answersFormErrorState.secondaryYearOfHeatingInstalation}
                          helperText={answersFormErrorState.secondaryYearOfHeatingInstalation}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {answersFormState.endMonthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? answersFormState.yearOfBill! - 1 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Verbrauch</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          disabled={!answersFormState.secondaryHeatingSystemType}
                          value={answersFormState.secondaryVerbrauch2}
                          onChange={updateNumberFieldValue("secondaryVerbrauch2")}
                          error={!!answersFormErrorState.secondaryVerbrauch2}
                          helperText={answersFormErrorState.secondaryVerbrauch2}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2} />
                  <Grid size={4} />
                  <Grid size={2}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {answersFormState.endMonthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? answersFormState.yearOfBill! - 2 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Stack>
                      <Typography variant='overline'>Verbrauch</Typography>
                      <FormControl>
                        <NovoTextField
                          sx={{ width: 100 }}
                          size='small'
                          disabled={!answersFormState.secondaryHeatingSystemType}
                          value={answersFormState.secondaryVerbrauch3}
                          onChange={updateNumberFieldValue("secondaryVerbrauch3")}
                          error={!!answersFormErrorState.secondaryVerbrauch3}
                          helperText={answersFormErrorState.secondaryVerbrauch3}
                          required
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid size={2} />
                </Grid>
              </Collapse>
            </Grid>
            <Grid size={12}>
              <CollapsibleSectionTitle open={openHotWater} onClick={clickHotWater}>
                <Typography variant='subtitle1' onClick={clickHotWater}>
                  Warmwasserdaten
                </Typography>
              </CollapsibleSectionTitle>
            </Grid>
            <Grid size={12}>
              <Collapse in={openHotWater}>
                <Grid container spacing={2} mx={2}>
                  {cert.answers.hotWaterSystems?.map((system, index) => (
                    <Grid size={4} key={index}>
                      <Stack>
                        <Typography variant='overline'>Warmwassersystem {index + 1}</Typography>
                        <Typography variant='body2' fontWeight={600}>
                          {system.type}
                        </Typography>
                        {system.isPartOfHeating && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                            <Typography variant='body2' sx={{ color: "success.main" }}>
                              Identisch mit dem Heizsystem
                            </Typography>
                          </Stack>
                        )}
                        {!system.isPartOfHeating && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CancelIcon sx={{ color: "text.secondary" }} />
                            <Typography variant='body2' sx={{ color: "text.secondary" }}>
                              Identisch mit dem Heizsystem
                            </Typography>
                          </Stack>
                        )}
                        {!system.isPartOfHeating && system.isCentralized && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                            <Typography variant='body2' sx={{ color: "success.main" }}>
                              Zentrale Warmwassererwärmung
                            </Typography>
                          </Stack>
                        )}
                        {!system.isPartOfHeating && !system.isCentralized && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CancelIcon sx={{ color: "text.secondary" }} />
                            <Typography variant='body2' sx={{ color: "text.secondary" }}>
                              Zentrale Warmwassererwärmung
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
          <Grid flex={1} flexDirection={"row-reverse"} container>
            <ButtonWithSpinner variant='contained' label={"Speichern"} disabled={!enablePersistChanges} loading={isPutAnswersRunning} onClick={submitForm} />
            {enablePersistChanges && (
              <ButtonWithSpinner variant='outlined' sx={{ mr: 2 }} disabled={!enablePersistChanges} label={"Änderungen verwerfen"} onClick={cancelEdit} />
            )}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}

type LoadingImageProps = {
  img?: ConsumptionCertBuildingImage;
  width?: number;
  alt?: string;
  isLoading: boolean;
};

const LoadingImage = ({ img, width, alt, isLoading }: LoadingImageProps) => {
  width = width ? width : 200;
  if (isLoading) {
    return <Skeleton width={width} height={width ? width * 1 : 40} animation='wave' />;
  }

  if (!img) {
    return (
      <Typography fontSize={width} sx={{ color: "#acacac" }}>
        <PhotoCameraIcon fontSize='inherit' />
      </Typography>
    );
  }

  return (
    <>
      {img.url && (
        <Box
          sx={{
            maxWidth: 250,
            cursor: "pointer",
          }}
        >
          <a target='_blank' rel='noopener noreferrer' href={img.url} download={img.url.split("/").pop()}>
            <img width={width} alt={alt} src={img.url} />
          </a>
        </Box>
      )}
    </>
  );
};
