import Grid from "@mui/material/Grid2";
import { Button, FormControl, FormHelperText, IconButton, MenuItem, Paper, Select, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import DeleteIcon from "@mui/icons-material/Delete";
import { HeatingSystem } from "./ConsumptionCertificateRequestEnums";
import { ChangeEvent, useState } from "react";
import { HotWaterSystem, UpsertConsumptionCertificate } from "./types";

export default function HotwaterScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [hotWaterSystems, setHotWaterSystems] = useState<HotWaterSystem[]>(consumptionCertificate.answers?.hotWaterSystems ?? []);
  const [systemType, setSystemType] = useState<string>("");
  const [hotWaterSystemsError, setHotWaterSystemsError] = useState<string>();
  const [showCardErrors, setShowCardErrors] = useState<boolean>(false);

  const addSystem = () => {
    const matchesHeating =
      consumptionCertificate.answers?.heatingSystemType === systemType || consumptionCertificate.answers?.secondaryHeatingSystemType === systemType;
    setHotWaterSystems([...hotWaterSystems, { type: systemType, isPartOfHeating: matchesHeating ? undefined : false }]);
    setSystemType("");
  };
  const removeSystemFactory = (index: number) => {
    return () => {
      hotWaterSystems.splice(index, 1);
      setHotWaterSystems([...hotWaterSystems]);
    };
  };
  const updateSystemFactory = (index: number) => {
    return (system: HotWaterSystem) => {
      hotWaterSystems[index] = system;
      setHotWaterSystems([...hotWaterSystems]);
    };
  };

  const validate = () => {
    if (hotWaterSystems.length === 0) {
      setHotWaterSystemsError("Pflichtfeld");
      return false;
    } else if (
      hotWaterSystems.some((system) => system.isPartOfHeating === undefined || (system.isPartOfHeating === false && system.isCentralized === undefined))
    ) {
      setShowCardErrors(true);
      return false;
    } else {
      setHotWaterSystemsError(undefined);
      setShowCardErrors(false);
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
          hotWaterSystems,
        },
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Warmwassersysteme</strong>
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack spacing={2}>
            <Typography variant='body1'>
              Bitte teile uns hier mit, über welche Energiequelle dein Warmwasser erhitzt wird. <sup>*</sup>
            </Typography>
            {hotWaterSystems.map((system, index) => (
              <HotWaterSystemCard
                key={index}
                system={system}
                remove={removeSystemFactory(index)}
                update={updateSystemFactory(index)}
                showErrors={showCardErrors}
              />
            ))}
            <FormControl error={!!hotWaterSystemsError} fullWidth>
              <Select data-cy='hotwater-select' value={systemType} onChange={(event: ChangeEvent<HTMLInputElement>) => setSystemType(event.target.value)}>
                {Object.values(HeatingSystem).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {!!hotWaterSystemsError && <FormHelperText>{hotWaterSystemsError}</FormHelperText>}
            </FormControl>
            <Stack direction='row'>
              <Button data-cy='hotwater-add-btn' color='secondary' onClick={addSystem} disabled={!systemType}>
                + Hinzufügen
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={validateAndSave} progressLabel='6 von 10' progressValue={60} isUpdateRunning={isUpdateRunning} content={content} />;
}

function HotWaterSystemCard({
  system,
  remove,
  update,
  showErrors,
}: {
  system: HotWaterSystem;
  remove: () => void;
  update: (system: HotWaterSystem) => void;
  showErrors: boolean;
}) {
  return (
    <Stack component={Paper} spacing={2} p={2}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>{system.type}</Typography>
        <Stack>
          <IconButton aria-label='delete' onClick={remove}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='baseline' spacing={1}>
        <Typography>Identisch mit dem Heizsystem?</Typography>
        <Stack>
          <ToggleButtonGroup
            color='secondary'
            value={system.isPartOfHeating === undefined ? "" : system.isPartOfHeating ? "yes" : "no"}
            size='small'
            exclusive
            onChange={(_, value) => {
              if (!value) return;
              update({
                ...system,
                isPartOfHeating: value === "yes",
                isCentralized: undefined,
              });
            }}
          >
            <ToggleButton data-cy='hotwater-system-partOfHeating-yes' value='yes'>
              Ja
            </ToggleButton>
            <ToggleButton data-cy='hotwater-system-partOfHeating-no' value='no'>
              Nein
            </ToggleButton>
          </ToggleButtonGroup>
          <FormHelperText error={showErrors}>{showErrors && system.isPartOfHeating === undefined ? "Pflichtfeld" : " "}</FormHelperText>
        </Stack>
      </Stack>
      {system.isPartOfHeating === false && (
        <Stack direction='row' alignItems='baseline' spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
          <Typography>{"Ist die Warmwassererwärmung zentral oder dezentral (zB. Durchlauferhitzer)?"}</Typography>
          <Stack>
            <ToggleButtonGroup
              color='secondary'
              value={system.isCentralized === undefined ? "" : system.isCentralized ? "centralized" : "decentralized"}
              size='small'
              exclusive
              onChange={(_, value) => {
                if (!value) return;
                update({
                  ...system,
                  isCentralized: value === "centralized",
                });
              }}
            >
              <ToggleButton data-cy='hotwater-system-centralized' value='centralized'>
                Zentral
              </ToggleButton>
              <ToggleButton data-cy='hotwater-system-decentralized' value='decentralized'>
                Dezentral
              </ToggleButton>
            </ToggleButtonGroup>
            <FormHelperText error={showErrors}>{showErrors && system.isCentralized === undefined ? "Pflichtfeld" : " "}</FormHelperText>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
