import LaunchIcon from "@mui/icons-material/Launch";
import { Stack, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { formatDate } from "date-fns";
import { useEffect, useState } from "react";
import { useFetchRenovationQuestionnaire } from "../../../../hooks/useFetchRenovationQuestionnaire";
import { useFetchLead } from "../../../../hooks/useFetchLead";
import { DisplayBubbleFlowRenovationDialog } from "../../leads/leadlist/DisplayBubbleFlowRenovationDialog";
import { RowDetailProps } from "./types";
import { DisplayRenovationDialog } from "../../leads/leadlist/DisplayRenovationDialog";
import Loading from "../../../../components/common/Loading";

export default function IsfpRenovationAnswered({ row }: RowDetailProps) {
  const { getRenovation, isLoading, renovation } = useFetchRenovationQuestionnaire();
  const [isRenovationOpen, setRenovationOpen] = useState(false);
  const { getLead, lead } = useFetchLead();
  const [isBubbleRenovationOpen, setBubbleRenovationOpen] = useState(false);

  const toggleRenovationModal = () => {
    setRenovationOpen(!isRenovationOpen);
  };

  const toggleBubbleRenovationModal = () => {
    setBubbleRenovationOpen(!isBubbleRenovationOpen);
  };

  const openQuestionnaireDetails = async (questionnaireId?: string) => {
    if (!questionnaireId) {
      return;
    }
    getRenovation(questionnaireId);
  };

  const openBubbleQuestionnaireDetails = async (leadId?: string) => {
    if (!leadId) {
      return;
    }
    getLead(leadId);
  };

  useEffect(() => {
    setRenovationOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renovation]);

  useEffect(() => {
    setBubbleRenovationOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <Stack direction='row' flexWrap='wrap'>
      <Typography>iSFP-Fragebogen wurde</Typography>
      {row.eventTimestamps?.renovationQuestionaireFinishedOn && (
        <>
          &nbsp;
          <Typography>am</Typography>
          &nbsp;
          <Typography sx={{ fontStyle: "italic" }}>{formatDate(row.eventTimestamps?.renovationQuestionaireFinishedOn, "dd.MM.yyyy")}</Typography>
        </>
      )}
      &nbsp;
      <Typography>ausgefüllt.</Typography>
      {row.renovationQuestionnaireId && (
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
            pl: 2,
          }}
          onClick={() => openQuestionnaireDetails(row.renovationQuestionnaireId)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
      )}
      {!row.renovationQuestionnaireId && (
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
            pl: 2,
          }}
          onClick={() => openBubbleQuestionnaireDetails(row.leadId)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
      )}
      <DisplayRenovationDialog renovation={renovation!} open={isRenovationOpen} handleClose={toggleRenovationModal} />
      <DisplayBubbleFlowRenovationDialog
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bubbleFlow={lead?.properties?.find((property) => property.isfp?.bubbleFlow)?.isfp?.bubbleFlow!}
        open={isBubbleRenovationOpen}
        handleClose={toggleBubbleRenovationModal}
      />
      {isLoading && <Loading />}
    </Stack>
  );
}
