import { Box, Button, Card, CardActions, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { ErrorControlState, useFormErrorControl } from "../../../forms/errorControls";
import { ComponentEquipmentEnum, ModernisationRecommendationState, RenovationCombiEnum } from "../../../types/ConsumptionCertificateState";

export type ModernisationRecComponentState = {
  modernisation: ModernisationRecommendationState;
  startInEditMode?: boolean;
  onChange: (state: ModernisationRecommendationState) => void;
  onCancel?: (state: ModernisationRecommendationState) => void;
  onDelete?: (state: ModernisationRecommendationState) => void;
};

export const ModernisationRecommendation: FunctionComponent<ModernisationRecComponentState> = ({
  modernisation,
  startInEditMode,
  onChange,
  onCancel,
  onDelete,
}) => {
  const [currModernisationState, setModernisationState] = useState<ModernisationRecommendationState>(modernisation);
  const [editingModernisationState, setEditingModernisationState] = useState<ModernisationRecommendationState>(modernisation);
  const [isEditing, setIsEditing] = useState(startInEditMode);

  const errorControls: { [key in keyof ModernisationRecommendationState]?: ErrorControlState<unknown> } = {
    componentEquipment: useFormErrorControl((val: string) => !!val && /.+/.test(val), "Bitte wählen sie einen Wert aus"),
    renovationCombination: useFormErrorControl((val: string) => !!val && /.+/.test(val), "Bitte wählen sie einen Wert aus"),
    description: useFormErrorControl((val: string) => !!val && /.{5,225}/.test(val), "Pflichteingabe - mindestens 5 und maximal 225 Zeichen"),
  };

  const setFieldValidity = (fieldName: keyof ModernisationRecommendationState, fieldValue: unknown) => {
    if (errorControls[fieldName]) {
      const errorCtrl = errorControls[fieldName];
      const isValid = errorCtrl?.isValid(fieldValue);
      errorCtrl?.setIsError(!isValid);
      errorCtrl?.setErrorMsg(isValid ? "" : errorCtrl.errorMsg);
    }
  };

  const handleFieldChange = (fieldName: keyof ModernisationRecommendationState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValidity(fieldName, event.target.value);
    setEditingModernisationState({ ...editingModernisationState, [fieldName]: event.target.value });
  };

  const onValuePersist = () => {
    // perform a validation check against fields
    let allValid = true;
    for (const fieldName of Object.keys(errorControls)) {
      const typedFieldName: keyof ModernisationRecommendationState = fieldName as keyof ModernisationRecommendationState;
      const ctrl = errorControls[typedFieldName];
      const fieldValue = editingModernisationState[typedFieldName];
      const isValid = ctrl?.isValid(fieldValue);
      // toggle all valid state if one of them is not valid
      allValid = !isValid ? false : allValid;

      setFieldValidity(typedFieldName, fieldValue);
    }
    if (allValid) {
      setIsEditing(false);
      setModernisationState(editingModernisationState);
      onChange(editingModernisationState);
    }
  };

  const onEditClick = () => {
    setEditingModernisationState(currModernisationState);
    setIsEditing(true);
  };

  const onCancelClick = () => {
    setIsEditing(false);
    if (onCancel) {
      onCancel(editingModernisationState);
    }
  };

  const onDeleteClick = () => {
    if (onDelete) {
      onDelete(currModernisationState);
    }
  };

  if (!isEditing) {
    return (
      <Card sx={{ p: 1 }}>
        <CardContent>
          <Grid container justifyContent={"left"} alignItems={"baseline"}>
            <Grid item>
              <Typography variant='h6' paddingRight={1}>
                {`${currModernisationState.number}. ${currModernisationState.componentEquipment}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2'>({currModernisationState.renovationCombination})</Typography>
            </Grid>
          </Grid>
          <Grid container paddingTop={1}>
            <Grid item>
              <Typography variant='body1'> {currModernisationState.description}</Typography>
            </Grid>
          </Grid>
          {(currModernisationState.amortisation || currModernisationState.specificCosts) && (
            <>
              <Grid item paddingY={1}>
                <Divider />
              </Grid>
              {currModernisationState.amortisation && <Typography variant='body2'>Amortisation: {currModernisationState.amortisation}</Typography>}
              {currModernisationState.specificCosts && <Typography variant='body2'>Spezifische Kosten: {currModernisationState.specificCosts}</Typography>}
            </>
          )}
        </CardContent>
        <CardActions>
          <Box display={"flex"} flex={1} flexDirection={"row-reverse"}>
            <Button sx={{ m: 1 }} variant='contained' onClick={onEditClick}>
              Bearbeiten
            </Button>
            <Button sx={{ m: 1 }} color='secondary' variant='outlined' onClick={onDeleteClick}>
              Löschen
            </Button>
          </Box>
        </CardActions>
      </Card>
    );
  }

  return (
    <Card sx={{ p: 1 }}>
      <CardContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl required error={errorControls.componentEquipment?.isErrorState} sx={{ paddingRight: { md: 2 } }} fullWidth>
              <InputLabel id='componentEquipmentLabel'>Bau/Anlageteil </InputLabel>
              <Select
                label='componentEquipmentLabel'
                error={errorControls.componentEquipment?.isErrorState}
                id='componentEquipment'
                value={editingModernisationState?.componentEquipment ?? ""}
                onChange={handleFieldChange("componentEquipment")}
              >
                {Object.values(ComponentEquipmentEnum).map((enumVal) => (
                  <MenuItem value={enumVal} key={enumVal}>
                    {enumVal}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl required fullWidth>
              <InputLabel error={errorControls.renovationCombination?.isErrorState} id='renovationCombinationLabel'>
                Zusammenhang
              </InputLabel>
              <Select
                label='renovationCombinationLabel'
                id='renovationCombination'
                value={editingModernisationState?.renovationCombination ?? ""}
                onChange={handleFieldChange("renovationCombination")}
                error={errorControls.renovationCombination?.isErrorState}
              >
                {Object.values(RenovationCombiEnum).map((enumVal) => (
                  <MenuItem value={enumVal} key={enumVal}>
                    {enumVal}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label='Beschreibung der Maßnahme'
                multiline
                id='description'
                value={editingModernisationState?.description}
                onChange={handleFieldChange("description")}
                error={errorControls.description?.isErrorState}
                helperText={errorControls.description?.errorMsgState}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ paddingRight: { xs: 0, md: 2 } }} fullWidth>
              <TextField
                label='Amortisation'
                id='amortisation'
                value={editingModernisationState?.amortisation ?? ""}
                onChange={handleFieldChange("amortisation")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label='Spezifische Kosten'
                id='specificCosts'
                value={editingModernisationState?.specificCosts ?? ""}
                onChange={handleFieldChange("specificCosts")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box display={"flex"} mr={1} flex={1} flexDirection={"row-reverse"}>
          <Button variant='contained' onClick={onValuePersist}>
            Fertig
          </Button>
          <Button sx={{ mr: 2 }} variant='outlined' color='secondary' onClick={onCancelClick}>
            Abbrechen
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};
