import { Stack, Typography } from "@mui/material";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpOfferRejected({ row }: RowDetailProps) {
  return (
    <Stack direction='row' flexWrap='wrap'>
      <Typography>Angebot wurde vom Kunden</Typography>
      {row.eventTimestamps?.offerAcceptOrRejectOn && (
        <>
          &nbsp;
          <Typography>am</Typography>
          &nbsp;
          <Typography sx={{ fontStyle: "italic" }}>{formatDate(row.eventTimestamps?.offerAcceptOrRejectOn, "dd.MM.yyyy")}</Typography>
        </>
      )}
      &nbsp;
      <Typography>abgelehnt.</Typography>
    </Stack>
  );
}
