import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import { useState } from "react";
import { UpsertConsumptionCertificate } from "./types";
import useUpsertConsumptionCertificate from "../../../hooks/useUpsertConsumptionCertificate";
import { FileRef } from "../../../components/common/NovoFileUpload";
import NovoImageUpload from "../../../components/common/NovoImageUpload";

export default function BuildingScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [uploading, setUploading] = useState<boolean>(false);
  const [housePhoto, setHousePhoto] = useState<FileRef | undefined>(consumptionCertificate.answers?.housePhoto);
  const [photoError, setPhotoError] = useState<string>();
  const { uploadPhoto } = useUpsertConsumptionCertificate();

  const validate = () => {
    if (!housePhoto) {
      setPhotoError("Pflichtfeld");
      return false;
    } else {
      setPhotoError(undefined);
    }
    return true;
  };
  const upload = async (photo: FileRef) => {
    setUploading(true);
    const fileId = await uploadPhoto(photo);
    setUploading(false);
    return fileId;
  };
  const validateAndSave = () => {
    if (validate()) {
      if (!housePhoto) {
        throw new Error("Foto nicht vorhanden.");
      }
      upload(housePhoto).then((fileId) => {
        const newState: UpsertConsumptionCertificate = {
          ...consumptionCertificate,
          answers: {
            ...consumptionCertificate.answers,
            photoId: fileId,
            housePhoto,
          },
        };
        setConsumptionCertificate(newState);
        next(newState);
      });
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Gebäudefoto</strong>
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoImageUpload
            data-cy='building-photo'
            required
            label='Foto Hochladen'
            value={housePhoto ? [housePhoto] : []}
            onChange={(files: FileRef[]) => {
              setHousePhoto(files.pop());
              setPhotoError(undefined);
            }}
            error={!!photoError}
            helperText={photoError}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      progressLabel='4 von 10'
      progressValue={40}
      isUpdateRunning={uploading || isUpdateRunning}
      content={content}
    />
  );
}
