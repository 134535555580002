import { useCallback } from "react";
import { useUnauthAxios } from "./useUnauthAxios";
import { apiEndpoint } from "../utils/params";
import { ContactRequest } from "../types/types";

export const useSendContactRequested = () => {
  const { unauthAxios, error, running, data } = useUnauthAxios<ContactRequest>();

  const sendContactRequested = useCallback(
    async (payload: ContactRequest) => {
      await unauthAxios({
        method: "POST",
        url: `${apiEndpoint()}/contact`,
        data: payload,
      });
    },
    [unauthAxios],
  );

  return { sendContactRequested, error, running, data };
};
