import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CardContent, Skeleton } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useFetchConsumptionCertBuildingImage } from "../../../hooks/useFetchConsumptionCertBuildingImage";
import { ConsumptionCertificate } from "../../../types/ConsumptionCertificateState";

type ConsumptionCertificateCardProps = {
  cert?: ConsumptionCertificate;
};

interface SectionTitleProps extends BoxProps {
  open?: boolean;
}

const CollapsibleSectionTitle = styled(Box)<SectionTitleProps>(({ open = false }) => ({
  "&:before": {
    content: open ? '"⏷"' : '"⏵"',
    cursor: "pointer",
    display: "inline-block",
    marginRight: "0.5rem",
    verticalAlign: "text-bottom",
  },
  "& .MuiTypography-root": {
    display: "inline-block",
    cursor: "pointer",
  },
}));

export default function ConsumptionCertificateCard({ cert }: ConsumptionCertificateCardProps) {
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openHeating, setOpenHeating] = useState(false);
  const [openHotWater, setOpenHotWater] = useState(false);

  const { fetchBuildingImage, buildingImage } = useFetchConsumptionCertBuildingImage();

  useEffect(() => {
    if (cert?.id) {
      fetchBuildingImage(cert?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cert?.id]);

  const clickGeneral = () => {
    setOpenGeneral(!openGeneral);
  };

  const clickHeating = () => {
    setOpenHeating(!openHeating);
  };

  const clickHotWater = () => {
    setOpenHotWater(!openHotWater);
  };

  return (
    <Card sx={{ p: 2 }}>
      <CardContent>
        <Stack rowGap={2}>
          <Box display={"flex"} flex={1}>
            <Box
              sx={{
                maxWidth: 250,
                cursor: "pointer",
              }}
            >
              <LoadingImage width={200} alt='Gebäudefoto' imgSrc={buildingImage?.url} />
            </Box>
            <Grid container rowSpacing={2} pl={4}>
              <Grid size={4}>
                <Typography variant='overline'>Straße & Hausnummer</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.address}
                </Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant='overline'>Ort</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.location}
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant='overline'>PLZ</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.postalCode}
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant='overline'>Bundesland</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.bundesland}
                </Typography>
              </Grid>
              <Grid size={1} />
              <Grid size={4}>
                <Typography variant='overline'>Anlass</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.reason}
                </Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant='overline'>Gebäudetyp</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.typeOfBuilding}
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant='overline'>Wohnfläche</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.area} m²
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant='overline'>Wohnungen</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.numberOfApartments}
                </Typography>
              </Grid>
              <Grid size={1} />
              <Grid size={4}>
                <Typography variant='overline'>Baujahr</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.yearOfConstruction}
                </Typography>
              </Grid>
              <Grid size={3}>
                <Typography variant='overline'>Wärmeschutzverordnung</Typography>
                <Typography variant='body2' fontWeight={600}>
                  {cert?.answers.reform}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid size={12}>
              <CollapsibleSectionTitle open={openGeneral} onClick={clickGeneral}>
                <Typography variant='subtitle1' onClick={clickGeneral}>
                  Allgemein
                </Typography>
              </CollapsibleSectionTitle>
            </Grid>
            <Grid size={12}>
              <Collapse in={openGeneral}>
                <Grid container spacing={2} mx={2}>
                  <Grid size={4}>
                    <Typography variant='overline'>Art der Lüftung</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.ventilationType?.join() || "-"}
                    </Typography>
                  </Grid>
                  <Grid size={4}>
                    <Typography variant='overline'>Kühlung</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.coolingSystems?.map((cooling) => `${cooling.system}: ${cooling.surface}m²`).join() ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid size={4}>
                    <Typography variant='overline'>Erneuerbare Energien</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.renewable?.join() || "-"}
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Typography variant='overline'>Sanierungsmaßnahmen</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.renovationMeasures}
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid size={12}>
              <CollapsibleSectionTitle open={openHeating} onClick={clickHeating}>
                <Typography variant='subtitle1' onClick={clickHeating}>
                  Heizung
                </Typography>
              </CollapsibleSectionTitle>
            </Grid>
            <Grid size={12}>
              <Collapse in={openHeating}>
                <Grid container spacing={2} mx={2}>
                  <Grid size={4}>
                    <Typography variant='overline'>Heizsystem</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.heatingSystemType}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.endMonthOfBill || cert?.answers.monthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.yearOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Verbrauch</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.verbrauch1}
                    </Typography>
                  </Grid>
                  <Grid size={4} />
                  <Grid size={4}>
                    <Typography variant='overline'>Jahr der Installation</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.yearOfHeatingInstalation}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.endMonthOfBill || cert?.answers.monthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? cert.answers.yearOfBill! - 1 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Verbrauch</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.verbrauch2}
                    </Typography>
                  </Grid>
                  <Grid size={4} />
                  <Grid size={4} />
                  <Grid size={1}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.endMonthOfBill || cert?.answers.monthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? cert.answers.yearOfBill! - 2 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Verbrauch</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.verbrauch3}
                    </Typography>
                  </Grid>
                  <Grid size={4} />
                  <Grid size={12} />
                  <Grid size={4}>
                    <Typography variant='overline'>Zweites Heizsystem</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.secondaryHeatingSystemType}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.endMonthOfBill || cert?.answers.monthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.yearOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Verbrauch</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.secondaryVerbrauch1}
                    </Typography>
                  </Grid>
                  <Grid size={4} />
                  <Grid size={4}>
                    <Typography variant='overline'>Jahr der Installation</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.secondaryYearOfHeatingInstalation}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.endMonthOfBill || cert?.answers.monthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? cert.answers.yearOfBill! - 1 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Verbrauch</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.secondaryVerbrauch2}
                    </Typography>
                  </Grid>
                  <Grid size={4} />
                  <Grid size={4} />
                  <Grid size={1}>
                    <Typography variant='overline'>Monat</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.endMonthOfBill || cert?.answers.monthOfBill}
                    </Typography>
                  </Grid>
                  <Grid size={1}>
                    <Typography variant='overline'>Jahr</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert ? cert.answers.yearOfBill! - 2 : ""}
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant='overline'>Verbrauch</Typography>
                    <Typography variant='body2' fontWeight={600}>
                      {cert?.answers.secondaryVerbrauch3}
                    </Typography>
                  </Grid>
                  <Grid size={4} />
                </Grid>
              </Collapse>
            </Grid>
            <Grid size={12}>
              <CollapsibleSectionTitle open={openHotWater} onClick={clickHotWater}>
                <Typography variant='subtitle1' onClick={clickHotWater}>
                  Warmwasserdaten
                </Typography>
              </CollapsibleSectionTitle>
            </Grid>
            <Grid size={12}>
              <Collapse in={openHotWater}>
                <Grid container spacing={2} mx={2}>
                  {cert?.answers.hotWaterSystems?.map((system, index) => (
                    <Grid size={4} key={index}>
                      <Stack>
                        <Typography variant='overline'>Warmwassersystem {index + 1}</Typography>
                        <Typography variant='body2' fontWeight={600}>
                          {system.type}
                        </Typography>
                        {system.isPartOfHeating && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                            <Typography variant='body2' sx={{ color: "success.main" }}>
                              Identisch mit dem Heizsystem
                            </Typography>
                          </Stack>
                        )}
                        {!system.isPartOfHeating && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CancelIcon sx={{ color: "text.secondary" }} />
                            <Typography variant='body2' sx={{ color: "text.secondary" }}>
                              Identisch mit dem Heizsystem
                            </Typography>
                          </Stack>
                        )}
                        {!system.isPartOfHeating && system.isCentralized && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                            <Typography variant='body2' sx={{ color: "success.main" }}>
                              Zentrale Warmwassererwärmung
                            </Typography>
                          </Stack>
                        )}
                        {!system.isPartOfHeating && !system.isCentralized && (
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <CancelIcon sx={{ color: "text.secondary" }} />
                            <Typography variant='body2' sx={{ color: "text.secondary" }}>
                              Zentrale Warmwassererwärmung
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}

type LoadingImageProps = {
  imgSrc?: string;
  width?: number;
  alt?: string;
};

const LoadingImage = ({ imgSrc, width, alt }: LoadingImageProps) => {
  if (!imgSrc) {
    return <Skeleton width={width ?? 100} height={width ? width * 1 : 40} animation='wave' />;
  }

  return (
    <>
      {imgSrc && (
        <a target='_blank' rel='noopener noreferrer' href={imgSrc} download={imgSrc.split("/").pop()}>
          <img width={width ?? 100} alt={alt} src={imgSrc} />
        </a>
      )}
    </>
  );
};
