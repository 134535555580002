import { Checkbox, Divider, FormControl, FormHelperText, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../../FormScreen";
import InsulationComponent from "./InsulationComponent";
import { AtticOrRoofInformation, RoofTypology } from "../../../../types/RenovationQuestionnaire";
import useImage from "../../../../hooks/useImage";

export default function AtticScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [roofTypologyError, setRoofTypologyError] = useState<string>();
  const [gabledRoofInsulationSelectedError, setGabledRoofInsulationSelectedError] = useState<string>();
  const [gabledRoofInsulationYearError, setGabledRoofInsulationYearError] = useState<string>();
  const [flatRoofInsulationSelectedError, setFlatRoofInsulationSelectedError] = useState<string>();
  const [flatRoofInsulationYearError, setFlatRoofInsulationYearError] = useState<string>();
  const [pitchedRoofInsulationSelectedError, setPitchedRoofInsulationSelectedError] = useState<string>();
  const [pitchedRoofInsulationYearError, setPitchedRoofInsulationYearError] = useState<string>();
  const [coldPitchedRoofInsulationSelectedError, setColdPitchedRoofInsulationSelectedError] = useState<string>();
  const [coldPitchedRoofInsulationYearError, setColdPitchedRoofInsulationYearError] = useState<string>();
  const [coldPitchedCeilingInsulationSelectedError, setColdPitchedCeilingInsulationSelectedError] = useState<string>();
  const [coldPitchedCeilingInsulationYearError, setColdPitchedCeilingInsulationYearError] = useState<string>();
  const [atticOrRoofInformation, setAtticOrRoofInformation] = useState<AtticOrRoofInformation>(renovations.answers?.atticOrRoofInformation || {});

  const roof = atticOrRoofInformation.roofTypology;
  const minYear = renovations.constructionYear ?? 1900;
  const maxYear = new Date().getFullYear();

  const validate = () => {
    if (!atticOrRoofInformation.roofTypology) {
      setRoofTypologyError("Bitte auswählen");
      return false;
    } else {
      setRoofTypologyError(undefined);
    }
    if (roof === "gabled") {
      if (atticOrRoofInformation.gabledRoofInsulation?.installed === undefined) {
        setGabledRoofInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setGabledRoofInsulationSelectedError(undefined);
      }
      if (atticOrRoofInformation.gabledRoofInsulation.installed && !atticOrRoofInformation.gabledRoofInsulation.year) {
        setGabledRoofInsulationYearError("Pflichtfeld");
        return false;
      } else if (atticOrRoofInformation.gabledRoofInsulation.year! < minYear || atticOrRoofInformation.gabledRoofInsulation.year! > maxYear) {
        setGabledRoofInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setGabledRoofInsulationYearError(undefined);
      }
    }
    if (roof === "flat") {
      if (atticOrRoofInformation.flatRoofInsulation?.installed === undefined) {
        setFlatRoofInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setFlatRoofInsulationSelectedError(undefined);
      }
      if (atticOrRoofInformation.flatRoofInsulation.installed && !atticOrRoofInformation.flatRoofInsulation.year) {
        setFlatRoofInsulationYearError("Pflichtfeld");
        return false;
      } else if (atticOrRoofInformation.flatRoofInsulation.year! < minYear || atticOrRoofInformation.flatRoofInsulation.year! > maxYear) {
        setFlatRoofInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setFlatRoofInsulationYearError(undefined);
      }
    }
    if (roof === "pitched") {
      if (atticOrRoofInformation.pitchedRoofInsulation?.installed === undefined) {
        setPitchedRoofInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setPitchedRoofInsulationSelectedError(undefined);
      }
      if (atticOrRoofInformation.pitchedRoofInsulation.installed && !atticOrRoofInformation.pitchedRoofInsulation.year) {
        setPitchedRoofInsulationYearError("Pflichtfeld");
        return false;
      } else if (atticOrRoofInformation.pitchedRoofInsulation.year! < minYear || atticOrRoofInformation.pitchedRoofInsulation.year! > maxYear) {
        setPitchedRoofInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setPitchedRoofInsulationYearError(undefined);
      }
    }
    if (roof === "coldPitched") {
      if (atticOrRoofInformation.coldPitchedRoofInsulation?.installed === undefined) {
        setColdPitchedRoofInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setColdPitchedRoofInsulationSelectedError(undefined);
      }
      if (atticOrRoofInformation.coldPitchedRoofInsulation.installed && !atticOrRoofInformation.coldPitchedRoofInsulation.year) {
        setColdPitchedRoofInsulationYearError("Pflichtfeld");
        return false;
      } else if (atticOrRoofInformation.coldPitchedRoofInsulation.year! < minYear || atticOrRoofInformation.coldPitchedRoofInsulation.year! > maxYear) {
        setColdPitchedRoofInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setColdPitchedRoofInsulationYearError(undefined);
      }
      if (atticOrRoofInformation.coldPitchedCeilingInsulation?.installed === undefined) {
        setColdPitchedCeilingInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setColdPitchedCeilingInsulationSelectedError(undefined);
      }
      if (atticOrRoofInformation.coldPitchedCeilingInsulation.installed && !atticOrRoofInformation.coldPitchedCeilingInsulation.year) {
        setColdPitchedCeilingInsulationYearError("Pflichtfeld");
        return false;
      } else if (atticOrRoofInformation.coldPitchedCeilingInsulation.year! < minYear || atticOrRoofInformation.coldPitchedCeilingInsulation.year! > maxYear) {
        setColdPitchedCeilingInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setColdPitchedCeilingInsulationYearError(undefined);
      }
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, atticOrRoofInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 4: </strong>Informationen zum Dach
          </Typography>
        </Grid>
        <Grid size={12}>
          <RoofTypeSelector
            roofTypology={atticOrRoofInformation.roofTypology}
            setRoofTypology={(roofTypology: RoofTypology) => {
              setAtticOrRoofInformation({ ...atticOrRoofInformation, roofTypology });
              setRoofTypologyError(undefined);
            }}
            typologyError={roofTypologyError}
          />
        </Grid>
        {roof === "gabled" && (
          <Grid size={12}>
            <InsulationComponent
              fadein
              question='Ist die Oberste Geschossdecke gedämmt?'
              picture='House_sections_gabled_roof_insulation_roof.png'
              insulation={atticOrRoofInformation.gabledRoofInsulation}
              setInsulation={(gabledRoofInsulation) => setAtticOrRoofInformation({ ...atticOrRoofInformation, gabledRoofInsulation })}
              selectedError={gabledRoofInsulationSelectedError}
              setSelectedError={setGabledRoofInsulationSelectedError}
              minYear={minYear}
              maxYear={maxYear}
              yearError={gabledRoofInsulationYearError}
              setYearError={setGabledRoofInsulationYearError}
            />
          </Grid>
        )}
        {roof === "flat" && (
          <Grid size={12}>
            <InsulationComponent
              fadein
              question='Ist das Dach gedämmt?'
              picture='House_sections_flat_roof_insulation_roof.png'
              insulation={atticOrRoofInformation.flatRoofInsulation}
              setInsulation={(flatRoofInsulation) => setAtticOrRoofInformation({ ...atticOrRoofInformation, flatRoofInsulation })}
              selectedError={flatRoofInsulationSelectedError}
              setSelectedError={setFlatRoofInsulationSelectedError}
              minYear={minYear}
              maxYear={maxYear}
              yearError={flatRoofInsulationYearError}
              setYearError={setFlatRoofInsulationYearError}
            />
          </Grid>
        )}
        {roof === "pitched" && (
          <Grid size={12}>
            <InsulationComponent
              fadein
              question='Ist das Dach gedämmt?'
              picture='House_sections_heated_attic_insulation_roof.png'
              insulation={atticOrRoofInformation.pitchedRoofInsulation}
              setInsulation={(pitchedRoofInsulation) => setAtticOrRoofInformation({ ...atticOrRoofInformation, pitchedRoofInsulation })}
              selectedError={pitchedRoofInsulationSelectedError}
              setSelectedError={setPitchedRoofInsulationSelectedError}
              minYear={minYear}
              maxYear={maxYear}
              yearError={pitchedRoofInsulationYearError}
              setYearError={setPitchedRoofInsulationYearError}
            />
          </Grid>
        )}
        {roof === "coldPitched" && (
          <>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Sind die Dachschrägen gedämmt?'
                picture='House_sections_unheated_attic_insulation_roof.png'
                insulation={atticOrRoofInformation.coldPitchedRoofInsulation}
                setInsulation={(coldPitchedRoofInsulation) => setAtticOrRoofInformation({ ...atticOrRoofInformation, coldPitchedRoofInsulation })}
                selectedError={coldPitchedRoofInsulationSelectedError}
                setSelectedError={setColdPitchedRoofInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={coldPitchedRoofInsulationYearError}
                setYearError={setColdPitchedRoofInsulationYearError}
              />
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Ist die Oberste Geschossdecke gedämmt?'
                picture='House_sections_unheated_attic_insulation_ceiling.png'
                insulation={atticOrRoofInformation.coldPitchedCeilingInsulation}
                setInsulation={(coldPitchedCeilingInsulation) => setAtticOrRoofInformation({ ...atticOrRoofInformation, coldPitchedCeilingInsulation })}
                selectedError={coldPitchedCeilingInsulationSelectedError}
                setSelectedError={setColdPitchedCeilingInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={coldPitchedCeilingInsulationYearError}
                setYearError={setColdPitchedCeilingInsulationYearError}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Dach'
      progressValue={33}
      content={content}
      helpContent={helpContent}
    />
  );
}

function RoofTypeSelector({
  roofTypology,
  setRoofTypology,
  typologyError,
}: {
  roofTypology?: RoofTypology;
  setRoofTypology: (roofTypology: RoofTypology) => void;
  typologyError?: string;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const pitched = useImage("House_sections_heated_attic.png");
  const coldPitched = useImage("House_sections_unheated_attic.png");
  const gabled = useImage("House_sections_gabled_roof.png");
  const flat = useImage("House_sections_flat_roof.png");
  return (
    <FormControl fullWidth>
      <Grid
        container
        flexGrow={1}
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid size={{ xs: 6, md: 6 }}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%", backgroundColor: roofTypology === "pitched" ? "#00000014" : "" }}
            onClick={() => setRoofTypology("pitched")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={pitched.image} height={isMobile ? 80 : 120} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Schrägdach
              </Typography>
            </Stack>
            <Checkbox sx={{ "&.Mui-checked": { color: "text.primary" } }} checked={roofTypology === "pitched"} onChange={() => setRoofTypology("pitched")} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 6 }}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%", backgroundColor: roofTypology === "coldPitched" ? "#00000014" : "" }}
            onClick={() => setRoofTypology("coldPitched")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={coldPitched.image} height={isMobile ? 80 : 120} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center", textAlign: "center" }}>
                Schrägdach (unbeheizt)
              </Typography>
            </Stack>
            <Checkbox
              sx={{ "&.Mui-checked": { color: "text.primary" } }}
              checked={roofTypology === "coldPitched"}
              onChange={() => setRoofTypology("coldPitched")}
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 6 }}>
          <Stack
            component={Paper}
            direction='row'
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%", backgroundColor: roofTypology === "gabled" ? "#00000014" : "" }}
            onClick={() => setRoofTypology("gabled")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={gabled.image} height={isMobile ? 80 : 120} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Satteldach
              </Typography>
            </Stack>
            <Checkbox sx={{ "&.Mui-checked": { color: "text.primary" } }} checked={roofTypology === "gabled"} onChange={() => setRoofTypology("gabled")} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 6 }}>
          <Stack
            component={Paper}
            direction='row'
            alignItems='center'
            sx={{
              p: { xs: 1, md: 2 },
              cursor: "pointer",
              height: "100%",
              backgroundColor: roofTypology === "flat" ? "#00000014" : "",
            }}
            onClick={() => setRoofTypology("flat")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={flat.image} height={isMobile ? 80 : 120} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Flachdach
              </Typography>
            </Stack>
            <Checkbox
              data-cy='flat-roof-type-toggle'
              sx={{ "&.Mui-checked": { color: "text.primary" } }}
              checked={roofTypology === "flat"}
              onChange={() => setRoofTypology("flat")}
            />
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (typologyError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!typologyError && <FormHelperText error>{typologyError}</FormHelperText>}
        </Grid>
      </Grid>
    </FormControl>
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Über das Dach geht die meiste Wärme
      <br />
      verloren. Daher ist die Dämmung
      <br />
      des Daches bzw. der oberen
      <br />
      Geschossdeckeeine der wirksamsten
      <br />
      energetischen Maßnahmen.
      <br />
      <br />
      Ihre Angaben helfen uns,
      <br />
      die aktuelle Energieeffizienz
      <br />
      Ihres Hauses so präzise wie
      <br />
      möglich zu berechnen.
    </Typography>
  );
}
