import { ConsumptionCertificateItem } from "../ConsumptionCertificateState";
import { RenovationQuestionnaire } from "../RenovationQuestionnaire";
import { SchnellcheckQuestionnaire } from "../Schnellcheck";

export type ReferrerResponse = {
  name: string;
  email: string;
  tenant: TenantBasicInfo;
  cdnUrl: string;
};

export type TenantBasicInfo = {
  id: string;
  name: string;
  displayName: string;
  emailDomain: string;
};

export enum IsfpProcessStatus {
  NOT_STARTED = "not_started",
  INVITE_SENT = "invite_sent",
  SCHNELLCHECK_STARTED = "schnellcheck_started",
  SCHNELLCHECK_FINISHED = "schnellcheck_finished",
  OFFER_REQUESTED = "offer_requested",
  OFFER_ACCEPTED = "offer_accepted",
  OFFER_REJECTED = "offer_rejected",
  RENOVATION_QUESTIONNAIRE_STARTED = "renovation_questionnaire_started",
  RENOVATION_QUESTIONNAIRE_FINISHED = "renovation_questionnaire_finished",
  PREPARING_ISFP_DOCUMENT = "preparing_isfp_document",
  FINISHED = "finished",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IsfpProcessStatus {
  export function toString(state: IsfpProcessStatus): string {
    switch (state) {
      case IsfpProcessStatus.NOT_STARTED:
        return "Noch nicht angefangen";
      case IsfpProcessStatus.INVITE_SENT:
        return "Eingeladen";
      case IsfpProcessStatus.SCHNELLCHECK_STARTED:
        return "Schnellcheck wird ausgefüllt";
      case IsfpProcessStatus.SCHNELLCHECK_FINISHED:
        return "Schnellcheck durchgeführt";
      case IsfpProcessStatus.OFFER_REQUESTED:
        return "iSFP angebot angefordert";
      case IsfpProcessStatus.OFFER_REJECTED:
        return "iSFP angebot abgelehnt";
      case IsfpProcessStatus.OFFER_ACCEPTED:
        return "iSFP beauftragt";
      case IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED:
        return "iSFP-Fragebogen wird ausgefüllt";
      case IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED:
        return "iSFP-Fragebogen durchgefürt";
      case IsfpProcessStatus.PREPARING_ISFP_DOCUMENT:
        return "iSFP Dokument in bearbeitung";
      case IsfpProcessStatus.FINISHED:
        return "iSFP bereitgestellt";
    }
  }

  export function fromString(stateName: string): IsfpProcessStatus | undefined {
    switch (stateName) {
      case "Noch nicht angefangen":
        return IsfpProcessStatus.NOT_STARTED;
      case "Eingeladen":
        return IsfpProcessStatus.INVITE_SENT;
      case "Schnellcheck wird ausgefüllt":
        return IsfpProcessStatus.SCHNELLCHECK_STARTED;
      case "Schnellcheck durchgeführt":
        return IsfpProcessStatus.SCHNELLCHECK_FINISHED;
      case "iSFP angebot angefordert":
        return IsfpProcessStatus.OFFER_REQUESTED;
      case "iSFP beauftragt":
        return IsfpProcessStatus.OFFER_ACCEPTED;
      case "iSFP angebot abgelehnt":
        return IsfpProcessStatus.OFFER_REJECTED;
      case "iSFP-Fragebogen wird ausgefüllt":
        return IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED;
      case "iSFP in bearbeitung":
        return IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED;
      case "iSFP Dokument in bearbeitung":
        return IsfpProcessStatus.PREPARING_ISFP_DOCUMENT;
      case "iSFP bereitgestellt":
        return IsfpProcessStatus.FINISHED;

      default:
        return undefined;
    }
  }

  export const OrderedStatuses = {
    [IsfpProcessStatus.NOT_STARTED]: 1,
    [IsfpProcessStatus.INVITE_SENT]: 2,
    [IsfpProcessStatus.SCHNELLCHECK_STARTED]: 3,
    [IsfpProcessStatus.SCHNELLCHECK_FINISHED]: 4,
    [IsfpProcessStatus.OFFER_REQUESTED]: 5,
    [IsfpProcessStatus.OFFER_ACCEPTED]: 6,
    [IsfpProcessStatus.OFFER_REJECTED]: 6,
    [IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED]: 7,
    [IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED]: 8,
    [IsfpProcessStatus.PREPARING_ISFP_DOCUMENT]: 9,
    [IsfpProcessStatus.FINISHED]: 10,
  };

  export function all(): Array<IsfpProcessStatus> {
    return [
      IsfpProcessStatus.INVITE_SENT,
      IsfpProcessStatus.SCHNELLCHECK_STARTED,
      IsfpProcessStatus.SCHNELLCHECK_FINISHED,
      IsfpProcessStatus.OFFER_REQUESTED,
      IsfpProcessStatus.OFFER_ACCEPTED,
      IsfpProcessStatus.OFFER_REJECTED,
      IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED,
      IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED,
      IsfpProcessStatus.PREPARING_ISFP_DOCUMENT,
      IsfpProcessStatus.FINISHED,
    ];
  }
}

export interface IsfpEventTimestamps {
  invitedOn?: number;
  schnellcheckStartedOn?: number;
  schnellcheckFinishedOn?: number;
  offerRequestedOn?: number;
  offerAcceptOrRejectOn?: number;
  renovationQuestionaireStartedOn?: number;
  renovationQuestionaireFinishedOn?: number;
  isfpDocumentFinishedOn?: number;
}

export interface TenantImages {
  id: string;
  displayName: string;
  logoUrl?: string;
  emailHeaderUrl?: string;
}

export interface IsfpProcess {
  id: string;
  leadId: string;
  leadName: string;
  leadEmail: string;
  leadTenantId?: string;
  leadSource?: LeadSource;
  address?: string;
  referrerEmail?: string;
  schnellCheckId?: string;
  implecoDataId?: string;
  renovationQuestionnaireId?: string;
  isfpDocument?: string;
  status: IsfpProcessStatus;
  eventTimestamps?: IsfpEventTimestamps;
  updatedAt?: number;
  createdAt: number;
}

export interface LegacyBubbleQuestionnaires {
  hasSchnellcheckAnswers?: boolean;
  hasRenovationQuestionnaireAnswers?: boolean;
}

export interface ConsultantWithMetrics {
  name?: string;
  email: string;
  leadCount?: number;
  invites?: number;
  invitesAccepted?: number;
  phoneNumber?: string;
  lastLoggedInOn?: number;
  registeredOn?: number;
  lastActivity?: number;
}

export enum ConsultantInvitationResponseCodes {
  MISSING_INVITATION_EMAIL = "missing-invitation-email",
  INVALID_INVITATION_DOMAIN = "invalid-invitation-domain",
  INVITED_BY_ANOTHER_MANAGER = "invited-by-another-manager",
  ADDED_TO_MANAGER = "added-to-manager",
  ALREADY_INVITED_BY_MANAGER = "already-invited-by-manager",
  SUCCESS = "success",
}

export interface CreateLeadRequest {
  name: string;
  email: string;
  telephone?: string;
  cc: boolean;
  referrerProduct: ProductRequest;
}

export interface ProductRequest {
  product: Product;
  isFree: boolean;
  createdAt?: number;
}

export interface InviteEmailTemplate {
  emailMessage: string;
}

export enum Product {
  onePager = "onePager",
  isfp = "isfp",
  consumptionCertificate = "consumptionCertificate",
  requirementCertificate = "requirementCertificate",
}

export enum CCState {
  START,
  INVITED,
  SUBMITTED,
  FINAL,
}

export enum Steps {
  introduction = "#introduction",
  start = "",
  heating = "#heating",
  insulation = "#insulation",
  openings = "#openings",
  report = "#report",
  offerRequest = "#offer-request",
  confirmation = "#confirmation",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CCState {
  export function toString(state: CCState): string {
    switch (state) {
      case CCState.START:
        return "zum Energieausweis einladen";
      case CCState.INVITED:
        return "zum Energieausweis eingeladen";
      case CCState.SUBMITTED:
        return "Einladung angenommen";
      case CCState.FINAL:
        return "Energieausweis bereitgestellt";
    }
  }

  export function fromString(stateName: string): CCState | undefined {
    switch (stateName) {
      case "zum Energieausweis einladen":
        return CCState.START;
      case "zum Energieausweis eingeladen":
        return CCState.INVITED;
      case "Einladung angenommen":
        return CCState.SUBMITTED;
      case "Energieausweis bereitgestellt":
        return CCState.FINAL;
      default:
        return undefined;
    }
  }

  export function all(): Array<CCState> {
    return [CCState.START, CCState.INVITED, CCState.SUBMITTED, CCState.FINAL];
  }
}

export enum IsfpState {
  START,
  INVITED,
  STATUS_QUO_STARTED,
  STATUS_QUO_FINISHED,
  OFFER_REQUESTED,
  OFFER_ACCEPTED,
  OFFER_REJECTED,
  RENOVATIONS_STARTED,
  RENOVATIONS_FINISHED,
  FINAL,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IsfpState {
  export function toString(state: IsfpState): string {
    switch (state) {
      case IsfpState.START:
        return "zum Schnellcheck einladen";
      case IsfpState.INVITED:
        return "zum Schnellcheck eingeladen";
      case IsfpState.STATUS_QUO_STARTED:
        return "Schnellcheck wird ausgefüllt";
      case IsfpState.STATUS_QUO_FINISHED:
        return "Schnellcheck durchgeführt";
      case IsfpState.OFFER_REQUESTED:
        return "iSFP Angebot angefordert";
      case IsfpState.OFFER_ACCEPTED:
        return "iSFP beauftragt";
      case IsfpState.OFFER_REJECTED:
        return "iSFP Angebot abgelehnt";
      case IsfpState.RENOVATIONS_STARTED:
        return "Dateneingabe für iSFP angefangen";
      case IsfpState.RENOVATIONS_FINISHED:
        return "Dateneingabe für iSFP erfolgt";
      case IsfpState.FINAL:
        return "iSFP bereitgestellt";
    }
  }

  export function fromString(stateName: string): IsfpState | undefined {
    switch (stateName) {
      case "zum Schnellcheck einladen":
        return IsfpState.START;
      case "zum Schnellcheck eingeladen":
        return IsfpState.INVITED;
      case "Schnellcheck wird ausgefüllt":
        return IsfpState.STATUS_QUO_STARTED;
      case "Schnellcheck durchgeführt":
        return IsfpState.STATUS_QUO_FINISHED;
      case "iSFP Angebot angefordert":
        return IsfpState.OFFER_REQUESTED;
      case "iSFP beauftragt":
        return IsfpState.OFFER_ACCEPTED;
      case "iSFP angebot abgelehnt":
        return IsfpState.OFFER_REJECTED;
      case "Dateneingabe für iSFP angefangen":
        return IsfpState.RENOVATIONS_STARTED;
      case "Dateneingabe für iSFP erfolgt":
        return IsfpState.RENOVATIONS_FINISHED;
      case "iSFP bereitgestellt":
        return IsfpState.FINAL;
      default:
        return undefined;
    }
  }

  export function all(): Array<IsfpState> {
    return [
      IsfpState.START,
      IsfpState.INVITED,
      IsfpState.STATUS_QUO_STARTED,
      IsfpState.STATUS_QUO_FINISHED,
      IsfpState.OFFER_REQUESTED,
      IsfpState.OFFER_ACCEPTED,
      IsfpState.OFFER_REJECTED,
      IsfpState.RENOVATIONS_STARTED,
      IsfpState.RENOVATIONS_FINISHED,
      IsfpState.FINAL,
    ];
  }

  export function happyPathNext(curr: IsfpState): IsfpState | undefined {
    switch (curr) {
      case IsfpState.FINAL:
        return undefined;
      case IsfpState.RENOVATIONS_FINISHED:
        return IsfpState.FINAL;
      case IsfpState.RENOVATIONS_STARTED:
        return IsfpState.RENOVATIONS_FINISHED;
      case IsfpState.OFFER_ACCEPTED:
        return IsfpState.RENOVATIONS_STARTED;
      case IsfpState.OFFER_REQUESTED:
        return IsfpState.OFFER_ACCEPTED;
      case IsfpState.STATUS_QUO_FINISHED:
        return IsfpState.OFFER_ACCEPTED;
      case IsfpState.STATUS_QUO_STARTED:
        return IsfpState.STATUS_QUO_FINISHED;
      case IsfpState.INVITED:
        return IsfpState.STATUS_QUO_STARTED;
      case IsfpState.START:
        return IsfpState.INVITED;
      default:
        return undefined;
    }
  }
}

export enum OPState {
  START,
  INVITED,
  FINAL,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OPState {
  export function toString(state: OPState): string {
    switch (state) {
      case OPState.START:
        return "zum Sanierungsgutachten einladen";
      case OPState.INVITED:
        return "zum Sanierungsgutachten eingeladen";
      case OPState.FINAL:
        return "Sanierungsgutachten bereitgestellt";
    }
  }

  export function fromString(stateName: string): OPState | undefined {
    switch (stateName) {
      case "zum Sanierungsgutachten einladen":
        return OPState.START;
      case "zum Sanierungsgutachten eingeladen":
        return OPState.INVITED;
      case "Sanierungsgutachten bereitgestellt":
        return OPState.FINAL;
      default:
        return undefined;
    }
  }

  export function all(): Array<OPState> {
    return [OPState.START, OPState.INVITED, OPState.FINAL];
  }
}

export type LeadContact = {
  name: string;
  email: string;
  telephone?: string;
};

export type Lead = {
  id: string;
  bubbleId?: string;
  productInvites?: ProductRequest[];
  referrer?: string;
  custodians?: string[];
  tenant?: string;
  consultantDataReviewConsent?: boolean;
  properties?: Property[];
  createdAt?: number;
} & LeadContact;

export interface Property {
  isfp?: IsfpProperty;
  consumptionCertificate?: ConsumptionCertificateItem;
}

export interface BubbleFlow {
  renovationResponse?: RenovationResponse;
  statusQuoResponse?: StatusQuoResponse;
  offerRequest?: OfferRequest;
  renovationReport?: RenovationReport;
}

export interface IsfpProperty {
  id?: string;
  leadId?: string;
  leadName?: string;
  leadEmail?: string;

  /**
   * @deprecated BubbleFlow is here for old compatibility reasons,
   *             no new development will be supported
   */
  bubbleFlow?: BubbleFlow;

  schnellcheck?: SchnellcheckQuestionnaire;
  renovationQuestionnaire?: RenovationQuestionnaire;

  eventTimestamps?: IsfpEventTimestamps;
  status?: IsfpProcessStatus;
}

export interface RenovationResponse {
  airVents?: string[];
  atticHeated?: string;
  basementHeated?: string;
  bufferTankSize?: number;
  damages?: string;
  exteriorWalls?: string[];
  floor?: string;
  heatGenerator?: string;
  heaterBufferTank?: string;
  heaterHydraulicallyBalanced?: string;
  hotWaterCirculationPump?: string;
  housePhotoBackLeft?: string;
  housePhotoBackRight?: string;
  housePhotoFrontLeft?: string;
  housePhotoFrontRight?: string;
  isCompleted?: boolean;
  windowFrame?: string;
  windowGlazing?: string;
  energyConsumptionStatement1?: string;
  energyConsumptionStatement2?: string;
  energyConsumptionStatement3?: string;
  hotWaterTank?: string;
  hotWaterSystem?: string;
  heatingPipesInsulation?: string;
  buildingDrawingsAndPlans?: string[];
}

export interface StatusQuoResponse {
  address?: string;
  buildYear?: number;
  cellingRenovation?: string;
  doorRenovation?: string;
  floorInsulation?: string;
  heatingSystemAge?: string;
  heatingType?: string;
  houseType?: string;
  interest?: string;
  isCompleted?: boolean;
  outerWallInsulation?: string;
  windowRenovation?: string;
  housingUnits?: number;
  outerDoorType?: string;
  energyCertificate?: string;
}

export interface OfferRequest {
  address?: string;
  city?: string;
  comment?: string;
  contactNumber?: string;
  email?: string;
  firstName?: string;
  lastLame?: string;
  plan?: string;
  postcode?: string;
  submitDate?: number;
}

export interface RenovationReport {
  createdDate?: number;
  completed?: boolean;
  houseToday?: string;
  houseTomorrow?: string;
  savings?: string;
  isfp?: string;
  isfpLight?: string;
  sanierungscheckKfw?: string;
  sanierungscheckSanierungspflicht?: string;
  energyCertificate?: string;
  question?: string[];
  answer?: string[];
}

export enum UserRoles {
  admin = "admin",
  consultant = "consultant",
  bankManager = "bank-manager",
}

export type ConsultantInvitationResponse = {
  code: ConsultantInvitationResponseCodes;
  message: string;
};

export type UserResponse = {
  id: string;
  name: string;
  email: string;
  referrerCode: string;
  shortLink?: string;
  groups?: UserRoles[];
  tenant?: TenantBasicInfo;
};

export enum StatusCollor {
  red = "#da3319",
  yellow = "#ffde5a",
  green = "#72ad47",
  none = "transparent",
}

export interface BenefitsResponse {
  subsidy: number;
  minSavings: number;
  maxSavings: number;
}

export interface StatusResponse {
  color: StatusCollor;
  minSavings: number;
  maxSavings: number;
  subsidy: number;
}

export enum WindowRenovation {
  before1979 = "Fenstertausch vor 1979",
  between1979And1995 = "Fenstertausch zwischen 1979 und 1995",
  between1995And2019 = "Fenstertausch zwischen 1995 und 2019",
  from2020 = "Fenstertausch 2020 oder später",
  none = "kein Fenstertausch",
}

export enum HeatingSystemAge {
  moreThan30Years = "vor mehr als 30 Jahren",
  between20To30Years = "vor 20 bis 30 Jahren",
  between10To20Years = "vor 10 bis 20 Jahren",
  lessThan10Years = "vor weniger als 10 Jahren",
  lessThan5Years = "vor weniger als 5 Jahren",
}

export enum OuterWallInsulation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein",
}

export enum FloorInsulation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein, keine Dämmung",
}

export enum DoorRenovation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein, wir haben noch Originaltüren",
}

export enum CeilingRenovation {
  before1968 = "ja, vor 1968",
  between1968And2001 = "ja, zwischen 1968 und 2001",
  from2002 = "ja, 2002 oder später",
  none = "nein, keine Dämmung",
}

export type FeedbackReport = {
  callback?: boolean;
  score?: number;
  message?: string;
  followUp?: boolean;
};

// External lead sources
export enum LeadSource {
  IMPLECO = "impleco",
}

export interface CustodianRequest {
  email: string;
  receiveNotifications?: boolean;
  canEdit?: boolean;
}
export interface AddCustodiansRequest {
  sendNotification: boolean;
  customMessage?: string;
  custodians: CustodianRequest[];
}

export interface RemoveCustodiansRequest {
  custodians: CustodianRequest[];
}
