import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { RenovationQuestionnaire } from "../../../../types/RenovationQuestionnaire";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import RenovationsAnswers from "./details/isfp/renovations/RenovationsAnswers";

interface DisplayRenovationDialogProps {
  renovation: RenovationQuestionnaire;
  open: boolean;
  handleClose: () => void;
}

export function DisplayRenovationDialog({ renovation, open, handleClose }: DisplayRenovationDialogProps) {
  if (!renovation) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        iSFP-Fragebogen Antworten
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <RenovationsAnswers renovations={renovation} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
