import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { AnalysisReport } from "../../../../types/Schnellcheck";
import ProgressBar from "../../ProgressBar";
import EnergyClasses from "./EnergyClasses";
import HouseStatus from "./HouseStatus";
import SavingsEstimates from "./SavingsEstimates";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";

interface Screen5Props extends StatusQuoScreenProps {
  report?: AnalysisReport;
}
export default function Screen5({ report, prev, next }: Screen5Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [searchParams] = useSearchParams();
  const tenant = searchParams.get("tenant");
  const ctaRef = useRef(null);

  const handleClick = () => {
    if (ctaRef.current) {
      (ctaRef.current as HTMLButtonElement).scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        mx: { xs: 0, lg: 10 },
        maxWidth: "100%",
      }}
    >
      <Grid
        container
        rowSpacing={{
          xs: 0,
          lg: 4,
        }}
        columnSpacing={{
          xs: 0,
          lg: 8,
        }}
      >
        <Grid size={12}>
          <ProgressBar label={"80% geschafft"} value={80} mt={{ xs: 2, lg: 0 }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 8 }}>
          <Report report={report} />
        </Grid>
        {isMobile && (
          <Grid size={12}>
            <Button variant='contained' sx={{ width: "calc(100% - 2rem)", m: 2 }} onClick={handleClick}>
              Wie geht es weiter?
            </Button>
          </Grid>
        )}
        <Grid size={{ xs: 12, lg: 4 }}>
          <OfferRequestActionPanel tenant={tenant} isMobile={isMobile} next={next} prev={prev} />
        </Grid>
        {isMobile && (
          <Grid size={12}>
            <Button ref={ctaRef} id='offer-btn' data-cy='offer-btn' variant='contained' sx={{ width: "calc(100% - 2rem)", m: 2 }} onClick={next}>
              Kostenloses Erstgespräch & Angebot anfordern
            </Button>
          </Grid>
        )}
        {isMobile && (
          <Grid size={12}>
            <Stack alignItems={"center"}>
              <Button variant='text' sx={{ color: "text.primary", mx: "auto" }} onClick={prev}>
                Zurück
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

type OfferRequestActionPanelProps = {
  isMobile: boolean;
  next: (payload?: unknown) => void;
  prev?: () => void;
  tenant?: string | null;
};

const OfferRequestActionPanel = ({ tenant, isMobile, next, prev }: OfferRequestActionPanelProps) => {
  switch (tenant) {
    case "hausbank":
      return <HausbankOfferRequestActionPanel isMobile={isMobile} next={next} prev={prev} />;
    default:
      return <UnbrandedOfferRequestActionPanel isMobile={isMobile} next={next} prev={prev} />;
  }
};

const HausbankOfferRequestActionPanel = ({ isMobile, next, prev }: OfferRequestActionPanelProps) => (
  <Stack component={Paper} spacing={2} sx={{ p: 2, m: { xs: 2, lg: 0 }, backgroundColor: "#f6f6f6" }}>
    <Typography fontSize={14} fontWeight={"fontWeightBold"}>
      Jetzt erfahren, wie Sanierung für Sie lohnen kann
    </Typography>
    <Typography fontSize={14}>Mit dem Sanierungsgutachten ab 699€</Typography>
    <Stack direction={"row"} sx={{ pt: 2 }}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>Minimal - und Maximalsanierung kennen</Typography>
    </Stack>
    <Stack direction={"row"}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>bereit für WEG Versammlung</Typography>
    </Stack>
    <Stack direction={"row"}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>Rabatt für Hausbank Kunden</Typography>
    </Stack>
    {!isMobile && (
      <Button variant='contained' id='offer-btn' data-cy='offer-btn' onClick={next}>
        Kostenloses Erstgespräch & Angebot anfordern
      </Button>
    )}
    {!isMobile && prev && (
      <Button variant='text' sx={{ color: "text.primary" }} onClick={prev}>
        Zurück
      </Button>
    )}
  </Stack>
);

const UnbrandedOfferRequestActionPanel = ({ isMobile, next, prev }: OfferRequestActionPanelProps) => (
  <Stack component={Paper} spacing={2} sx={{ p: 2, m: { xs: 2, lg: 0 }, backgroundColor: "#f6f6f6" }}>
    <Typography fontSize={14} fontWeight={"fontWeightBold"}>
      Jetzt erfahren, welche Sanierungs-maßnahmen sich für Sie lohnen:
    </Typography>
    <Typography fontSize={14}>Mit einem individuellen Sanierungsfahrplan (iSFP)</Typography>
    <Stack direction={"row"} sx={{ pt: 2 }}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>Überblick über Maßnahmen, Kosten und Förderungen</Typography>
    </Stack>
    <Stack direction={"row"}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>dena-zertifiziert</Typography>
    </Stack>
    <Stack direction={"row"}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>bis zu 850€ Zuschuss</Typography>
    </Stack>
    <Stack direction={"row"}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>doppelte förderfähige Summe</Typography>
    </Stack>
    <Stack direction={"row"} sx={{ pb: 2 }}>
      <CheckIcon color='success' sx={{ mr: 1 }} />
      <Typography fontSize={14}>5% Extra-Förderung</Typography>
    </Stack>
    {!isMobile && (
      <Button variant='contained' id='offer-btn' data-cy='offer-btn' onClick={next}>
        Kostenloses Erstgespräch & Angebot anfordern
      </Button>
    )}
    {!isMobile && prev && (
      <Button variant='text' sx={{ color: "text.primary" }} onClick={prev}>
        Zurück
      </Button>
    )}
  </Stack>
);

function Report({ report }: { report?: AnalysisReport }) {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        ml: { lg: 4 },
        mx: { xs: 2, lg: "inherit" },
      }}
    >
      <Grid size={12}>
        <Typography variant='body1' fontWeight={"fontWeightBold"} mt={{ xs: 2, lg: 0 }} mb={2} fontSize={{ xs: 14, lg: 16 }}>
          Ihr Haus im Überblick
        </Typography>
      </Grid>
      <Grid size={12}>
        <EnergyClasses future={report?.afterRenovationEnergyClass} current={report?.currentEnergyClass} />
      </Grid>
      <Grid size={12}>
        <HouseStatus status={report?.status} />
      </Grid>
      <Grid size={12}>
        <SavingsEstimates energyCostSavings={report?.energyCostSavings} subsidy={report?.subsidy} trees={report?.trees} />
      </Grid>
    </Grid>
  );
}
