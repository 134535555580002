import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../Contexts";
import { ConsumptionCertificateItem } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/params";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export function useFinalizeConsumptionCertManually() {
  const { session } = useContext(AuthContext);

  const [updatedConsumptionCert, setUpdatedConsumptionCert] = useState<ConsumptionCertificateItem>();
  const [isRunning, setIsRunning] = useState(false);
  const [finalizeError, setFinalizeError] = useState<unknown>();

  const finalizeConsumptionCert = useCallback(
    async (file: File, consumptionCertId: string, registrationNumber: string) => {
      setIsRunning(true);
      setFinalizeError(undefined);

      try {
        const { data: presignedLinkData } = await axios.request<{ presignedLink: string }>({
          method: "POST",
          baseURL: `${API_ENDPOINT}/${consumptionCertId}/pdf-upload-link`,
          headers: {
            Authorization: `Bearer ${session?.getIdToken().getJwtToken()}`,
          },
        });

        await axios.put(presignedLinkData.presignedLink, file, {
          headers: {
            "Content-Type": file.type,
            "Access-Control-Allow-Origin": "*",
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
            responseType: "json",
          },
        });

        const { data: finalizeDocData } = await axios.request<ConsumptionCertificateItem>({
          headers: {
            Authorization: `Bearer ${session?.getIdToken().getJwtToken()}`,
          },
          method: "POST",
          baseURL: `${API_ENDPOINT}/${consumptionCertId}/manual-finalize`,
          data: { registrationNumber },
        });

        setUpdatedConsumptionCert(finalizeDocData);
      } catch (error) {
        setFinalizeError(error);
      } finally {
        setIsRunning(false);
      }
    },
    [session],
  );

  const resetState = () => {
    setFinalizeError(undefined);
    setIsRunning(false);
  };

  return { finalizeConsumptionCert, isRunning, updatedConsumptionCert, finalizeError, resetState };
}
