import Grid from "@mui/material/Grid2";
import ProgressBar from "../ProgressBar";
import { Button, Stack, Typography } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";

export default function IntroScreen({ next }: ConsumptionCertificateScreenProps) {
  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={"Jetzt starten!"} value={0} />
      </Grid>
      <Grid size={12}>
        <Stack mx={{ xs: 3, lg: 5 }}>
          <Typography fontSize={{ xs: 14, lg: 16 }}>
            <strong>Vielen Dank, dass Sie NOVO ausgewählt haben.</strong>
          </Typography>
          <Typography fontSize={{ xs: 14, lg: 16 }}>Wir freuen uns, Ihnen bei Ihrem Verbrauchsausweisbedarf zu helfen.</Typography>
          <Typography mt={4} fontSize={{ xs: 14, lg: 16 }}>
            Bevor Sie mit dem Fragebogen anfangen, stellen Sie bitte sicher, dass Sie Informationen zu folgenden Themen zur Hand haben:
          </Typography>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Baujahr und Fläche Ihrer Immobilie</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Außenfoto Ihrer Immobilie</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>
              Heizkostenabrechnungen aus 3 aufeinanderfolgenden Jahren, wobei die letzte Abrechnung nicht älter als 18 Monate ist.
            </Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>
              Falls Sie eine aktive Kühlung haben, die insgesamt installierte Leistung und das Datum der nächsten Inspektion (falls zutreffend).
            </Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>
              Informationen zu bereits durchgeführten Renovierungsmaßnahmen (einschließlich des Zeitpunkts).
            </Typography>
          </Stack>
          <Typography mt={4} fontSize={{ xs: 14, lg: 16 }}>
            Alle Eingaben werden zwischengespeichert und Sie können jederzeit zum letzten Bearbeitungsstand zurückkehren.
          </Typography>
          <Typography mt={4} fontSize={{ xs: 14, lg: 16 }}>
            Sobald Sie bereit sind, klicken Sie auf die Schaltfläche unten, um zu beginnen. Herzlichen Dank!
          </Typography>
          <Stack direction='row' spacing={2} sx={{ mt: 8, justifyContent: "center" }}>
            <Button variant='contained' data-cy='im-ready-btn' onClick={next}>
              Ich bin bereit!
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
