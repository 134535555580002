import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AvatarGroup, TableSortLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { NovoTooltip } from "../../../../components/common/NovoTooltip";
import { CCState, IsfpState, OPState } from "../../../../types/cockpit/types";
import { RowProps } from "./LeadList";
import { EXAMPLE_LEAD_ID_PREFIX } from "./PlaceholderLeadData";
import { LeadCustodian } from "./workflow/LeadCustodian";
import LeadWorkflow from "./workflow/LeadWorkflow";
import ManageCustodiansDialog from "./workflow/ManageCustodiansDialog";

export type Order = "asc" | "desc";
export type OrderBy = "name" | "address" | "referrer";

export interface OrderParams {
  order: Order;
  orderBy: OrderBy;
}

export const TABLE_COLUMNS_COUNT = 6;

export interface LeadTableProps {
  rows?: RowProps[];
  order: OrderParams;
  orderChanged: (orderParams: OrderParams) => void;
}

export default function LeadTable({ rows, order, orderChanged }: LeadTableProps) {
  const [searchParams] = useSearchParams();

  const initiallySelectedRowId = searchParams.get("selected") ?? "";

  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === order.orderBy) {
      orderChanged({ ...order, order: order.order === "asc" ? "desc" : "asc" });
    } else {
      orderChanged({ ...order, orderBy: newOrder });
    }
  };

  return (
    <Table aria-label='collapsible table' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
      <colgroup>
        <col width='3%' />
        <col width='24%' />
        <col width='35%' />
        <col width='15%' />
        <col width='20%' />
        <col width='3%' />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <TableSortLabel
              active={order.orderBy === "name"}
              direction={order.order}
              onClick={() => {
                handleOrderChange("name");
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={order.orderBy === "address"}
              direction={order.order}
              onClick={() => {
                handleOrderChange("address");
              }}
            >
              Adresse
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={order.orderBy === "referrer"}
              direction={order.order}
              onClick={() => {
                handleOrderChange("referrer");
              }}
            >
              Berater
            </TableSortLabel>
          </TableCell>
          <TableCell>Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody data-cy='lead-list-table-body'>
        {rows?.filter((row) => !!row).map((row) => <Row key={row.id} selected={initiallySelectedRowId === row.id} row={row} />)}
      </TableBody>
    </Table>
  );
}

function Row({ selected = false, row }: { selected?: boolean; row: RowProps }) {
  const [open, setOpen] = useState(selected);
  const [openLeadSharing, setOpenLeadSharing] = useState(false);

  const owners = row.custodians ? [...row.custodians] : [];
  if (row.referrer && !owners.includes(row.referrer)) {
    owners.unshift(row.referrer);
  }
  const toggleLeadSharing = () => {
    setOpenLeadSharing(!openLeadSharing);
  };

  return (
    <Fragment>
      <TableRow
        data-cy={`lead-row-${row.name}`}
        sx={{
          height: 75,
          cursor: "pointer",
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell padding='checkbox'>
          <Checkbox color='primary' checked={open} />
        </TableCell>
        <TableCell data-cy={`lead-row-name-${row.name}`} component='th' scope='row' sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          {row.name}
        </TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          <LocationOnIcon sx={{ pr: "0.5rem", verticalAlign: "middle", fontSize: "2rem", color: open ? "primary.main" : "#707070" }} />
          {row.address}
        </TableCell>
        <TableCell>
          <Stack direction='row' alignItems='center' spacing={2}>
            {owners.length > 0 && (
              <AvatarGroup max={4}>
                {owners.map((custodian, index) => (
                  <LeadCustodian key={index} email={custodian} short />
                ))}
              </AvatarGroup>
            )}
            <IconButton
              disabled={row.id.includes(EXAMPLE_LEAD_ID_PREFIX)}
              onClick={(evt) => {
                evt.stopPropagation();
                toggleLeadSharing();
              }}
            >
              <NovoTooltip title='Kunde teilen'>
                <GroupAddIcon />
              </NovoTooltip>
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          {row.isfp.state !== IsfpState.START && (
            <Chip sx={{ mr: 1 }} data-cy={`lead-row-isfp-state-chip-${row.name}`} label={IsfpState.toString(row.isfp.state)} />
          )}
          {row.cc.state !== CCState.START && <Chip sx={{ mr: 1 }} data-cy={`lead-row-cc-state-chip-${row.name}`} label={CCState.toString(row.cc.state)} />}
          {row.op.state !== OPState.START && <Chip sx={{ mr: 1 }} data-cy={`lead-row-op-state-chip-${row.name}`} label={OPState.toString(row.op.state)} />}
        </TableCell>
        <TableCell>
          <IconButton data-cy={`lead-row-expand-btn-${row.name}`} aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        data-cy={`lead-row-expanded-${row.name}`}
        sx={{
          borderSpacing: 0,
          bgcolor: "#FBFBFB",
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={row.columnsCount}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <LeadWorkflow row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
      <ManageCustodiansDialog lead={row} open={openLeadSharing} handleClose={toggleLeadSharing} />
    </Fragment>
  );
}
