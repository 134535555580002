import { Stack, Typography } from "@mui/material";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpRenovationInProgress({ row }: RowDetailProps) {
  return (
    <Stack direction='row' flexWrap='wrap'>
      {row.status == IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED && (
        <>
          <Typography>iSFP-Fragebogen wird ausgefüllt</Typography>
          {row.eventTimestamps?.renovationQuestionaireStartedOn && (
            <>
              <Typography>. Er wurde am</Typography>
              &nbsp;
              <Typography sx={{ fontStyle: "italic" }}>
                {formatDate(row.eventTimestamps?.renovationQuestionaireStartedOn, "dd.MM.yyyy") + " begonnen"}
              </Typography>
            </>
          )}
          <Typography>.</Typography>
        </>
      )}
    </Stack>
  );
}
