import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { Lead } from "../../../../../types/cockpit/types";
import Stack from "@mui/material/Stack";
import { LeadCustodian } from "./LeadCustodian";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useContext, useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Fade, Typography } from "@mui/material";
import useAddLeadCustodians from "../../../../../hooks/useAddLeadCustodians";
import useRemoveLeadCustodians from "../../../../../hooks/useRemoveLeadCustodians";
import ButtonWithSpinner from "../../../../../components/ButtonWithSpinner";
import useFetchTenantUsers from "../../../../../hooks/useFetchTenantUsers";
import { AuthContext } from "../../../../../Contexts";

export interface ManageCustodiansDialog {
  open: boolean;
  handleClose: () => void;
  lead: Lead;
}
export default function ManageCustodiansDialog({ open, handleClose, lead }: ManageCustodiansDialog) {
  const { user } = useContext(AuthContext);
  const [autocompleteValue, setAutocompleteValue] = useState<string[]>([]);
  const [ownersToRemove, setOwnersToRemove] = useState<string[]>([]);
  const [sendNotification, setSendNotification] = useState<boolean>(true);
  const [customMessage, setCustomMessage] = useState<string>();
  const { getTenantUsers, tenantUsers, isLoading: isLoadingUsers } = useFetchTenantUsers();
  const { addLeadCustodians, error: addError, isLoading: isAdding, lead: leadAfterAdd } = useAddLeadCustodians();
  const { removeLeadCustodians, error: removeError, isLoading: isRemoving, lead: leadAfterRemove } = useRemoveLeadCustodians();
  const owners = lead.custodians ? [...lead.custodians] : [];
  if (lead.referrer && !owners.includes(lead.referrer)) {
    owners.unshift(lead.referrer);
  }
  let userOptions: string[] = [];
  if (tenantUsers) {
    userOptions = tenantUsers.map((user) => user.email).filter((email) => !owners.includes(email));
  }

  const onAutocompleteChange = (_: React.SyntheticEvent, value: string[]) => {
    setAutocompleteValue(value);
  };
  const resetAutocomplete = () => {
    setAutocompleteValue([]);
    setCustomMessage(undefined);
  };
  const toggleSendNotfication = () => {
    setSendNotification(!sendNotification);
  };
  const onCustomMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomMessage(event.target.value);
  };
  const addCustodians = () => {
    addLeadCustodians(lead, {
      sendNotification,
      customMessage,
      custodians: autocompleteValue.map((email) => {
        return { email, canEdit: true, receiveNotifications: true };
      }),
    });
  };
  const handleMarkForRemoval = (owner: string) => {
    setOwnersToRemove([...ownersToRemove, owner]);
  };
  const resetRemovals = () => {
    setOwnersToRemove([]);
  };
  const saveRemovals = () => {
    removeLeadCustodians(lead, {
      custodians: ownersToRemove.map((email) => {
        return { email, canEdit: true, receiveNotifications: true };
      }),
    });
  };

  useEffect(() => {
    if (open && !tenantUsers) {
      if (lead.tenant) {
        getTenantUsers(lead.tenant);
      } else if (user?.tenant?.id) {
        getTenantUsers(user.tenant.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tenantUsers]);

  useEffect(() => {
    if (leadAfterAdd?.custodians) {
      lead.custodians = [...leadAfterAdd.custodians];
    }
    setAutocompleteValue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadAfterAdd]);

  useEffect(() => {
    if (leadAfterRemove?.custodians) {
      lead.custodians = [...leadAfterRemove.custodians];
    }
    setOwnersToRemove([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadAfterRemove]);

  const onClose = () => {
    resetRemovals();
    resetAutocomplete();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        <Stack direction='row'>
          <Typography variant='h6' flexGrow={1}>{`Kunde "${lead.name}" teilen`}</Typography>
          <Stack>
            <Button sx={{ color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }} onClick={onClose} endIcon={<CloseIcon />}>
              Schließen
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack
          spacing={1}
          sx={{
            m: 2,
          }}
        >
          <Autocomplete
            id='manage-custodian-search-input'
            multiple
            options={userOptions}
            loading={isLoadingUsers}
            filterOptions={createFilterOptions({ limit: 5 })}
            renderInput={(params) => <TextField {...params} placeholder={autocompleteValue.length > 0 ? "" : "Wähle die E-Mail eines deiner Kollegen"} />}
            value={autocompleteValue}
            onChange={onAutocompleteChange}
          />
          {autocompleteValue.length > 0 && (
            <Fade in={true} timeout={500}>
              <Stack spacing={1}>
                <FormControlLabel control={<Checkbox checked={sendNotification} onChange={toggleSendNotfication} />} label='Nachricht schicken' />
                <TextField multiline minRows={4} label='Nachricht' value={customMessage} onChange={onCustomMessageChange} />
                <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2} pt={4}>
                  {addError && (
                    <Typography variant='body2' color='error'>
                      {addError.errorMsg}
                    </Typography>
                  )}
                  <Button variant='outlined' color='secondary' onClick={resetAutocomplete}>
                    Abbrechen
                  </Button>
                  <ButtonWithSpinner variant='contained' color='primary' onClick={addCustodians} label='Teilen' loading={isAdding} />
                </Stack>
              </Stack>
            </Fade>
          )}
          {autocompleteValue.length == 0 && (
            <Fade in={true} timeout={500}>
              <Stack spacing={4} pt={3}>
                <Typography fontWeight={600}>Bereits freigeschalteter Zugang</Typography>
                {owners.map((owner, index) => (
                  <Stack key={index} direction='row' alignItems='center'>
                    <LeadCustodian email={owner} strikethrough={ownersToRemove.includes(owner)} flexGrow={1} maxWidth='85%' />
                    <Button
                      variant='text'
                      color='secondary'
                      disabled={owner === lead.referrer || ownersToRemove.includes(owner)}
                      onClick={() => handleMarkForRemoval(owner)}
                    >
                      {owner === lead.referrer ? "Referrer" : "Entfernen"}
                    </Button>
                  </Stack>
                ))}
                <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2} pt={4}>
                  {ownersToRemove.length > 0 && (
                    <>
                      <Typography
                        variant='body2'
                        color={removeError ? "error" : "primary"}
                        sx={{ textAlign: "end", fontStyle: removeError ? "inherit" : "italic", color: removeError ? "inherit" : "#00000090" }}
                      >
                        {removeError ? removeError.errorMsg : !isRemoving ? "Du hast ungespeicherte Änderungen" : ""}
                      </Typography>
                      <Button variant='outlined' color='secondary' onClick={resetRemovals}>
                        Abbrechen
                      </Button>
                      <ButtonWithSpinner variant='contained' color='primary' label='Speichern' loading={isRemoving} onClick={saveRemovals} />
                    </>
                  )}
                  {ownersToRemove.length == 0 && (
                    <Button variant='contained' color='primary' onClick={onClose}>
                      Schließen
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Fade>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
