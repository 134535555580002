import { Divider, FormHelperText, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../../FormScreen";
import InsulationComponent from "./InsulationComponent";
import { BasementOrGroundInformation } from "../../../../types/RenovationQuestionnaire";

export default function BasementScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [basementTypologyError, setBasementTypologyError] = useState<string>();
  const [basementWallsInsulationSelectedError, setBasementWallsInsulationSelectedError] = useState<string>();
  const [basementWallsInsulationYearError, setBasementWallsInsulationYearError] = useState<string>();
  const [basementFloorInsulationSelectedError, setBasementFloorInsulationSelectedError] = useState<string>();
  const [basementFloorInsulationYearError, setBasementFloorInsulationYearError] = useState<string>();
  const [hotWallsInsulationSelectedError, setHotWallsInsulationSelectedError] = useState<string>();
  const [hotWallsInsulationYearError, setHotWallsInsulationYearError] = useState<string>();
  const [hotFloorInsulationSelectedError, setHotFloorInsulationSelectedError] = useState<string>();
  const [hotFloorInsulationYearError, setHotFloorInsulationYearError] = useState<string>();
  const [coldCeilingInsulationSelectedError, setColdCeilingInsulationSelectedError] = useState<string>();
  const [coldCeilingInsulationYearError, setColdCeilingInsulationYearError] = useState<string>();
  const [basementCeilingInsulationSelectedError, setBasementCeilingInsulationSelectedError] = useState<string>();
  const [basementCeilingInsulationYearError, setBasementCeilingInsulationYearError] = useState<string>();
  const [groundFloorInsulationSelectedError, setGroundFloorInsulationSelectedError] = useState<string>();
  const [groundFloorInsulationYearError, setGroundFloorInsulationYearError] = useState<string>();
  const [basementOrGroundInformation, setBasementOrGroundInformation] = useState<BasementOrGroundInformation>(
    renovations.answers?.basementOrGroundInformation || {},
  );

  const basement = basementOrGroundInformation.basement;
  const minYear = renovations.constructionYear ?? 1900;
  const maxYear = new Date().getFullYear();

  const validate = () => {
    if (!basementOrGroundInformation.basement) {
      setBasementTypologyError("Bitte auswählen");
      return false;
    } else {
      setBasementTypologyError(undefined);
    }
    if (basement === "heated") {
      //basement walls
      if (basementOrGroundInformation.basementWallsInsulation?.installed === undefined) {
        setBasementWallsInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setBasementWallsInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.basementWallsInsulation.installed && !basementOrGroundInformation.basementWallsInsulation.year) {
        setBasementWallsInsulationYearError("Pflichtfeld");
        return false;
      } else if (basementOrGroundInformation.basementWallsInsulation.year! < minYear || basementOrGroundInformation.basementWallsInsulation.year! > maxYear) {
        setBasementWallsInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setBasementWallsInsulationYearError(undefined);
      }
      //basement floor
      if (basementOrGroundInformation.basementFloorInsulation?.installed === undefined) {
        setBasementFloorInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setBasementFloorInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.basementFloorInsulation.installed && !basementOrGroundInformation.basementFloorInsulation.year) {
        setBasementFloorInsulationYearError("Pflichtfeld");
        return false;
      } else if (basementOrGroundInformation.basementFloorInsulation.year! < minYear || basementOrGroundInformation.basementFloorInsulation.year! > maxYear) {
        setBasementFloorInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setBasementFloorInsulationYearError(undefined);
      }
    }
    if (basement === "partiallyHeated") {
      //hot walls
      if (basementOrGroundInformation.hotWallsInsulation?.installed === undefined) {
        setHotWallsInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setHotWallsInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.hotWallsInsulation.installed && !basementOrGroundInformation.hotWallsInsulation.year) {
        setHotWallsInsulationYearError("Pflichtfeld");
        return false;
      } else if (basementOrGroundInformation.hotWallsInsulation.year! < minYear || basementOrGroundInformation.hotWallsInsulation.year! > maxYear) {
        setHotWallsInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setHotWallsInsulationYearError(undefined);
      }
      //hot floor
      if (basementOrGroundInformation.hotFloorInsulation?.installed === undefined) {
        setHotFloorInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setHotFloorInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.hotFloorInsulation.installed && !basementOrGroundInformation.hotFloorInsulation.year) {
        setHotFloorInsulationYearError("Pflichtfeld");
        return false;
      } else if (basementOrGroundInformation.hotFloorInsulation.year! < minYear || basementOrGroundInformation.hotFloorInsulation.year! > maxYear) {
        setHotFloorInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setHotFloorInsulationYearError(undefined);
      }
      //cold ceiling
      if (basementOrGroundInformation.coldCeilingInsulation?.installed === undefined) {
        setColdCeilingInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setColdCeilingInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.coldCeilingInsulation.installed && !basementOrGroundInformation.coldCeilingInsulation.year) {
        setColdCeilingInsulationYearError("Pflichtfeld");
        return false;
      } else if (basementOrGroundInformation.coldCeilingInsulation.year! < minYear || basementOrGroundInformation.coldCeilingInsulation.year! > maxYear) {
        setColdCeilingInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setColdCeilingInsulationYearError(undefined);
      }
    }
    if (basement === "unheated") {
      //basement ceiling
      if (basementOrGroundInformation.basementCeilingInsulation?.installed === undefined) {
        setBasementCeilingInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setBasementCeilingInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.basementCeilingInsulation.installed && !basementOrGroundInformation.basementCeilingInsulation.year) {
        setBasementCeilingInsulationYearError("Pflichtfeld");
        return false;
      } else if (
        basementOrGroundInformation.basementCeilingInsulation.year! < minYear ||
        basementOrGroundInformation.basementCeilingInsulation.year! > maxYear
      ) {
        setBasementCeilingInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setBasementCeilingInsulationYearError(undefined);
      }
    }
    if (basement === "none") {
      //ground floor
      if (basementOrGroundInformation.groundFloorInsulation?.installed === undefined) {
        setGroundFloorInsulationSelectedError("Bitte auswählen");
        return false;
      } else {
        setGroundFloorInsulationSelectedError(undefined);
      }
      if (basementOrGroundInformation.groundFloorInsulation.installed && !basementOrGroundInformation.groundFloorInsulation.year) {
        setGroundFloorInsulationYearError("Pflichtfeld");
        return false;
      } else if (basementOrGroundInformation.groundFloorInsulation.year! < minYear || basementOrGroundInformation.groundFloorInsulation.year! > maxYear) {
        setGroundFloorInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setGroundFloorInsulationYearError(undefined);
      }
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, basementOrGroundInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 5: </strong>Informationen zum Keller oder Boden
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction={isMobile ? "column" : "row"} alignItems='baseline' spacing={2}>
            <Typography fontSize={{ xs: 14, lg: 16 }}>Hat das Haus einen Keller? Ist dieser beheizt?</Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={basement}
              onChange={(_, value) => {
                setBasementOrGroundInformation({ ...basementOrGroundInformation, basement: value });
                setBasementTypologyError(undefined);
              }}
            >
              <ToggleButton value='heated' sx={{ fontSize: { xs: 10, lg: 13 } }} disabled={!renovations.answers?.houseConfiguration?.basementHeight}>
                Ja, beheizt
              </ToggleButton>
              <ToggleButton value='partiallyHeated' sx={{ fontSize: { xs: 10, lg: 13 } }} disabled={!renovations.answers?.houseConfiguration?.basementHeight}>
                Ja, teilweise beheizt
              </ToggleButton>
              <ToggleButton value='unheated' sx={{ fontSize: { xs: 10, lg: 13 } }} disabled={!renovations.answers?.houseConfiguration?.basementHeight}>
                Ja unbeheizt
              </ToggleButton>
              <ToggleButton value='none' sx={{ fontSize: { xs: 10, lg: 13 } }} disabled={!!renovations.answers?.houseConfiguration?.basementHeight}>
                Nein
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (basementTypologyError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!basementTypologyError && <FormHelperText error>{basementTypologyError}</FormHelperText>}
        </Grid>
        {basement === "heated" && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Sind die Außenwände des Kellers gedämmt?'
                picture='House_sections_basement_walls_insulation.png'
                insulation={basementOrGroundInformation.basementWallsInsulation}
                setInsulation={(basementWallsInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, basementWallsInsulation })}
                selectedError={basementWallsInsulationSelectedError}
                setSelectedError={setBasementWallsInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={basementWallsInsulationYearError}
                setYearError={setBasementWallsInsulationYearError}
              />
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Ist die Bodenplatte des Kellers gedämmt?'
                picture='House_sections_basement_floor_insulation.png'
                insulation={basementOrGroundInformation.basementFloorInsulation}
                setInsulation={(basementFloorInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, basementFloorInsulation })}
                selectedError={basementFloorInsulationSelectedError}
                setSelectedError={setBasementFloorInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={basementFloorInsulationYearError}
                setYearError={setBasementFloorInsulationYearError}
              />
            </Grid>
          </>
        )}
        {basement === "partiallyHeated" && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Sind die Außenwände des Kellers in den beheizten Räumen gedämmt?'
                picture='House_sections_hot_basement_walls_insulation.png'
                insulation={basementOrGroundInformation.hotWallsInsulation}
                setInsulation={(hotWallsInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, hotWallsInsulation })}
                selectedError={hotWallsInsulationSelectedError}
                setSelectedError={setHotWallsInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={hotWallsInsulationYearError}
                setYearError={setHotWallsInsulationYearError}
              />
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Ist die Bodenplatte des Kellers in den beheizten Räumen gedämmt?'
                picture='House_sections_hot_basement_floor_insulation.png'
                insulation={basementOrGroundInformation.hotFloorInsulation}
                setInsulation={(hotFloorInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, hotFloorInsulation })}
                selectedError={hotFloorInsulationSelectedError}
                setSelectedError={setHotFloorInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={hotFloorInsulationYearError}
                setYearError={setHotFloorInsulationYearError}
              />
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Ist die Kellerdecke in den unbeheizten Räumen gedämmt?'
                picture='House_sections_cold_basement_ceiling_insulation.png'
                insulation={basementOrGroundInformation.coldCeilingInsulation}
                setInsulation={(coldCeilingInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, coldCeilingInsulation })}
                selectedError={coldCeilingInsulationSelectedError}
                setSelectedError={setColdCeilingInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={coldCeilingInsulationYearError}
                setYearError={setColdCeilingInsulationYearError}
              />
            </Grid>
          </>
        )}
        {basement === "unheated" && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Ist die Kellerdecke gedämmt?'
                picture='House_sections_basement_ceiling_insulation.png'
                insulation={basementOrGroundInformation.basementCeilingInsulation}
                setInsulation={(basementCeilingInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, basementCeilingInsulation })}
                selectedError={basementCeilingInsulationSelectedError}
                setSelectedError={setBasementCeilingInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={basementCeilingInsulationYearError}
                setYearError={setBasementCeilingInsulationYearError}
              />
            </Grid>
          </>
        )}
        {basement === "none" && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent
                fadein
                question='Ist die Bodenplatte des Erdgeschosses gedämmt?'
                picture='House_sections_no_basement_floor_insulation.png'
                insulation={basementOrGroundInformation.groundFloorInsulation}
                setInsulation={(groundFloorInsulation) => setBasementOrGroundInformation({ ...basementOrGroundInformation, groundFloorInsulation })}
                selectedError={groundFloorInsulationSelectedError}
                setSelectedError={setGroundFloorInsulationSelectedError}
                minYear={minYear}
                maxYear={maxYear}
                yearError={groundFloorInsulationYearError}
                setYearError={setGroundFloorInsulationYearError}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Keller oder Boden'
      progressValue={41}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Sind die Außenwände des Kellers
      <br />
      und der Kellerboden ungedämmt,
      <br />
      geht dort Wärme verloren.
      <br />
      Je nach Kellernutzung ist es
      <br />
      sinnvoll die Kellerdecke oder
      <br />
      die Außenbauteile des Kellers
      <br />
      zu dämmen.
      <br />
      <br />
      Sollten Sie keinen Keller haben,
      <br />
      benötigen wir Informationen zur
      <br />
      eventuellen Dämmung der Bodenplatte.
    </Typography>
  );
}
