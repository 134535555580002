import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ChangeEvent, useState } from "react";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import { HouseType } from "../../../../types/Schnellcheck";
import FormScreen from "../../FormScreen";
import NovoTextField from "../../../../components/common/NovoTextField";

export default function Screen1({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, next }: StatusQuoScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [yearError, setYearError] = useState<string>();
  const [areaError, setAreaError] = useState<string>();
  const [typeError, setTypeError] = useState<string>();
  const currentYear = new Date().getFullYear();

  const updateYear = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const yearOfConstruction = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          yearOfConstruction,
        },
      },
    });
    if (yearOfConstruction) {
      setYearError(undefined);
    }
  };

  const updateArea = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const area = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          area,
        },
      },
    });
    if (area) {
      setAreaError(undefined);
    }
  };

  const handleTypeSelection = (type: HouseType) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          type,
        },
      },
    });
  };

  const handleListedBuilding = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseDetails: {
          ...schnellcheck.answers?.houseDetails,
          isMonument: event.target.checked,
        },
      },
    });
  };

  const validate = () => {
    const { area, yearOfConstruction, type } = schnellcheck.answers?.houseDetails ?? {};
    if (!yearOfConstruction) {
      setYearError("Pflichtfeld");
      return false;
    } else if (yearOfConstruction < 1800 || yearOfConstruction > currentYear) {
      setYearError(`Das Jahr muss zwischen 1800 und ${currentYear} liegen`);
      return false;
    } else {
      setYearError(undefined);
    }

    if (!area) {
      setAreaError("Pflichtfeld");
      return false;
    } else if (area <= 0) {
      setAreaError("Muss größer als 0 sein");
      return false;
    } else {
      setAreaError(undefined);
    }

    if (!type) {
      setTypeError("Pflichtfeld");
      return false;
    } else {
      setTypeError(undefined);
    }
    return true;
  };

  const validateAndSave = () => {
    if (validate()) {
      next();
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: { xs: 2, lg: 2 } }}>
          <Typography variant='body1' fontSize={isMobile ? 14 : 16}>
            <strong>Schritt 1: </strong>Informationen zum Haus
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
            <NovoTextField
              variant='outlined'
              id='build-year-input'
              data-cy='build-year-input'
              value={schnellcheck.answers?.houseDetails?.yearOfConstruction ?? ""}
              label='Baujahr'
              onChange={updateYear}
              required={true}
              type='number'
              error={!!yearError}
              helperText={yearError}
              slotProps={{
                htmlInput: { min: 1800, max: currentYear },
                inputLabel: { shrink: !!schnellcheck.answers?.houseDetails?.yearOfConstruction },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
            <NovoTextField
              variant='outlined'
              id='area-input'
              data-cy='area-input'
              value={schnellcheck.answers?.houseDetails?.area ?? ""}
              label='Wohnfläche'
              onChange={updateArea}
              required={true}
              type='number'
              error={!!areaError}
              helperText={areaError}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
                },
                htmlInput: { min: 1 },
                inputLabel: { shrink: !!schnellcheck.answers?.houseDetails?.area },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <TypeSelector selectedType={schnellcheck.answers?.houseDetails?.type} typeError={typeError} handleTypeSelection={handleTypeSelection} />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={<Checkbox onChange={handleListedBuilding} checked={schnellcheck.answers?.houseDetails?.isMonument} />}
            label='Dies ist ein denkmalgeschütztes Gebäude.'
            sx={{ "& > span": { fontSize: { xs: 14, lg: 16 } } }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Jetzt starten!'
      progressValue={5}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Je nach Alter des Hauses wurde nach
      <br />
      unterschiedlichen Bauvorschriften gebaut.
      <br />
      Zum Beispiel mit oder ohne
      <br />
      Wärmedämmung. Dies beeinflusst die
      <br />
      Energieeffizienz.
      <br />
      <br />
      Auch der Haustyp hat einen
      <br />
      entscheidenden Einfluss auf
      <br />
      Wärmeverluste und sinnvolle
      <br />
      Empfehlungen zur Modernisierung.
    </Typography>
  );
}

function TypeSelector({
  selectedType,
  typeError,
  handleTypeSelection,
}: {
  selectedType?: HouseType;
  typeError?: string;
  handleTypeSelection: (type: HouseType) => void;
}) {
  return (
    <FormControl required error={!!typeError}>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          borderRadius: 1,
          borderWidth: typeError ? 1 : 0,
          borderStyle: typeError ? "solid" : "",
          borderColor: "error.main",
        }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: selectedType === HouseType.singleFamily ? "" : "inherit" }}
            onClick={() => handleTypeSelection(HouseType.singleFamily)}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.singleFamily && <HomeIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.singleFamily && <HomeOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Einfamilienhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }}
              onChange={() => handleTypeSelection(HouseType.singleFamily)}
              checked={selectedType === HouseType.singleFamily}
              data-cy='single-family-option'
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: selectedType === HouseType.doubleFamily ? "" : "inherit" }}
            onClick={() => handleTypeSelection(HouseType.doubleFamily)}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.doubleFamily && <HolidayVillageIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.doubleFamily && <HolidayVillageOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Zweifamilienhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }}
              onChange={() => handleTypeSelection(HouseType.doubleFamily)}
              checked={selectedType === HouseType.doubleFamily}
              data-cy='double-family-option'
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: selectedType === HouseType.multiFamily ? "" : "inherit" }}
            onClick={() => handleTypeSelection(HouseType.multiFamily)}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.multiFamily && <ApartmentIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.multiFamily && <ApartmentOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Mehrfamilienhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }}
              onChange={() => handleTypeSelection(HouseType.multiFamily)}
              checked={selectedType === HouseType.multiFamily}
              data-cy='multi-family-option'
            />
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 } }}>
          {typeError && <FormHelperText>{typeError}</FormHelperText>}
        </Grid>
      </Grid>
    </FormControl>
  );
}
