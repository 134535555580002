import Grid from "@mui/material/Grid2";
import { Checkbox, Collapse, FormControl, FormControlLabel, FormHelperText, InputAdornment, Paper, PaperProps, Stack, Typography } from "@mui/material";
import { ChangeEvent, ReactNode, useState } from "react";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de } from "date-fns/locale/de";
import { addDays, format } from "date-fns";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import NovoTextField from "../../../components/common/NovoTextField";
import FormScreen from "../FormScreen";
import { UpsertConsumptionCertificate } from "./types";
import { CoolingType } from "./ConsumptionCertificateRequestEnums";

const coolingOptions = Object.keys(CoolingType) as (keyof typeof CoolingType)[];

type CoolingSystem = {
  system: keyof typeof CoolingType;
  selected: boolean;
  surface?: string;
  error?: string;
};

const emptyCoolingSystems: CoolingSystem[] = coolingOptions.map((option) => ({
  system: option,
  selected: false,
  surface: undefined,
  error: undefined,
}));

function populateCoolingSystems(coolingSystems?: { system: string; surface?: number }[]): CoolingSystem[] {
  return emptyCoolingSystems.map((system) => {
    const matchingSystem = coolingSystems?.find((s) => s.system === system.system);
    return {
      ...system,
      selected: matchingSystem !== undefined,
      surface: matchingSystem?.surface?.toString(),
    };
  });
}

function isActiveCooling(system: keyof typeof CoolingType): boolean {
  return ["Kühlung aus Strom", "Kühlung aus Wärme", "Gelieferte Kälte"].includes(system);
}

export default function CoolingScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [coolingSystems, setCoolingSystems] = useState<CoolingSystem[]>(populateCoolingSystems(consumptionCertificate.answers?.coolingSystems));
  const [numberOfUnits, setNumberOfUnits] = useState<string>(consumptionCertificate.answers?.coolingSystemsNumber || "");
  const [numberOfUnitsError, setNumberOfUnitsError] = useState<string>();
  const [nominalOutput, setNominalOutput] = useState<string>(consumptionCertificate.answers?.coolingSystemsNominalOutput || "");
  const [nominalOutputError, setNominalOutputError] = useState<string>();
  const [nextInspection, setNextInspection] = useState<Date | null>(
    consumptionCertificate.answers?.coolingSystemsNextInspection ? new Date(consumptionCertificate.answers?.coolingSystemsNextInspection) : null,
  );
  const [nextInspectionError, setNextInspectionError] = useState<string>();
  const [hasBuildingAutomation, setHasBuildingAutomation] = useState<boolean>(consumptionCertificate.answers?.coolingSystemsHasBuildingAutomation || false);

  const hasAnyActiveSelected = coolingSystems.some((system) => system.selected && isActiveCooling(system.system));

  const validate = () => {
    let shouldBreak = false;
    coolingSystems.forEach((system) => {
      if (system.selected && isActiveCooling(system.system) && !system.surface) {
        system.error = "Pflichtfeld";
        shouldBreak = true;
      } else if (system.surface && consumptionCertificate.answers?.area && +system.surface > consumptionCertificate.answers?.area) {
        system.error = `Maximal ${consumptionCertificate.answers?.area}m²`;
        shouldBreak = true;
      } else {
        system.error = undefined;
      }
    });
    setCoolingSystems([...coolingSystems]);
    if (shouldBreak) {
      return false;
    }
    if (!numberOfUnits && hasAnyActiveSelected) {
      setNumberOfUnitsError("Pflichtfeld");
      return false;
    } else {
      setNumberOfUnitsError(undefined);
    }
    if (!nominalOutput && hasAnyActiveSelected) {
      setNominalOutputError("Pflichtfeld");
      return false;
    } else {
      setNominalOutputError(undefined);
    }
    if (hasAnyActiveSelected && (+nominalOutput > 70 || (+nominalOutput > 12 && !hasBuildingAutomation)) && !nextInspection) {
      setNextInspectionError("Pflichtfeld");
      return false;
    } else if (
      hasAnyActiveSelected &&
      (+nominalOutput > 70 || (+nominalOutput > 12 && !hasBuildingAutomation)) &&
      nextInspection &&
      nextInspection.getTime() < Date.now()
    ) {
      setNextInspectionError("Datum muss in der Zukunft liegen");
      return false;
    } else {
      setNextInspectionError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
          coolingSystems: coolingSystems
            .filter((system) => system.selected)
            .map((system) => ({
              system: system.system,
              surface: system.surface ? Number.parseInt(system.surface) : undefined,
            })),
          coolingSystemsNumber: numberOfUnits,
          coolingSystemsNominalOutput: nominalOutput,
          coolingSystemsNextInspection: nextInspection ? format(nextInspection, "yyyy-MM-dd") : undefined,
          coolingSystemsHasBuildingAutomation: hasBuildingAutomation,
        },
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Kühlung</strong>
          </Typography>
        </Grid>
        {coolingOptions.map((option, index) => (
          <Grid size={{ xs: 12, md: 6 }} key={index}>
            <CoolingSystem
              title={option}
              icon={<AcUnitIcon />}
              selected={coolingSystems[index].selected}
              surface={coolingSystems[index].surface}
              requiresSurface={isActiveCooling(option)}
              maxSurface={consumptionCertificate.answers?.area?.toString()}
              error={coolingSystems[index].error}
              handleSurfaceChange={(id: string, surface?: string) => {
                const system = coolingSystems.find((system) => system.system === id);
                if (system) {
                  system.surface = surface;
                }
                setCoolingSystems([...coolingSystems]);
              }}
              handleSelectedChange={(id: string, selected: boolean) => {
                const system = coolingSystems.find((system) => system.system === id);
                if (system) {
                  system.selected = selected;
                  if (!selected) {
                    system.surface = undefined;
                    system.error = undefined;
                  }
                }
                setCoolingSystems([...coolingSystems]);
              }}
            />
          </Grid>
        ))}
        {hasAnyActiveSelected && (
          <>
            <Grid size={12}>
              <FormControlLabel
                control={<Checkbox required={false} value={hasBuildingAutomation} onChange={(_, checked: boolean) => setHasBuildingAutomation(checked)} />}
                label='Die Kühlanlage verfügt über Gebäudeautomation'
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <FormControl fullWidth>
                <NovoTextField
                  data-cy='cooling-number-input'
                  variant='outlined'
                  id='cooling-number-input'
                  value={numberOfUnits}
                  error={!!numberOfUnitsError}
                  helperText={numberOfUnitsError}
                  type='number'
                  label='Anzahl-Klimaanlagen'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setNumberOfUnits(event.target.value);
                    setNumberOfUnitsError(undefined);
                    setNextInspectionError(undefined);
                  }}
                  required={hasAnyActiveSelected}
                  slotProps={{
                    htmlInput: { min: 0 },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <FormControl fullWidth>
                <NovoTextField
                  data-cy='cooling-output-input'
                  variant='outlined'
                  id='cooling-output-input'
                  value={nominalOutput}
                  error={!!nominalOutputError}
                  helperText={nominalOutputError}
                  type='number'
                  label='kW'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setNominalOutput(event.target.value);
                    setNominalOutputError(undefined);
                    setNextInspectionError(undefined);
                  }}
                  required={hasAnyActiveSelected}
                  slotProps={{
                    htmlInput: { min: 0 },
                  }}
                />
              </FormControl>
              <FormHelperText id='next-inspection-due-date'>Nennleistung</FormHelperText>
            </Grid>
            <Grid size={{ xs: 12, md: 5 }}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                  <DatePicker
                    label='Fälligkeitsdatum'
                    data-cy='cooling-next-inspection'
                    value={nextInspection}
                    onChange={(newDate: Date) => setNextInspection(newDate)}
                    minDate={addDays(new Date(), 1)}
                    disablePast={true}
                    disabled={!hasAnyActiveSelected || (+nominalOutput <= 70 && hasBuildingAutomation) || +nominalOutput <= 12}
                    slotProps={{
                      textField: {
                        required: hasAnyActiveSelected && (+nominalOutput > 70 || (+nominalOutput > 12 && !hasBuildingAutomation)),
                        error: !!nextInspectionError,
                        helperText: nextInspectionError,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormHelperText id='next-inspection-due-date'>Nächstes Fälligkeitsdatum der Inspektion</FormHelperText>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={validateAndSave} progressLabel='7 von 10' progressValue={70} isUpdateRunning={isUpdateRunning} content={content} />;
}

interface CoolingSystemProps extends PaperProps {
  title: string;
  icon: ReactNode;
  selected: boolean;
  surface?: string;
  requiresSurface?: boolean;
  maxSurface?: string;
  disabled?: boolean;
  error?: string;
  handleSelectedChange: (id: string, selected: boolean) => void;
  handleSurfaceChange: (id: string, surface?: string) => void;
}

function CoolingSystem({
  title,
  icon,
  selected,
  surface = "",
  requiresSurface,
  maxSurface,
  error,
  handleSelectedChange,
  handleSurfaceChange,
}: CoolingSystemProps) {
  const toggleSelected = () => handleSelectedChange(title, !selected);
  return (
    <Stack
      component={Paper}
      spacing={1}
      alignItems='flex-start'
      sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: !selected ? "#f8f8f8" : "inherit" }}
      onClick={toggleSelected}
    >
      <Stack alignItems={"center"} spacing={2} direction='row' width='100%'>
        {icon}
        <Stack flexGrow={2} direction='row' spacing={1}>
          <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium" }}>
            {title}
          </Typography>
        </Stack>
        <Stack sx={{ flexGrow: 1, alignItems: "flex-end" }}>
          <Checkbox
            data-cy={`${title.replace(/\s/g, "-")}-input`}
            sx={{ "&.Mui-checked": { color: "text.primary" } }}
            onChange={toggleSelected}
            checked={selected}
          />
        </Stack>
      </Stack>
      {requiresSurface &&
        selected && ( // only show surface input if required and selected
          <Collapse orientation='horizontal' in={selected} sx={{ width: "100%", "& .MuiCollapse-wrapperInner": { width: "100%" } }}>
            <FormControl
              fullWidth
              onClick={(evt) => {
                evt.stopPropagation();
              }}
            >
              <NovoTextField
                fullWidth
                variant='outlined'
                id={`${title.replace(/\s/g, "-")}-surface-input`}
                data-cy={`${title.replace(/\s/g, "-")}-surface-input`}
                value={surface}
                label='Fläche'
                onChange={(event) => handleSurfaceChange(title, event.target.value)}
                required={true}
                type='number'
                error={!!error}
                helperText={error}
                slotProps={{
                  input: { endAdornment: <InputAdornment position='end'>m²</InputAdornment> },
                  htmlInput: { min: 0, max: maxSurface },
                  inputLabel: { shrink: true },
                }}
              />
            </FormControl>
          </Collapse>
        )}
    </Stack>
  );
}
