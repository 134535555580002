import { TextField, TextFieldProps, TextFieldVariants, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEvent, FocusEvent } from "react";

// Assumes German numbers only for now!
export const NovoGermanPhoneInput = <Variant extends TextFieldVariants>(
  props: {
    /**
     * The variant to use.
     * @default 'outlined'
     */
    variant?: Variant;
    // if this input is supposed to accept only mobile numbers or also landlines
    mobileOnly?: boolean;
  } & Omit<TextFieldProps, "variant">,
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  // mobile phones are between 10-11 numbers long, landlines can go up to 15!
  const pattern = props.mobileOnly ? /^\+49$|^\+49[1-9][0-9]{0,10}$/g : /^\+49$|^\+49[1-9][0-9]{0,14}$/g;

  const telephoneHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (pattern.test(event.target.value)) {
      props.onChange?.(event);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event?.target;
    const length = input.value.length;
    input.setSelectionRange(length, length); // Moves cursor to the end
  };

  return <TextField size={isMobile ? "small" : "medium"} {...props} onChange={telephoneHandler} onFocus={handleFocus} />;
};
