import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid2, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import NovoFileUpload, { FileRef } from "../../../components/common/NovoFileUpload";
import { useFinalizeConsumptionCertManually } from "../../../hooks/useFinalizeConsumptionCertManually";
import { ConsumptionCertificateItem } from "../../../types/ConsumptionCertificateState";
import { UploadCertItem } from "./ConsumptionCertificateTable";

type UploadCertificateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onChange?: (cert: ConsumptionCertificateItem) => void;
  certItem?: UploadCertItem;
};

export default function UploadCertificateDialog({ isOpen, onClose, onChange, certItem }: UploadCertificateDialogProps) {
  const { finalizeConsumptionCert, resetState, updatedConsumptionCert, isRunning, finalizeError } = useFinalizeConsumptionCertManually();
  const [file, setFile] = useState<File>();
  const [registrationNumber, setRegistrationNumber] = useState<string>();
  const [registrationNumberError, setRegistrationNumberError] = useState<string>();
  const [fileError, setFileError] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (updatedConsumptionCert && onChange) {
      onChange(updatedConsumptionCert);
      onGoingToClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedConsumptionCert]);

  useEffect(() => {
    if (finalizeError) {
      setError("Es ist leider ein Fehler aufgetreten");
    } else {
      setError(undefined);
    }
  }, [finalizeError]);

  const handleFinalize = (): void => {
    if (!certItem || isRunning) {
      return;
    }
    if (!file) {
      setFileError(true);
      return;
    }
    if (!registrationNumber) {
      setRegistrationNumberError("Gib die Registriernummer des Verbrauchsausweises ein");
      return;
    }
    if (!/[A-Z]{2}-20[0-9]{2}-[0-9]{9}/g.test(registrationNumber)) {
      setRegistrationNumberError("Ungültige Registriernummer. Format: BB-YYYY-ZZZZZZZZZ (B = Buchstabe, YYYY = Jahr, Z = Zahl)");
      return;
    }
    finalizeConsumptionCert(file, certItem.id, registrationNumber);
  };

  const handleRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationNumber(event.target.value);
    setRegistrationNumberError("");
  };

  const handleFileChange = (value: FileRef[]): void => {
    if (value.length == 1) {
      setFileError(false);
      setFile(value[0].file);
    }
  };

  const onGoingToClose = (): void => {
    setFile(undefined);
    resetState();
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={onGoingToClose} maxWidth={"xl"} data-cy='manual-finalize-cc-modal'>
      <DialogTitle>
        Verbrauchsausweis hochladen
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={onGoingToClose}
          data-cy='cancel-upload-btn'
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers sx={{ py: 3 }}>
        <Stack>
          <Typography pb={2}>
            Lade den Verbrauchsausweis für <b>{certItem?.email ?? certItem?.address}</b> hoch.
          </Typography>
          <Grid2 minWidth={800}>
            <NovoFileUpload
              maxFiles={1}
              error={fileError}
              disabled={isRunning}
              required
              accept='.pdf'
              label='Verbrauchsausweis'
              direction='row'
              onChange={handleFileChange}
            />
            {fileError && (
              <Typography color='error.main' variant='caption'>
                Bitte wähle eine iSFP-Datei aus
              </Typography>
            )}
          </Grid2>
          <FormControl fullWidth sx={{ pt: 2 }}>
            <TextField
              disabled={isRunning}
              error={!!registrationNumberError}
              helperText={registrationNumberError}
              label='Registriernummer'
              id='registrationNumber'
              value={registrationNumber}
              onChange={handleRegistrationNumberChange}
            />
          </FormControl>

          {error && (
            <Alert data-cy='finalize-error' sx={{ marginTop: 2 }} severity='error'>
              {error}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant='outlined' color='secondary' onClick={onGoingToClose} disabled={isRunning}>
          Abbrechen
        </Button>
        <ButtonWithSpinner
          data-cy='consumption-cert-finish-process-btn'
          disabled={!certItem || isRunning}
          variant={"contained"}
          loading={isRunning}
          label='Hochladen und Abschließen'
          onClick={handleFinalize}
        />
      </DialogActions>
    </Dialog>
  );
}
