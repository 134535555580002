import { useCallback } from "react";
import { ConsumptionCertificate, ConsumptionCertificateAnswers } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export type EditConsumptionCertificateAnswersRequest = {
  answers: ConsumptionCertificateAnswers;
  updateCalculations: boolean;
};

export const useUpdateConsumptionCertAnswers = (consumptionCertId: string) => {
  const { authAxios, error, running, data } = useAuthAxios<ConsumptionCertificate>();

  const putAnswers = useCallback(
    async (updateAnswReq: EditConsumptionCertificateAnswersRequest) =>
      authAxios({
        method: "PUT",
        baseURL: `${API_ENDPOINT}/${consumptionCertId}/answers`,
        data: JSON.stringify(updateAnswReq),
      }),
    [authAxios, consumptionCertId],
  );

  return { putAnswers, error, running, updatedCert: data };
};
