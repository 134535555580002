import { useCallback } from "react";
import { useUnauthAxios } from "./useUnauthAxios";
import { apiEndpoint } from "../utils/params";
import { ConsultantEmailValidationResponse } from "../types/types";

export const useValidateConsultantEmail = () => {
  const { unauthAxios, error, running, data } = useUnauthAxios<ConsultantEmailValidationResponse>();

  const validateConsultantEmail = useCallback(
    async (email: string) => {
      await unauthAxios({
        method: "GET",
        url: `${apiEndpoint()}/consultant-email-validation/${email}`,
      });
    },
    [unauthAxios],
  );

  return { validateConsultantEmail, validationError: error, validationRunning: running, validationResponse: data };
};
