import Grid from "@mui/material/Grid2";
import ProgressBar from "../../ProgressBar";
import { Button, Stack, Typography } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

export default function IntroScreen({ next }: RenovationsScreenProps) {
  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={"Jetzt starten!"} value={0} />
      </Grid>
      <Grid size={12}>
        <Stack mx={{ xs: 3, lg: 5 }}>
          <Typography fontSize={{ xs: 14, lg: 16 }}>
            Um Sie bei der Planung Ihrer Sanierung bestmöglich zu unterstützen, werden wir diese Informationen so genau und umfassend wie möglich aufnehmen.
          </Typography>
          <Typography mt={4} fontSize={{ xs: 14, lg: 16 }}>
            Bitte stellen Sie sicher, dass Sie Informationen zu folgenden Themen zur Hand haben:
          </Typography>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Dämmung von Wänden</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Dämmung des Daches</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Kellerdämmung</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Außenfotos Ihrer Immobilie</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Grundrisse</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Schnitte (Seitenansichten)</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Baupläne (soweit vorhanden)</Typography>
          </Stack>
          <Stack direction='row' sx={{ mt: 2 }}>
            <CheckCircleOutlinedIcon sx={{ ml: 4, mr: 1, alignSelf: "baseline", fontSize: { xs: 20, lg: 24 } }} />
            <Typography fontSize={{ xs: 14, lg: 16 }}>Energieausweis (soweit vorhanden)</Typography>
          </Stack>
          <Typography mt={4} fontSize={{ xs: 14, lg: 16 }}>
            Alle Eingaben werden zwischengespeichert und Sie können jederzeit zum letzten Bearbeitungsstand zurückkehren.
          </Typography>
          <Typography mt={4} fontSize={{ xs: 14, lg: 16 }}>
            Sobald Sie bereit sind, klicken Sie auf die Schaltfläche unten, um zu beginnen. Herzlichen Dank!
          </Typography>
          <Stack direction='row' spacing={2} sx={{ mt: 8, justifyContent: "center" }}>
            <Button variant='contained' data-cy='im-ready-btn' onClick={next}>
              Ich bin bereit!
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
