import { Stack, Typography } from "@mui/material";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpSchnellcheckInProgress({ row }: RowDetailProps) {
  return (
    <Stack direction='row' flexWrap='wrap'>
      {row.status == IsfpProcessStatus.SCHNELLCHECK_STARTED && (
        <>
          <Typography>Schnellcheck wird ausgefüllt</Typography>
          {row.eventTimestamps?.schnellcheckStartedOn && (
            <>
              <Typography>. Er wurde am</Typography>
              &nbsp;
              <Typography sx={{ fontStyle: "italic" }}>{formatDate(row.eventTimestamps?.schnellcheckStartedOn, "dd.MM.yyyy") + " begonnen"}</Typography>
            </>
          )}
          <Typography>.</Typography>
        </>
      )}
    </Stack>
  );
}
