import Grid from "@mui/material/Grid2";
import ProgressBar from "../ProgressBar";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ActionContainer from "../../../components/ActionContainer";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import useResumeConsumptionCertificate from "../../../hooks/useResumeConsumptionCertificate";
import { useEffect } from "react";

export default function FinalScreen({ consumptionCertificate }: ConsumptionCertificateScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { clearLocal } = useResumeConsumptionCertificate();

  useEffect(() => {
    clearLocal(); // Once reached here, CC is completed.
  }, [clearLocal]);

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={"Fertiggestellt"} value={100} />
      </Grid>
      <Grid size={12}>
        <ActionContainer
          sx={{ minHeight: "50vh", backgroundColor: "background.default" }}
          minHeight={100}
          showAppBar={false}
          sticker={{ image: "agent.svg", t: isMobile ? -4 : -20, l: isMobile ? -1 : -4, s: isMobile ? 60 : 100 }}
        >
          <Stack pl={isMobile ? 6 : 8}>
            <Typography data-cy='thank-you-text' fontWeight={"fontWeightMedium"} fontSize={isMobile ? "1.5rem" : "2rem"}>
              Vielen Dank{consumptionCertificate.leadName ? `, ${consumptionCertificate.leadName}` : ""}!
            </Typography>
            <Typography mt={2} fontWeight={"fontWeightMedium"} fontSize={isMobile ? "1rem" : "1.2rem"}>
              Wir haben Ihre Daten erfasst und erstellen nun Ihren Energieausweis.
            </Typography>
            <Typography mt={2} mb={2} fontSize={isMobile ? "1rem" : "1.2rem"}>
              Dieser wird in der Regel spätestens 48h nach Bestellung an die angegebene E-Mailadresse gesendet.
            </Typography>
            <Typography mt={2} mb={4} fontSize={isMobile ? "1rem" : "1.2rem"}>
              Sie können jetzt diese Seite verlassen.
            </Typography>
          </Stack>
        </ActionContainer>
      </Grid>
    </Grid>
  );
}
