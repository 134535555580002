import axios from "axios";
import { useUnauthAxios } from "./useUnauthAxios";
import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { UpsertConsumptionCertificate } from "../pages/funnels/consumption-certificate/types";
import { generateFilenameBasedId, sanitizeFilename } from "../utils/utils";
import { FileRef } from "../components/common/NovoFileUpload";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;
const headers = { "Content-Type": "application/json" };

export default function useUpsertConsumptionCertificate() {
  const { unauthAxios, error, running, data } = useUnauthAxios<UpsertConsumptionCertificate>();

  const upsertConsumptionCertificate = useCallback(
    async (request: UpsertConsumptionCertificate) => {
      const requestUri = `${API_ENDPOINT}/upsert`;
      await unauthAxios({
        method: "POST",
        url: requestUri,
        data: request,
        headers,
      });
    },
    [unauthAxios],
  );

  const uploadPhoto = async ({ id, name, file }: FileRef) => {
    if (!file) return;
    let fileId = id;
    if (!fileId) {
      if (!name) return;
      const filename = sanitizeFilename(file.name);
      fileId = generateFilenameBasedId(filename);
    }
    const requestUri = `${API_ENDPOINT}/photo-upload-link?photoId=${fileId}`;
    const response = await axios.get(requestUri, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { presignedLink, key } = response.data;
    await axios.put(presignedLink, file, {
      headers: {
        "Content-Type": file.type,
        "Access-Control-Allow-Origin": "*",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        responseType: "json",
      },
    });
    return key as string;
  };

  return { upsertConsumptionCertificate, uploadPhoto, upsertError: error, upsertRunning: running, upsertConsumptionCertificateResponse: data };
}
