import { useCallback } from "react";
import { IsfpProcessStatus, Lead } from "../types/cockpit/types";
import { ConsumptionCertificateItemState } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/leads`;

export type ConsumptionCertSearchStatus = ConsumptionCertificateItemState | "invited";

export type SearchParams = {
  // whether to use backend cached results or not
  cache?: boolean;
  searchString?: string;
  wasInvited?: boolean;
  ccStatus?: ConsumptionCertSearchStatus[];
  isfpStatus?: IsfpProcessStatus[];
};

export default function useFetchLeads() {
  const { authAxios, error, running, data } = useAuthAxios<Lead[]>();

  const getLeads = useCallback(
    async (params: { order: string; orderBy: string; page: number; pageSize: number } & SearchParams) => {
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
        params,
      });
    },
    [authAxios],
  );

  return { getLeads, error, isLoading: running, leads: data };
}
