import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/tenant`;

type TenantUsersResponseType = {
  users: UserProfile[];
};

type UserProfile = {
  name: string;
  email: string;
};

export default function useFetchTenantUsers() {
  const { authAxios, error, running, data } = useAuthAxios<TenantUsersResponseType>();

  const getTenantUsers = useCallback(
    async (tenantId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${tenantId}/users`,
      }),
    [authAxios],
  );

  return { getTenantUsers, error, isLoading: running, tenantUsers: data?.users };
}
