import { Box, Stack, Typography } from "@mui/material";
import { RowDetailProps } from "./types";
import { useFetchIsfpDocumentUrl } from "../../../../hooks/useFetchIsfpDocument";
import { useEffect } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { formatDate } from "date-fns";

export default function IsfpFinished({ row }: RowDetailProps) {
  const { fetchIsfpDocumentUrl, isfpDocumentLink, fetchDocumentRunning } = useFetchIsfpDocumentUrl();

  const openPdfDoc = () => {
    fetchIsfpDocumentUrl(row.id);
  };

  useEffect(() => {
    if (isfpDocumentLink) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.href = isfpDocumentLink.url;
      link.click();
    }
  }, [isfpDocumentLink]);

  return (
    <Stack direction='row' alignItems='baseline' flexWrap='wrap'>
      <Typography>iSFP Dokument wurde</Typography>
      {row.eventTimestamps?.isfpDocumentFinishedOn && (
        <>
          &nbsp;
          <Typography>am</Typography>
          &nbsp;
          <Typography sx={{ fontStyle: "italic" }}>{formatDate(row.eventTimestamps?.isfpDocumentFinishedOn, "dd.MM.yyyy")}</Typography>
        </>
      )}
      &nbsp;
      <Typography>angefertigt.</Typography>
      <Box pl={2}>
        <ButtonWithSpinner variant='outlined' color='secondary' loading={fetchDocumentRunning} label='Dokument herunterladen' onClick={openPdfDoc} />
      </Box>
    </Stack>
  );
}
