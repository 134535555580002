import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useCallback, useState } from "react";
import { retrieveRum } from "../AwsRum";
import { RequestError } from "../types/types";

export const useUnauthAxios = <ReturnType>() => {
  const [responseStatus, setStatus] = useState<number>();
  const [running, setRunning] = useState<boolean>(false);
  const [error, setError] = useState<RequestError>();
  const [data, setData] = useState<ReturnType>();

  const unauthAxios = useCallback(async (req: AxiosRequestConfig) => {
    setData(undefined);
    setRunning(true);
    try {
      const { data, status } = await axios.request(req);
      setStatus(status);
      setData(data);
    } catch (error) {
      retrieveRum()?.recordError(error);
      const err = error as Error | AxiosError;
      let errorMsg;
      if (axios.isAxiosError(err)) {
        setStatus(err.response?.status);
        errorMsg = err.response?.data.message;
      }
      setError({
        ...error,
        errorMsg,
      });
    } finally {
      setRunning(false);
    }
  }, []);

  return { unauthAxios, data, responseStatus, error, running };
};
