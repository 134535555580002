import LaunchIcon from "@mui/icons-material/Launch";
import { Stack, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { formatDate } from "date-fns";
import { useEffect, useState } from "react";
import { useFetchLead } from "../../../../hooks/useFetchLead";
import { useFetchSchnellcheck } from "../../../../hooks/useFetchSchnellcheck";
import { DisplayBubbleFlowSchnellcheckDialog } from "../../leads/leadlist/DisplayBubbleFlowSchnellcheckDialog";
import { DisplaySchnellcheckDialog } from "../../leads/leadlist/DisplaySchnellcheckDialog";
import { RowDetailProps } from "./types";
import Loading from "../../../../components/common/Loading";

export default function IsfpSchnellcheckReady({ row }: RowDetailProps) {
  const { getSchnellcheck, isLoading, schnellcheck } = useFetchSchnellcheck();
  const [isSchnellcheckOpen, setSchnellcheckOpen] = useState(false);
  const { getLead, lead } = useFetchLead();
  const [isBubbleSchnellCheckOpen, setBubbleSchnellCheckOpen] = useState(false);

  const toggleSchnellcheckModal = () => {
    setSchnellcheckOpen(!isSchnellcheckOpen);
  };

  const toggleBubbleSchnellCheckModal = () => {
    setBubbleSchnellCheckOpen(!isBubbleSchnellCheckOpen);
  };

  const openQuestionnaireDetails = async (questionnaireId?: string) => {
    if (!questionnaireId) {
      return;
    }
    getSchnellcheck(questionnaireId);
  };

  const openBubbleQuestionnaireDetails = async (leadId?: string) => {
    if (!leadId) {
      return;
    }
    getLead(leadId);
  };

  useEffect(() => {
    setSchnellcheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schnellcheck]);

  useEffect(() => {
    setBubbleSchnellCheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <Stack direction='row' flexWrap='wrap'>
      <Typography>Schnellcheck wurde</Typography>
      {row.eventTimestamps?.schnellcheckFinishedOn && (
        <>
          &nbsp;
          <Typography>am</Typography>
          &nbsp;
          <Typography sx={{ fontStyle: "italic" }}>{formatDate(row.eventTimestamps?.schnellcheckFinishedOn, "dd.MM.yyyy")}</Typography>
        </>
      )}
      &nbsp;
      <Typography>ausgefüllt.</Typography>
      {row.schnellCheckId && (
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
            pl: 2,
          }}
          onClick={() => openQuestionnaireDetails(row.schnellCheckId)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
      )}
      {!row.schnellCheckId && (
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
            pl: 2,
          }}
          onClick={() => openBubbleQuestionnaireDetails(row.leadId)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
      )}
      <DisplaySchnellcheckDialog schnellcheck={schnellcheck!} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
      <DisplayBubbleFlowSchnellcheckDialog
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bubbleFlow={lead?.properties?.find((property) => property.isfp?.bubbleFlow)?.isfp?.bubbleFlow!}
        open={isBubbleSchnellCheckOpen}
        handleClose={toggleBubbleSchnellCheckModal}
      />
      {isLoading && <Loading />}
    </Stack>
  );
}
