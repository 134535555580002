import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import { UpsertConsumptionCertificate } from "./types";
import { useCreatePurchaseOrder } from "../../../hooks/useCreatePurchaseOrder";
import { useEffect } from "react";
import { Product } from "../../../types/types";
import { OrderPaymentStatus } from "../../../types/PurchaseOrder";
import { useSearchParams } from "react-router-dom";
import StripeEmbeddedCheckout from "../../../components/common/stripe/StripeEmbeddedCheckout";

export default function CheckoutScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const { createPurchaseOrder, createOrderRunning, createdPurchaseOrder: purchaseOrder } = useCreatePurchaseOrder();
  const [searchParams] = useSearchParams();
  const voucherId = searchParams.get("voucherId") ?? undefined;

  useEffect(() => {
    if (consumptionCertificate.id) {
      const purchaseOrderRequest = {
        leadId: consumptionCertificate.leadId,
        voucherId: voucherId,
        items: [{ id: consumptionCertificate.id, product: Product.consumptionCertificate }],
      };
      createPurchaseOrder(purchaseOrderRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (purchaseOrder && purchaseOrder.paymentData?.paymentStatus === OrderPaymentStatus.NoPaymentRequired) {
      return validateAndSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder]);

  const validate = () => {
    // TODO: Add validators
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
        },
        isComplete: true,
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Checkout</strong>
          </Typography>
        </Grid>
        <Grid size={12}>{!!purchaseOrder && <StripeEmbeddedCheckout orderId={purchaseOrder?.id} onComplete={validateAndSave} />}</Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      hideNext={true}
      progressLabel='9 von 10'
      progressValue={90}
      isUpdateRunning={isUpdateRunning || createOrderRunning}
      content={content}
    />
  );
}
