import Grid from "@mui/material/Grid2";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import { RenewableEnergySytem, RenewableEnergyUsage, VentilationType } from "./ConsumptionCertificateRequestEnums";
import { useState } from "react";
import { UpsertConsumptionCertificate } from "./types";
import NovoTextField from "../../../components/common/NovoTextField";
import NovoMultiSelect from "../../../components/common/NovoMultiSelect";

export default function MiscScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [ventilationType, setVentilationType] = useState<string[]>(consumptionCertificate.answers?.ventilationType ?? []);
  const [ventilationTypeError, setVentilationTypeError] = useState<string>();
  const [renewable, setRenewable] = useState<string[]>(consumptionCertificate.answers?.renewable ?? []);
  const [renewableUsage, setRenewableUsage] = useState<string[]>(consumptionCertificate.answers?.renewableUsage ?? []);
  const [renewableUsageError, setRenewableUsageError] = useState<string>();
  const [renovationMeasures, setRenovationMeasures] = useState<string>(consumptionCertificate.answers?.renovationMeasures ?? "");

  const validate = () => {
    if (ventilationType.length === 0) {
      setVentilationTypeError("Pflichtfeld");
      return false;
    } else {
      setVentilationTypeError(undefined);
    }
    if (renewable.length > 0 && renewableUsage.length === 0) {
      setRenewableUsageError("Bei der Wahl einer erneuerbaren Energiequelle muss der Verbrauch angegeben werden");
      return false;
    } else {
      setRenewableUsageError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
          ventilationType,
          renewable,
          renewableUsage,
          renovationMeasures,
        },
        isComplete: false, // TODO: pass as True if payment not required
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Sonstiges</strong>
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoMultiSelect
            data-cy='ventilation-type-select'
            label='Art der Lüftung'
            options={[...Object.keys(VentilationType)]}
            value={ventilationType}
            required={true}
            error={!!ventilationTypeError}
            helperText={ventilationTypeError}
            onUpdate={(value: Array<string>) => {
              setVentilationType(value);
              setVentilationTypeError(undefined);
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <NovoMultiSelect
            data-cy='renewable-energy-select'
            label='Erneuerbare Energien'
            options={[...Object.keys(RenewableEnergySytem)]}
            value={renewable}
            onUpdate={(value: Array<string>) => setRenewable(value)}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <NovoMultiSelect
            data-cy='renewable-energy-usage-select'
            label='Verwendung erneuerbarer Energien'
            options={[...Object.keys(RenewableEnergyUsage)]}
            value={renewableUsage}
            disabled={!renewable.length}
            error={!!renewableUsageError}
            helperText={renewableUsageError}
            onUpdate={(value: Array<string>) => {
              setRenewableUsage(value);
              setRenewableUsageError(undefined);
            }}
          />
        </Grid>
        <Grid size={12}>
          <FormControl fullWidth>
            <NovoTextField
              variant='outlined'
              id='renovation-measures'
              value={renovationMeasures}
              type='text'
              multiline
              rows={2}
              label='bereits erfolgte Sanierungsmaßnahmen'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRenovationMeasures(event.target.value)}
            />
          </FormControl>
          <FormHelperText id='renovation-measures-helper-text'>Fenster gewechselt, Dämmung erhöht, ...</FormHelperText>
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={validateAndSave} progressLabel='8 von 10' progressValue={80} isUpdateRunning={isUpdateRunning} content={content} />;
}
