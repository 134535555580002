import Grid from "@mui/material/Grid2";
import { FormControl, InputAdornment, Stack, Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import NovoSelect from "../../../components/common/NovoSelect";
import NovoAddressAutocomplete, { AddressDetails, getAddressDetails, includesHouseNumber } from "../../../components/common/NovoAddressAutocomplete";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import HelpIcon from "@mui/icons-material/Help";
import { GermanState, Purpose } from "./ConsumptionCertificateRequestEnums";
import { Fragment, useEffect, useState } from "react";
import { UpsertConsumptionCertificate } from "./types";
import NovoTextField from "../../../components/common/NovoTextField";
import useVerifyPLZ from "../../../hooks/useVerifyPLZ";

const today = new Date();
const time = new Date(today.getFullYear() - 1, 0, 0);

export default function BuildingScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const { verifyPLZ, plzStatus } = useVerifyPLZ();
  const [reason, setReason] = useState<string>(consumptionCertificate.answers?.reason ?? "");
  const [reasonError, setReasonError] = useState<string>();
  const [surface, setSurface] = useState<string>(consumptionCertificate.answers?.area ? consumptionCertificate.answers?.area.toString() : "");
  const [surfaceError, setSurfaceError] = useState<string>();
  const [autocompleteAddress, setAutocompleteAddress] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [address, setAddress] = useState<string>(consumptionCertificate.answers?.address ?? "");
  const [addressError, setAddressError] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>(consumptionCertificate.answers?.postalCode ?? "");
  const [postalCodeError, setPostalCodeError] = useState<string>();
  const [town, setTown] = useState<string>(consumptionCertificate.answers?.location ?? "");
  const [townError, setTownError] = useState<string>();
  const [bundesland, setBundesland] = useState<string>(consumptionCertificate.answers?.bundesland ?? "");
  const [bundeslandError, setBundeslandError] = useState<string>();

  useEffect(() => {
    if (plzStatus) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plzStatus]);

  const validate = () => {
    if (!reason) {
      setReasonError("Pflichtfeld");
      return false;
    } else {
      setReasonError(undefined);
    }
    if (!surface) {
      setSurfaceError("Pflichtfeld");
      return false;
    } else {
      setSurfaceError(undefined);
    }
    if (!address) {
      setAddressError("Pflichtfeld");
      return false;
    } else if (!includesHouseNumber(autocompleteAddress)) {
      setAddressError("Geben Sie die Hausnummer an");
      return false;
    } else {
      setAddressError(undefined);
    }
    if (!postalCode) {
      setPostalCodeError("Pflichtfeld");
      return false;
    } else if (!postalCode.match(/^[0-9]{5}$/)) {
      setPostalCodeError("5-stellig, z.B. 10115");
      return false;
    } else if (plzStatus !== 200) {
      setPostalCodeError("Unbekannte Postleitzahl");
      return false;
    } else {
      setPostalCodeError(undefined);
    }
    if (!town) {
      setTownError("Pflichtfeld");
      return false;
    } else {
      setTownError(undefined);
    }
    if (!bundesland) {
      setBundeslandError("Pflichtfeld");
      return false;
    } else {
      setBundeslandError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
          reason,
          area: +surface,
          address,
          postalCode,
          location: town,
          bundesland,
        },
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Gebäudeinformation</strong>
          </Typography>
        </Grid>
        <Grid size={6}>
          <NovoSelect
            id='reason-select'
            dataCy={{ field: "reason-select", values: Object.values(Purpose) }}
            label='Anlass'
            options={[...Object.values(Purpose)]}
            value={reason}
            onUpdate={(value) => {
              setReason(value);
              setReasonError(undefined);
            }}
            error={!!reasonError}
            helperText={reasonError}
            required={true}
          />
        </Grid>
        <Grid size={6}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <FormControl fullWidth>
              <NovoTextField
                data-cy='sq-meters-input'
                variant='outlined'
                id='sq-meters-input'
                value={surface}
                type='number'
                label='Wohnfläche'
                onChange={(event) => {
                  setSurface(event.target.value);
                  setSurfaceError(undefined);
                }}
                required={true}
                error={!!surfaceError}
                helperText={surfaceError}
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
                  },

                  htmlInput: { min: 10 },
                }}
              />
            </FormControl>
            <NovoTooltip
              title={
                <Fragment>
                  <Typography variant='body2'>
                    Die Wohnfläche einer Wohnung umfasst die Grundflächen der Räume, die ausschließlich zu dieser Wohnung gehören, wie zum Beispiel:
                    <ul>
                      <li>Schlafzimmer</li>
                      <li>Wohnzimmer</li>
                      <li>Küche</li>
                      <li>Badezimmer</li>
                      <li>Esszimmer</li>
                    </ul>
                    Kellerräume, Garagen, und Abstellräume gehören nicht zur Wohnfläche.
                  </Typography>
                </Fragment>
              }
            >
              <HelpIcon />
            </NovoTooltip>
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <NovoAddressAutocomplete
              id='street-address-input'
              data_cy='street-address-input'
              address={autocompleteAddress}
              prefilledInput={address}
              updateAddress={(autocompleteAddress: google.maps.places.AutocompletePrediction | null) => {
                setAutocompleteAddress(autocompleteAddress);
                setAddressError(undefined);
                if (autocompleteAddress) {
                  getAddressDetails(autocompleteAddress, (address: AddressDetails) => {
                    setAddress(address.address ?? "");
                    setPostalCode(address.postalCode ?? "");
                    setTown(address.town ?? "");
                    setBundesland(address.state ?? "");
                    if (address.postalCode) {
                      verifyPLZ(address.postalCode, time.getTime());
                    }
                  });
                }
              }}
              addressError={addressError}
            />
            <NovoTooltip title='Wenn Ihre Adresse in den Vorschlägen nicht enthalten ist, geben Sie weitere Informationen ein, beispielsweise Stadt oder Stadtteil.'>
              <HelpIcon />
            </NovoTooltip>
          </Stack>
        </Grid>
        <Grid size={2}>
          <FormControl fullWidth>
            <NovoTextField
              data-cy='plz-input'
              variant='outlined'
              id='plz-input'
              type='number'
              label='PLZ'
              value={postalCode}
              onChange={(event) => {
                const plz = event.target.value;
                setPostalCode(plz);
                setPostalCodeError(undefined);
                if (plz && plz.match(/^[0-9]{5}$/)) {
                  verifyPLZ(plz, time.getTime());
                }
              }}
              error={!!postalCodeError}
              helperText={postalCodeError}
              required={true}
              slotProps={{
                htmlInput: { pattern: /^[0-9]{5}$/ },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={5}>
          <FormControl fullWidth>
            <NovoTextField
              data-cy='city-input'
              variant='outlined'
              id='ort-input'
              label='Ort'
              value={town}
              onChange={(event) => {
                setTown(event.target.value);
                setTownError(undefined);
              }}
              error={!!townError}
              helperText={townError}
              required={true}
            />
          </FormControl>
        </Grid>
        <Grid size={5}>
          <NovoSelect
            data-cy='country-state-select'
            label='Bundesland'
            options={[...Object.values(GermanState)]}
            value={bundesland}
            onUpdate={(value) => {
              setBundesland(value);
              setBundeslandError(undefined);
            }}
            error={!!bundeslandError}
            helperText={bundeslandError}
            required={true}
          />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={validateAndSave} progressLabel='3 von 10' progressValue={30} isUpdateRunning={isUpdateRunning} content={content} />;
}
