import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../../components/common/NovoLogo";
import CssBaseline from "@mui/material/CssBaseline";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UpsertConsumptionCertificate } from "./types";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Steps } from "./utils";
import ConsentSnackbar from "../Consent";
import Smartlook from "smartlook-client";
import { retrieveRum } from "../../../AwsRum";
import usePutMetric from "../../../hooks/usePutMetric";
import useUpsertConsumptionCertificate from "../../../hooks/useUpsertConsumptionCertificate";
import useResumeConsumptionCertificate from "../../../hooks/useResumeConsumptionCertificate";
import { ScreenProps } from "../FormScreen";
import CustomerScreen from "./CustomerScreen";
import TriageScreen from "./TriageScreen";
import BuildingScreen from "./BuildingScreen";
import HeatingScreen from "./HeatingScreen";
import PhotoUploadScreen from "./PhotoUploadScreen";
import HotwaterScreen from "./HotwaterScreen";
import MiscScreen from "./MiscScreen";
import CoolingScreen from "./CoolingScreen";
import FinalScreen from "./FinalScreen";
import CheckoutScreen from "./CheckoutScreen";
import IntroScreen from "./IntroScreen";

export type ConsumptionCertificateScreenProps = ScreenProps & {
  consumptionCertificate: UpsertConsumptionCertificate;
  setConsumptionCertificate: Dispatch<SetStateAction<UpsertConsumptionCertificate>>;
};

export default function ConsumptionCertificateFunnel() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [putMetric] = usePutMetric();
  const leadId = searchParams.get("leadId") ?? undefined;
  const [consumptionCertificate, setConsumptionCertificate] = useState<UpsertConsumptionCertificate>({ leadId, isComplete: false });
  const { upsertConsumptionCertificate, upsertRunning, upsertConsumptionCertificateResponse } = useUpsertConsumptionCertificate();
  const { resumeConsumptionCertificate, toLocal, resuming } = useResumeConsumptionCertificate();
  const [goNext, setGoNext] = useState(false);
  const tenant = searchParams.get("tenant");
  const location = useLocation(); // To get the current pathname
  const pathWithTenant = `${location.pathname}${tenant ? `?tenant=${tenant}` : ""}`;

  const navigateToStep = (step: Steps, wasResumed = false) => {
    const pathWithHash = `${pathWithTenant}${step}`;
    putMetric(pathWithHash, "RenderPage");
    if (wasResumed) {
      putMetric(pathWithHash, "ResumeFunnel");
    }
    navigate(`${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ""}${step}`);
  };

  useEffect(() => {
    resumeConsumptionCertificate(leadId).then((response) => {
      if (response) {
        //Consent is only validated client-side, so if it exists in the backend, consent was given
        setConsumptionCertificate({ ...response, consultantDataReviewConsent: true, isComplete: response.state !== "started" });
        if (response.state !== "started") {
          navigateToStep(Steps.final, true);
        } else if (response.answers?.ventilationType && response.answers.ventilationType.length >= 1) {
          navigateToStep(Steps.misc, true);
        } else if (response.answers?.coolingSystems && response.answers.coolingSystems.length >= 1) {
          navigateToStep(Steps.misc, true);
        } else if (response.answers?.hotWaterSystems && response.answers?.hotWaterSystems.length >= 1) {
          navigateToStep(Steps.cooling, true);
        } else if (response.answers?.verbrauch3) {
          navigateToStep(Steps.hotwater, true);
        } else if (response.answers?.photoId) {
          navigateToStep(Steps.heating, true);
        } else if (response.answers?.bundesland) {
          navigateToStep(Steps.photo, true);
        } else if (response.answers?.numberOfApartments) {
          navigateToStep(Steps.building, true);
        } else if (response.consultantDataReviewConsent) {
          navigateToStep(Steps.eligibility, true);
        } else {
          navigateToStep(Steps.start, true);
        }
        putMetric(pathWithTenant, "ResumeFunnel");
      }
    });
    if (leadId) {
      Smartlook.identify(leadId, {});
      retrieveRum()?.addSessionAttributes({ userId: leadId });
      putMetric(pathWithTenant, "RenderStartPage", leadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    switch (hash) {
      case Steps.start:
        navigateToStep(Steps.customer);
        break;
      case Steps.customer:
        navigateToStep(Steps.eligibility);
        break;
      case Steps.eligibility:
        navigateToStep(Steps.building);
        break;
      case Steps.building:
        navigateToStep(Steps.photo);
        break;
      case Steps.photo:
        navigateToStep(Steps.heating);
        break;
      case Steps.heating:
        navigateToStep(Steps.hotwater);
        break;
      case Steps.hotwater:
        navigateToStep(Steps.cooling);
        break;
      case Steps.cooling:
        navigateToStep(Steps.misc);
        break;
      case Steps.misc:
        navigateToStep(Steps.checkout);
        break;
      case Steps.checkout:
        navigateToStep(Steps.final);
        break;
      default:
        navigateToStep(Steps.start);
    }
  };

  const prev = () => {
    switch (hash) {
      case Steps.customer:
        navigateToStep(Steps.start);
        break;
      case Steps.eligibility:
        navigateToStep(Steps.customer);
        break;
      case Steps.building:
        navigateToStep(Steps.eligibility);
        break;
      case Steps.photo:
        navigateToStep(Steps.building);
        break;
      case Steps.heating:
        navigateToStep(Steps.photo);
        break;
      case Steps.hotwater:
        navigateToStep(Steps.heating);
        break;
      case Steps.cooling:
        navigateToStep(Steps.hotwater);
        break;
      case Steps.misc:
        navigateToStep(Steps.cooling);
        break;
      case Steps.checkout:
        navigateToStep(Steps.misc);
        break;
      case Steps.final:
        navigateToStep(Steps.checkout);
        break;
      default:
        navigateToStep(Steps.start);
    }
  };

  useEffect(() => {
    if (upsertConsumptionCertificateResponse && !resuming) {
      setConsumptionCertificate(upsertConsumptionCertificateResponse);
      if (upsertConsumptionCertificateResponse.id) {
        toLocal(upsertConsumptionCertificateResponse.id);
      }
      if (goNext) {
        setGoNext(false);
        next();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertConsumptionCertificateResponse, resuming]);

  const saveAndNext = (payload: UpsertConsumptionCertificate) => {
    const certGuard = (arg: unknown): arg is UpsertConsumptionCertificate => {
      return (
        !!(arg as UpsertConsumptionCertificate).leadName &&
        !!(arg as UpsertConsumptionCertificate).leadEmail &&
        !!(arg as UpsertConsumptionCertificate).leadTelephone
      );
    };
    const reqBody = certGuard(payload) ? payload : consumptionCertificate;
    setGoNext(true);
    upsertConsumptionCertificate(reqBody);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f4f5f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <NovoLogo fileName='dena-logo.png' height={48} />
        </Toolbar>
      </AppBar>
      <Backdrop sx={(theme) => ({ color: "#ffffff", zIndex: theme.zIndex.drawer + 1 })} open={resuming}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            maxWidth: "lg",
            mx: "auto",
            bgcolor: "background.default",
            px: { xs: 0, lg: 8 },
            pb: 4,
          }}
        >
          <Title step={hash} />
          {hash === Steps.start && (
            <IntroScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={next}
            />
          )}
          {hash === Steps.customer && (
            <CustomerScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.eligibility && (
            <TriageScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.building && (
            <BuildingScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.photo && (
            <PhotoUploadScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.heating && (
            <HeatingScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.hotwater && (
            <HotwaterScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.cooling && (
            <CoolingScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.misc && (
            <MiscScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.checkout && (
            <CheckoutScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.final && (
            <FinalScreen
              key={consumptionCertificate.id}
              consumptionCertificate={consumptionCertificate}
              isUpdateRunning={upsertRunning}
              setConsumptionCertificate={setConsumptionCertificate}
              prev={prev}
              next={next}
            />
          )}
        </Stack>
      </Box>
      <ConsentSnackbar smartlookId='5abe1187b106b0cb2e77b582a599550690eca13f' metricName='ConsumptionCertificate' />
    </Box>
  );
}

function Title({ step }: { step: string }) {
  return (
    <Grid container>
      {step !== Steps.final && (
        <Grid size={12}>
          <Typography
            variant='h4'
            sx={{
              fontWeight: "fontWeightBold",
              fontSize: { xs: 20, lg: 34 },
              lineHeight: 1.4,
              pb: 0,
              m: { xs: 3, lg: 5 },
            }}
          >
            Ihr Verbrauchsausweis
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
