import Chip from "@mui/material/Chip";
import { EnergyConsumption } from "../types/types";
import { Product } from "../types/cockpit/types";
import { v4 } from "uuid";

export const createTags = (input: string[]) => {
  return input.map((e) => <Chip label={e} key={e} />);
};

export const isTelephoneValid = (telephone: string) => {
  return telephone && /(^0|^\+49)\s*[1-9][0-9]{2,3}\s*[0-9]{0,3}\s*[0-9]{0,4}$/g.test(telephone);
};

export const isVolksbank = (tenantName?: string) => {
  return tenantName?.includes("volksbank") || tenantName?.includes("vr_bank");
};

export const calculateEnergyCostSavings = (consumptionBefore: EnergyConsumption, consumptionAfter: EnergyConsumption): number => {
  const costPerYearBefore = consumptionBefore.costPerYear;
  const costPerYearAfter = consumptionAfter.costPerYear;
  return ((costPerYearBefore - costPerYearAfter) / costPerYearBefore) * 100;
};

export const formatNum = (num?: number): string => {
  return num ? new Intl.NumberFormat("de-de").format(num) : "-";
};

export const formatEuros = (num?: number): string => {
  return num ? new Intl.NumberFormat("de-de", { minimumFractionDigits: 2 }).format(num) : "-";
};

export const convertClassToColor = (energyClass: string) => {
  switch (energyClass) {
    case "A+":
      return "#22fe16";
    case "A":
      return "#97f525";
    case "B":
      return "#bbff00";
    case "C":
      return "#e0fe00";
    case "D":
      return "#fefe02";
    case "E":
      return "#fef200";
    case "F":
      return "#fcda00";
    case "G":
      return "#fe7b00";
    case "H":
      return "#fc3700";
    default:
      return "#ffffff";
  }
};

export const getProductCost = (product: Product) => {
  switch (product) {
    case Product.consumptionCertificate:
      return "59€";
    case Product.onePager:
    case Product.requirementCertificate:
      return "249€";
    case Product.isfp:
    default:
      return "";
  }
};

export function generateFilenameBasedId(filename: string): string {
  const uuid = v4();
  const tokens = filename.split(".");
  let name: string, extension: string;
  if (tokens.length < 2) {
    name = tokens[0];
    extension = "";
  } else {
    const lastToken = tokens.pop() || "";
    name = tokens.join(".");
    extension = lastToken;
  }
  name = name + "-" + uuid.slice(0, 7);
  return `${name}.${extension}`;
}

export function sanitizeFilename(filename: string, replaceDiacritics: boolean = true) {
  const tokens = filename.split(".");
  let name: string, extension: string;
  if (tokens.length < 2) {
    name = tokens[0];
    extension = "";
  } else {
    const lastToken = tokens.pop() || "";
    name = tokens.join(".");
    extension = lastToken.replace(/[^A-Za-z]/g, "");
  }
  if (replaceDiacritics) {
    name = name.normalize("NFC").replace(/\W/g, (char) => DIACRITICS[char] || char);
  }
  name = name.replace(/[^A-Za-z0-9\-_]/g, "");
  return `${name}.${extension}`;
}

const DIACRITICS: { [key: string]: string } = {
  Ä: "Ae",
  Ö: "Oe",
  Ü: "Ue",
  ä: "ae",
  ö: "oe",
  ü: "ue",
  ß: "ss",
  é: "e",
  è: "e",
  ê: "e",
  à: "a",
  â: "a",
  ã: "a",
  î: "i",
  ï: "i",
  ô: "o",
  û: "u",
  ù: "u",
  ç: "c",
  ñ: "n",
  É: "E",
  È: "E",
  Ê: "E",
  À: "A",
  Â: "A",
  Ã: "A",
  Î: "I",
  Ï: "I",
  Ô: "O",
  Û: "U",
  Ù: "U",
  Ç: "C",
  Ñ: "N",
};
