import Box from "@mui/material/Box";
import useImage from "../../hooks/useImage";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

export default function Sophia({ content = defaultHelpContent }: { content?: React.FC }) {
  const bubble = useImage("bubble.svg");
  const sophia = useImage("sophia-avatar.png");
  return (
    <Stack alignContent={"center"} maxWidth={"288px"} mx={"auto"}>
      <Box height={"302px"}>
        <img src={bubble.image} style={{ position: "absolute" }} />
        <Typography
          fontSize={11}
          fontWeight={700}
          sx={{
            position: "relative",
            top: 30,
            left: 20,
          }}
        >
          Warum benötigen wir diese Daten?
        </Typography>
        {content({})}
      </Box>
      <Box
        sx={{
          backgroundImage: `url('${sophia.image}')`,
          backgroundSize: "cover",
          borderRadius: "50%",
          alignSelf: "flex-end",
          width: 48,
          height: 48,
        }}
      />
    </Stack>
  );
}

function defaultHelpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Lorem ipsum dolor sit amet,
      <br />
      consectetur adipiscing elit.
      <br />
      Mauris quis odio ac purus
      <br />
      efficitur vehicula sit amet eget orci.
      <br />
      <br />
      Vestibulum dignissim pretium
      <br />
      justo in dapibus. Donec sed
      <br />
      laoreet libero, in imperdiet dolor.
    </Typography>
  );
}
