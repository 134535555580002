import Grid from "@mui/material/Grid2";
import { Checkbox, FormControl, FormControlLabel, Stack, styled, Tooltip, Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import NovoSelect from "../../../components/common/NovoSelect";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { getMonth, getMonthKey, HeatingSystem, Month } from "./ConsumptionCertificateRequestEnums";
import { useState } from "react";
import { UpsertConsumptionCertificate } from "./types";
import NovoTextField from "../../../components/common/NovoTextField";
import { addMonths, differenceInMonths, format, subDays, subMonths } from "date-fns";
import { ContactRequestRequired } from "./ContactRequestRequired";

const INFIMUM_FACTOR = 12;
const SUPREMUM_FACTOR = 10 * INFIMUM_FACTOR;

const formatStartDate = (month: string, year: string, variation: number): string => {
  return format(addMonths(new Date(`${parseInt(year) - variation - 1}-${month}-01`), 1), "dd.MM.yyyy");
};

const formatEndDate = (month: string, year: string, variation: number): string => {
  return format(subDays(addMonths(new Date(`${parseInt(year) - variation}-${month}-01`), 1), 1), "dd.MM.yyyy");
};

const generateMonthOptions = (year: string): string[] => {
  const today = new Date();
  // if current year, update values of selectable months up to the current one, as
  // months in the future make no sense
  if (`${today.getFullYear()}` == year) {
    return Object.keys(Month).filter((_, month) => month <= today.getMonth());
    // conversely, only accept bills up to 18 months old
  } else {
    return Object.keys(Month).filter((_, month) => differenceInMonths(today, new Date(`${year}/${month + 1}/01`)) <= 18);
  }
};

const WarningIcon = styled(ReportProblemOutlinedIcon)(({ theme }) => ({
  "@keyframes bleep": {
    from: {
      backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 1.0)`,
    },
    to: {
      backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 0)`,
    },
  },
  animation: "bleep 1s ease-out",
  padding: 8,
  borderRadius: "100%",
}));

export default function HeatingScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [oldReadings, setOldReadings] = useState<boolean>(false);
  const [heating, setHeating] = useState<string>(consumptionCertificate.answers?.heatingSystemType || "");
  const [heatingError, setHeatingError] = useState<string>();
  const [installationYear, setInstallationYear] = useState<string>(
    consumptionCertificate.answers?.yearOfHeatingInstalation ? consumptionCertificate.answers?.yearOfHeatingInstalation.toString() : "",
  );
  const [installationYearError, setInstallationYearError] = useState<string>();
  const [readingYear, setReadingYear] = useState<string>(
    consumptionCertificate.answers?.yearOfBill ? consumptionCertificate.answers?.yearOfBill.toString() : "",
  );
  const [readingYearError, setReadingYearError] = useState<string>();
  const [monthOptions, setMonthOptions] = useState<string[]>(
    consumptionCertificate.answers?.yearOfBill ? generateMonthOptions(consumptionCertificate.answers?.yearOfBill.toString()) : [],
  );
  const [readingMonth, setReadingMonth] = useState<string>(consumptionCertificate.answers?.endMonthOfBill || "");
  const [readingMonthError, setReadingMonthError] = useState<string>();
  const [readingValue1, setReadingValue1] = useState<string>(
    consumptionCertificate.answers?.verbrauch1 ? consumptionCertificate.answers?.verbrauch1.toString() : "",
  );
  const [readingValue1Error, setReadingValue1Error] = useState<string>();
  const [readingValue2, setReadingValue2] = useState<string>(
    consumptionCertificate.answers?.verbrauch2 ? consumptionCertificate.answers?.verbrauch2.toString() : "",
  );
  const [readingValue2Error, setReadingValue2Error] = useState<string>();
  const [readingValue3, setReadingValue3] = useState<string>(
    consumptionCertificate.answers?.verbrauch3 ? consumptionCertificate.answers?.verbrauch3.toString() : "",
  );
  const [readingValue3Error, setReadingValue3Error] = useState<string>();
  const [secondaryHeating, setSecondaryHeating] = useState<string>(consumptionCertificate.answers?.secondaryHeatingSystemType || "");
  const [secondaryInstallationYear, setSecondaryInstallationYear] = useState<string>(
    consumptionCertificate.answers?.secondaryYearOfHeatingInstalation ? consumptionCertificate.answers?.secondaryYearOfHeatingInstalation.toString() : "",
  );
  const [secondaryInstallationYearError, setSecondaryInstallationYearError] = useState<string>();
  const [secondaryReadingValue1, setSecondaryReadingValue1] = useState<string>(
    consumptionCertificate.answers?.secondaryVerbrauch1 ? consumptionCertificate.answers?.secondaryVerbrauch1.toString() : "",
  );
  const [secondaryReadingValue1Error, setSecondaryReadingValue1Error] = useState<string>();
  const [secondaryReadingValue2, setSecondaryReadingValue2] = useState<string>(
    consumptionCertificate.answers?.secondaryVerbrauch2 ? consumptionCertificate.answers?.secondaryVerbrauch2.toString() : "",
  );
  const [secondaryReadingValue2Error, setSecondaryReadingValue2Error] = useState<string>();
  const [secondaryReadingValue3, setSecondaryReadingValue3] = useState<string>(
    consumptionCertificate.answers?.secondaryVerbrauch3 ? consumptionCertificate.answers?.secondaryVerbrauch3.toString() : "",
  );
  const [secondaryReadingValue3Error, setSecondaryReadingValue3Error] = useState<string>();

  const years: string[] = [];
  const presentDate = new Date();
  const presentYear = presentDate.getFullYear();
  // the newest bill end date should be maximally 18 months in the past
  const oldestBillYear = subMonths(presentDate, 18).getFullYear();
  for (let year = presentYear; year >= oldestBillYear; year--) {
    years.push(`${year}`);
  }

  const infimum = consumptionCertificate.answers?.area ? INFIMUM_FACTOR * +consumptionCertificate.answers?.area : undefined;
  const supremum = consumptionCertificate.answers?.area ? SUPREMUM_FACTOR * +consumptionCertificate.answers?.area : undefined;

  const validate = () => {
    if (!heating) {
      setHeatingError("Pflichtfeld");
      return false;
    } else {
      setHeatingError(undefined);
    }
    if (!installationYear) {
      setInstallationYearError("Pflichtfeld");
      return false;
    } else {
      setInstallationYearError(undefined);
    }
    if (!readingYear) {
      setReadingYearError("Pflichtfeld");
      return false;
    } else {
      setReadingYearError(undefined);
    }
    if (!readingMonth) {
      setReadingMonthError("Pflichtfeld");
      return false;
    } else {
      setReadingMonthError(undefined);
    }
    if (!readingValue1) {
      setReadingValue1Error("Pflichtfeld");
      return false;
    } else {
      setReadingValue1Error(undefined);
    }
    if (!readingValue2) {
      setReadingValue2Error("Pflichtfeld");
      return false;
    } else {
      setReadingValue2Error(undefined);
    }
    if (!readingValue3) {
      setReadingValue3Error("Pflichtfeld");
      return false;
    } else {
      setReadingValue3Error(undefined);
    }
    if (secondaryHeating) {
      if (!secondaryInstallationYear) {
        setSecondaryInstallationYearError("Pflichtfeld");
        return false;
      } else {
        setSecondaryInstallationYearError(undefined);
      }
      if (!secondaryReadingValue1) {
        setSecondaryReadingValue1Error("Pflichtfeld");
        return false;
      } else {
        setSecondaryReadingValue1Error(undefined);
      }
      if (!secondaryReadingValue2) {
        setSecondaryReadingValue2Error("Pflichtfeld");
        return false;
      } else {
        setSecondaryReadingValue2Error(undefined);
      }
      if (!secondaryReadingValue3) {
        setSecondaryReadingValue3Error("Pflichtfeld");
        return false;
      } else {
        setSecondaryReadingValue3Error(undefined);
      }
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
          heatingSystemType: heating,
          yearOfHeatingInstalation: +installationYear,
          yearOfBill: +readingYear,
          endMonthOfBill: readingMonth,
          verbrauch1: +readingValue1,
          verbrauch2: +readingValue2,
          verbrauch3: +readingValue3,
          secondaryHeatingSystemType: secondaryHeating,
          secondaryYearOfHeatingInstalation: +secondaryInstallationYear,
          secondaryVerbrauch1: +secondaryReadingValue1,
          secondaryVerbrauch2: +secondaryReadingValue2,
          secondaryVerbrauch3: +secondaryReadingValue3,
        },
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Heizsysteme</strong>
          </Typography>
        </Grid>
        <Grid size={8}>
          <NovoSelect
            dataCy={{ field: "heating-type-select", values: Object.values(HeatingSystem) }}
            label='Heizsystem'
            options={[...Object.values(HeatingSystem)]}
            value={heating}
            onUpdate={(value) => {
              setHeating(value);
              setHeatingError(undefined);
            }}
            error={!!heatingError}
            helperText={heatingError}
            required={true}
          />
        </Grid>
        <Grid size={4}>
          <FormControl fullWidth>
            <NovoTextField
              data-cy='installation-year-input'
              variant='outlined'
              id='jahr-installation-input'
              value={installationYear}
              type='number'
              label='Jahr der Installation'
              onChange={(event) => {
                setInstallationYear(event.target.value);
                setInstallationYearError(undefined);
              }}
              error={!!installationYearError}
              helperText={installationYearError}
              required={true}
              slotProps={{
                htmlInput: { min: 1900 },
              }}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Typography variant='body1' gutterBottom>
            Bitte wählen Sie für das Zertifikat den Endmonat und das Jahr der drei zusammenhängenden Verbrauchsjahre aus. Starten Sie mit der jüngsten
            Abrechnung.
          </Typography>
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={
              <Checkbox
                required={false}
                data-cy='old-readings-empty-checkbox'
                name='old-readings-empty-checkbox'
                checked={oldReadings}
                onChange={(_, checked) => setOldReadings(checked)}
              />
            }
            label='Meine letzte Abrechnung liegt mehr als 18 Monate zurück.'
          />
        </Grid>
        {oldReadings ? (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              description='Das Ende der Abrechnungsperiode darf nicht mehr als 18 Monate zurück liegen, sonst benötigen Sie einen Bedarfsausweis.'
              title='Neuen Bedarfsausweis beantragen'
              message={`Hallo NOVO-Team,

da das Ender meiner letzen Abrechung mehr als 18 Monate zurückliegt, möchte ich einen Bedarfsausweis beantragen und ein unverbindliches Angebot einholen.`}
              consent={true}
            />
          </Grid>
        ) : (
          <>
            <Grid size={6}>
              <NovoSelect
                dataCy={{ field: "year-select", values: years }}
                label='Jahr'
                options={years}
                value={readingYear}
                onUpdate={(value) => {
                  setReadingYear(value);
                  setReadingYearError(undefined);
                  setReadingMonth("");
                  setReadingMonthError(undefined);
                  setMonthOptions(generateMonthOptions(value));
                }}
                error={!!readingYearError}
                helperText={readingYearError}
                required={true}
              />
            </Grid>
            <Grid size={6}>
              <NovoSelect
                data-cy='month-select'
                label='Monat'
                defaultValue=''
                value={getMonthKey(readingMonth) || ""}
                options={monthOptions}
                onUpdate={(value) => {
                  setReadingMonth(getMonth(value) || "");
                  setReadingMonthError(undefined);
                }}
                error={!!readingMonthError}
                helperText={readingMonthError}
                required={true}
              />
            </Grid>
            <Grid size={6}>
              {readingMonth && readingYear && (
                <Typography variant='body1'>
                  von {formatStartDate(readingMonth, readingYear, 0)} bis {formatEndDate(readingMonth, readingYear, 0)}:
                </Typography>
              )}
            </Grid>
            <Grid size={6}>
              <Stack direction='row' alignItems={"center"}>
                <FormControl fullWidth>
                  <NovoTextField
                    data-cy='consumption-1-input'
                    variant='outlined'
                    id='verbrauch-1-input'
                    type='number'
                    label='Verbrauch'
                    value={readingValue1}
                    onChange={(event) => {
                      setReadingValue1(event.target.value);
                      setReadingValue1Error(undefined);
                    }}
                    error={!!readingValue1Error}
                    helperText={readingValue1Error}
                    required={true}
                    slotProps={{
                      htmlInput: { min: 100 },
                    }}
                  />
                </FormControl>
                {!!infimum && !!readingValue1 && +readingValue1 < infimum && (
                  <Tooltip title='Dieser Zählerstand scheint zu niedrig zu sein.' placement='right' arrow>
                    <WarningIcon fontSize='large' />
                  </Tooltip>
                )}
                {!!supremum && !!readingValue1 && +readingValue1 > supremum && (
                  <Tooltip title='Dieser Zählerstand scheint zu hoch zu sein.' placement='right' arrow>
                    <WarningIcon fontSize='large' />
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid size={6}>
              {readingMonth && readingYear && (
                <Typography variant='body1'>
                  von {formatStartDate(readingMonth, readingYear, 1)} bis {formatEndDate(readingMonth, readingYear, 1)}:
                </Typography>
              )}
            </Grid>
            <Grid size={6}>
              <Stack direction='row' alignItems={"center"}>
                <FormControl fullWidth>
                  <NovoTextField
                    data-cy='consumption-2-input'
                    variant='outlined'
                    id='verbrauch-2-input'
                    type='number'
                    label='Verbrauch'
                    value={readingValue2}
                    onChange={(event) => {
                      setReadingValue2(event.target.value);
                      setReadingValue2Error(undefined);
                    }}
                    error={!!readingValue2Error}
                    helperText={readingValue2Error}
                    slotProps={{
                      htmlInput: { min: 100 },
                    }}
                  />
                </FormControl>
                {!!infimum && !!readingValue2 && +readingValue2 < infimum && (
                  <Tooltip title='Dieser Zählerstand scheint zu niedrig zu sein.' placement='right' arrow>
                    <WarningIcon fontSize='large' />
                  </Tooltip>
                )}
                {!!supremum && !!readingValue2 && +readingValue2 > supremum && (
                  <Tooltip title='Dieser Zählerstand scheint zu hoch zu sein.' placement='right' arrow>
                    <WarningIcon fontSize='large' />
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid size={6}>
              {readingMonth && readingYear && (
                <Typography variant='body1'>
                  von {formatStartDate(readingMonth, readingYear, 2)} bis {formatEndDate(readingMonth, readingYear, 2)}:
                </Typography>
              )}
            </Grid>
            <Grid size={6}>
              <Stack direction='row' alignItems={"center"}>
                <FormControl fullWidth>
                  <NovoTextField
                    data-cy='consumption-3-input'
                    variant='outlined'
                    id='verbrauch-3-input'
                    type='number'
                    label='Verbrauch'
                    value={readingValue3}
                    onChange={(event) => {
                      setReadingValue3(event.target.value);
                      setReadingValue3Error(undefined);
                    }}
                    error={!!readingValue3Error}
                    helperText={readingValue3Error}
                    slotProps={{
                      htmlInput: { min: 100 },
                    }}
                  />
                </FormControl>
                {!!infimum && !!readingValue3 && +readingValue3 < infimum && (
                  <Tooltip title='Dieser Zählerstand scheint zu niedrig zu sein.' placement='right' arrow>
                    <WarningIcon fontSize='large' />
                  </Tooltip>
                )}
                {!!supremum && !!readingValue3 && +readingValue3 > supremum && (
                  <Tooltip title='Dieser Zählerstand scheint zu hoch zu sein.' placement='right' arrow>
                    <WarningIcon fontSize='large' />
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid size={12}>
              <NovoSelect
                label='Zweites Heizsystem (optional)'
                options={["", ...Object.values(HeatingSystem)]}
                value={secondaryHeating}
                onUpdate={(value) => {
                  setSecondaryHeating(value);
                }}
                required={false}
              />
            </Grid>
            {secondaryHeating && (
              <>
                <Grid size={12}>
                  <FormControl fullWidth>
                    <NovoTextField
                      variant='outlined'
                      id='jahr-installation-input-zweites-heizsystem'
                      type='number'
                      label='Jahr der Installation (Zweites Heizsystem)'
                      value={secondaryInstallationYear}
                      onChange={(event) => {
                        setSecondaryInstallationYear(event.target.value);
                        setSecondaryInstallationYearError(undefined);
                      }}
                      error={!!secondaryInstallationYearError}
                      helperText={secondaryInstallationYearError}
                      required={true}
                      slotProps={{
                        htmlInput: { min: 1900 },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  {readingMonth && readingYear && (
                    <Typography variant='body1'>
                      von {formatStartDate(readingMonth, readingYear, 0)} bis {formatEndDate(readingMonth, readingYear, 0)}:
                    </Typography>
                  )}
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth>
                    <NovoTextField
                      variant='outlined'
                      id='verbrauch-1-input-zweites-heizsystem'
                      type='number'
                      label='Verbrauch (Zweites Heizsystem)'
                      value={secondaryReadingValue1}
                      onChange={(event) => {
                        setSecondaryReadingValue1(event.target.value);
                        setSecondaryReadingValue1Error(undefined);
                      }}
                      error={!!secondaryReadingValue1Error}
                      helperText={secondaryReadingValue1Error}
                      required={true}
                      slotProps={{
                        htmlInput: { min: 100 },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  {readingMonth && readingYear && (
                    <Typography variant='body1'>
                      von {formatStartDate(readingMonth, readingYear, 1)} bis {formatEndDate(readingMonth, readingYear, 1)}:
                    </Typography>
                  )}
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth>
                    <NovoTextField
                      variant='outlined'
                      id='verbrauch-2-input-zweites-heizsystem'
                      type='number'
                      label='Verbrauch (Zweites Heizsystem)'
                      value={secondaryReadingValue2}
                      onChange={(event) => {
                        setSecondaryReadingValue2(event.target.value);
                        setSecondaryReadingValue2Error(undefined);
                      }}
                      error={!!secondaryReadingValue2Error}
                      helperText={secondaryReadingValue2Error}
                      required={true}
                      slotProps={{
                        htmlInput: { min: 100 },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid size={6}>
                  {readingMonth && readingYear && (
                    <Typography variant='body1'>
                      von {formatStartDate(readingMonth, readingYear, 2)} bis {formatEndDate(readingMonth, readingYear, 2)}:
                    </Typography>
                  )}
                </Grid>
                <Grid size={6}>
                  <FormControl fullWidth>
                    <NovoTextField
                      variant='outlined'
                      id='verbrauch-3-input-zweites-heizsystem'
                      type='number'
                      label='Verbrauch (Zweites Heizsystem)'
                      value={secondaryReadingValue3}
                      onChange={(event) => {
                        setSecondaryReadingValue3(event.target.value);
                        setSecondaryReadingValue3Error(undefined);
                      }}
                      error={!!secondaryReadingValue3Error}
                      helperText={secondaryReadingValue3Error}
                      required={true}
                      slotProps={{
                        htmlInput: { min: 100 },
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={validateAndSave} progressLabel='5 von 10' progressValue={50} isUpdateRunning={isUpdateRunning} content={content} />;
}
