import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ProgressBar from "../../ProgressBar";

export default function FinalScreen() {
  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        pb: 8,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={"Fertig!"} value={100} />
      </Grid>
      <Grid size={12}>
        <Stack mx={5}>
          <Typography variant='h4' data-cy='final-screen-thanks-title' textAlign='center' fontSize={{ xs: 20, lg: 34 }}>
            Vielen Dank!
          </Typography>
          <Typography mt={8} fontSize={{ xs: 14, lg: 16 }}>
            Sie sind fertig und wir verarbeiten jetzt Ihre Antworten.
          </Typography>
          <Typography mt={2} fontSize={{ xs: 14, lg: 16 }}>
            Wir melden uns in Kürze bei Ihnen zur Vereinbarung eines kurzen Vor-Ort-Termins (soweit nicht bereits geschehen). Dieser Termin ist notwendig für
            die Ausstellung Ihres iSFP.
          </Typography>
          <Typography mt={2} fontSize={{ xs: 14, lg: 16 }}>
            Wir freuen uns darauf Ihnen zu helfen, Ihr Zuhause energieeffizienter zu machen.
          </Typography>
          <Typography mt={2} fontSize={{ xs: 14, lg: 16 }}>
            Sie können jetzt diese Seite verlassen.
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
