import { TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDate } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { ConsultantWithMetrics } from "../../../types/cockpit/types";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

type Order = "asc" | "desc";
type OrderBy = keyof ConsultantWithMetrics;

export interface ConsultantTableProps {
  isLoading: boolean;
  error?: string | string[];
  consultants?: ConsultantWithMetrics[];
}

export function ConsultantTable({ isLoading, error, consultants }: ConsultantTableProps) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>("name");
  const [sortedRows, setSortedRows] = useState<ConsultantWithMetrics[]>([]);

  useEffect(() => {
    if (!consultants) return;
    const sorted = [...consultants.sort(consultantSorter(orderBy, order))];
    setSortedRows(sorted);
  }, [consultants, order, orderBy]);

  if (consultants && consultants?.length < pageSize * page) {
    setPage(0);
    return null;
  }
  const consultantSorter = (property: keyof ConsultantWithMetrics, order: Order) => {
    return (a: ConsultantWithMetrics, b: ConsultantWithMetrics) => {
      const inverter = order === "asc" ? 1 : -1;
      const left = a[property];
      const right = b[property];
      if (!left || !right) return 0;
      if (left < right) {
        return -1 * inverter;
      } else if (left > right) {
        return 1 * inverter;
      } else {
        return 0;
      }
    };
  };

  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(newOrder);
    }
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = Number.parseInt(event.target.value);
    setPageSize(newPageSize);
    setPage(0);
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement>, page: number) => {
    setPage(page);
  };

  if (isLoading || !consultants) {
    return <LoadingData />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }
  if (consultants?.length === 0) {
    return <EmptyTable />;
  }
  return (
    <Table aria-label='collapsible table' data-cy='consultants-list' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={orderBy === "email"}
              direction={order}
              onClick={() => {
                handleOrderChange("email");
              }}
            >
              Email
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "name"}
              direction={order}
              onClick={() => {
                handleOrderChange("name");
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "leadCount"}
              direction={order}
              onClick={() => {
                handleOrderChange("leadCount");
              }}
            >
              Gesamtleads
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "invites"}
              direction={order}
              onClick={() => {
                handleOrderChange("invites");
              }}
            >
              Gesendete Einladungen
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "invitesAccepted"}
              direction={order}
              onClick={() => {
                handleOrderChange("invitesAccepted");
              }}
            >
              Akzeptierte Einladungen
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "phoneNumber"}
              direction={order}
              onClick={() => {
                handleOrderChange("phoneNumber");
              }}
            >
              Telefonnummer
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "lastActivity"}
              direction={order}
              onClick={() => {
                handleOrderChange("lastActivity");
              }}
            >
              Zuletzt Aktiv
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "registeredOn"}
              direction={order}
              onClick={() => {
                handleOrderChange("registeredOn");
              }}
            >
              Registriert am
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{sortedRows?.slice(page * pageSize, (page + 1) * pageSize).map((row) => <Row key={row.email} row={row} />)}</TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={handlePageSizeChange}
            component='td'
            count={consultants?.length || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handlePageChange}
            colSpan={5}
            labelRowsPerPage='Einträge pro Seite'
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

function LoadingData() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      </Stack>
    </Box>
  );
}

function LoadingError({ error }: { error: string | string[] }) {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>{error}</Typography>
    </Box>
  );
}

function EmptyTable() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>Keine Ergebnisse gefunden</Typography>
    </Box>
  );
}

function Row({ row }: { row: ConsultantWithMetrics }) {
  if (row.name) {
    return <ConsultantRow key={row.email} row={row} />;
  }
  return <InvitationRow key={row.email} row={row} />;
}

function InvitationRow({ row }: { row: ConsultantWithMetrics }) {
  return (
    <Fragment>
      <TableRow
        sx={{
          height: 75,
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
      >
        <TableCell>{row.email}</TableCell>
        <TableCell colSpan={7}>
          <Stack direction='row' spacing={2} sx={{ justifyContent: "center" }}>
            <EmailIcon />
            <Typography>Eingeladen</Typography>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}

function ConsultantRow({ row }: { row: ConsultantWithMetrics }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <TableRow
        onClick={() => {
          navigate(`/cockpit/leads?filter=${encodeURIComponent(row.email)}`);
        }}
        sx={{
          cursor: "pointer",
          height: 75,
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
      >
        <TableCell component='th' scope='row'>
          {row.email}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.leadCount}</TableCell>
        <TableCell>{row.invites}</TableCell>
        <TableCell>{row.invitesAccepted}</TableCell>
        <TableCell>{row.phoneNumber}</TableCell>
        <TableCell>{row.lastActivity ? formatDate(row.lastActivity, "dd.MM.yyyy") : "-"}</TableCell>
        <TableCell>{row.registeredOn ? formatDate(row.registeredOn, "dd.MM.yyyy") : "-"}</TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}
