import Grid from "@mui/material/Grid2";
import { Checkbox, FormControl, FormControlLabel, Stack, Typography } from "@mui/material";
import { ConsumptionCertificateScreenProps } from "./ConsumptionCertificateFunnel";
import FormScreen from "../FormScreen";
import NovoSelect from "../../../components/common/NovoSelect";
import { ContactRequestRequired } from "./ContactRequestRequired";
import { Fragment, useState } from "react";
import NovoTextField from "../../../components/common/NovoTextField";
import { BuildingType, Reforms } from "./ConsumptionCertificateRequestEnums";
import { UpsertConsumptionCertificate } from "./types";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import HelpIcon from "@mui/icons-material/Help";

export default function TriageScreen({ consumptionCertificate, setConsumptionCertificate, isUpdateRunning, prev, next }: ConsumptionCertificateScreenProps) {
  const [readingsAbsence, setReadingsAbsence] = useState<boolean>(false);
  const [yearOfConstruction, setYearOfConstruction] = useState<string>(
    consumptionCertificate.answers?.yearOfConstruction ? consumptionCertificate.answers?.yearOfConstruction.toString() : "",
  );
  const [yearOfConstructionError, setYearOfConstructionError] = useState<string>();
  const [reform, setReform] = useState<string>("");
  const [reformError, setReformError] = useState<string>();
  const [buildingType, setBuildingType] = useState<string>(consumptionCertificate.answers?.typeOfBuilding ?? "");
  const [buildingTypeError, setBuildingTypeError] = useState<string>();
  const [units, setUnits] = useState<string>(
    consumptionCertificate.answers?.numberOfApartments ? consumptionCertificate.answers?.numberOfApartments.toString() : "",
  );
  const [unitsError, setUnitsError] = useState<string>();
  const [hasHeatedBasement, setHasHeatedBasement] = useState<boolean>(!!consumptionCertificate.answers?.hasHeatedBasement);
  const maxAllowedConstructionYear = new Date().getFullYear() - 3;

  const isYearFilled: boolean = !!yearOfConstruction && yearOfConstruction.length > 3;
  const recentBuilding: boolean = +yearOfConstruction > maxAllowedConstructionYear;
  const isOldAndUnreformed: boolean =
    isYearFilled && +yearOfConstruction < 1977 && !!reform && reform !== Reforms["Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten"];
  const isForMixedUses: boolean = buildingType === BuildingType["Wohnteil gemischt genutztes Gebäude"];
  const isForOtherUses: boolean = buildingType === BuildingType["Sonstiges"];
  const hasMoreThanFourUnits: boolean = +units > 4;
  const notEligible: boolean = readingsAbsence || recentBuilding || isOldAndUnreformed || isForMixedUses || isForOtherUses || hasMoreThanFourUnits;

  const validate = () => {
    if (!yearOfConstruction) {
      setYearOfConstructionError("Pflichtfeld");
      return false;
    } else {
      setYearOfConstructionError(undefined);
    }
    if (+yearOfConstruction < 1977 && !reform) {
      setReformError("Pflichtfeld");
      return false;
    } else {
      setReformError(undefined);
    }
    if (!buildingType) {
      setBuildingTypeError("Pflichtfeld");
      return false;
    } else {
      setBuildingTypeError(undefined);
    }
    if (!units) {
      setUnitsError("Pflichtfeld");
      return false;
    } else {
      setUnitsError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (!next) {
      return;
    }
    if (validate()) {
      const newState: UpsertConsumptionCertificate = {
        ...consumptionCertificate,
        answers: {
          ...consumptionCertificate.answers,
          yearOfConstruction: +yearOfConstruction,
          typeOfBuilding: buildingType,
          numberOfApartments: +units,
          hasHeatedBasement,
        },
      };
      setConsumptionCertificate(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Welcher Energieausweis passt zu Ihnen?</strong>
          </Typography>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            Je nach Gebäudeart benötigen Sie entweder einen Energieverbrauchsausweis oder einen Energiebedarfsausweis..
          </Typography>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            Beantworten Sie die folgenden Fragen, um herauszufinden, welcher für Ihr Gebäude erforderlich ist
          </Typography>
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={
              <Checkbox
                required={false}
                data-cy='long-period-empty-checkbox'
                name='long-period-empty-checkbox'
                checked={readingsAbsence}
                onChange={(_, checked) => setReadingsAbsence(checked)}
              />
            }
            label='Während der drei aufeinanderfolgenden Verbrauchsjahre für das Zertifikat war die Immobilie zu über 30% leerstehend'
          />
        </Grid>
        {!readingsAbsence && (
          <Grid size={4}>
            <NovoTextField
              fullWidth
              variant='outlined'
              id='build-year-input'
              data-cy='build-year-input'
              value={yearOfConstruction}
              type='number'
              label='Baujahr'
              onChange={(event) => {
                setYearOfConstruction(event.target.value);
                setYearOfConstructionError(undefined);
                setReform("");
                setReformError(undefined);
              }}
              required={true}
              error={!!yearOfConstructionError}
              helperText={yearOfConstructionError}
              slotProps={{
                inputLabel: { shrink: !!yearOfConstruction },
              }}
            />
          </Grid>
        )}
        {!readingsAbsence && (!isYearFilled || +yearOfConstruction >= 1977) && <Grid size={8} />}
        {!readingsAbsence && isYearFilled && +yearOfConstruction < 1977 && (
          <Grid size={8}>
            <Stack direction='row' alignItems='center' spacing={2}>
              <NovoSelect
                id='reforms-type-select'
                data-cy='reforms-type-select'
                label='Wärmeschutzverordnung'
                options={[...Object.values(Reforms)]}
                value={reform}
                onUpdate={(value) => {
                  setReform(value);
                  setReformError(undefined);
                }}
                error={!!reformError}
                helperText={reformError}
                required={true}
              />
              <NovoTooltip
                title={
                  <Fragment>
                    <Typography variant='subtitle1' style={{ fontWeight: 400 }}>
                      Die Anforderung gilt als erfüllt (alle Punkte müssen erfüllt werden), wenn:
                    </Typography>
                    <Typography variant='body2'>
                      <ol>
                        <li>
                          für den Großteil der Außenwände eine der folgenden Konstruktionen vorhanden ist:
                          <ul>
                            <li>24cm Bimsstein-Mauerwerk, Porenbeton oder Blocksteine ODER</li>
                            <li>38cm Kalksanstein-Mauerwerk mit mind. 6cm äußerer Dämmung ODER</li>
                            <li>Zweischaliges Mauerwerk mit 8cm Dämmung</li>
                          </ul>
                        </li>
                        <li>Ihre Fenster Holz- oder Kunststofffenster mit Doppel- oder Isolierverglasung sind</li>
                        <li>das Dach gedämmt ist (mind. 18cm Aufsparren-, 15cm Zwischensparrendämmung oder 8cm Dachbodendämmung)</li>
                        <li>
                          bei einem unbeheizten Keller 5,5cm Kellerdeckendämmung vorhanden sind (bei beheizten Kellern oder keinem Keller ist keine Dämmung
                          notwendig).
                        </li>
                      </ol>
                      Sind alle Kategorien erfüllt, können Sie „Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten“ auswählen. Wir fragen nach den
                      Informationen, um Sie in die richtige Kategorie des Verbrauchs- oder Bedarfsausweises einordnen zu können.
                    </Typography>
                  </Fragment>
                }
              >
                <HelpIcon />
              </NovoTooltip>
            </Stack>
          </Grid>
        )}
        {!readingsAbsence && isYearFilled && !recentBuilding && !isOldAndUnreformed && (
          <>
            <Grid size={6}>
              <NovoSelect
                id='construction-type-select'
                dataCy={{ field: "construction-type-select", values: Object.values(BuildingType) }}
                label='Gebäudetyp'
                options={[...Object.values(BuildingType)]}
                value={buildingType}
                onUpdate={(value) => {
                  setBuildingType(value);
                  setBuildingTypeError(undefined);
                }}
                error={!!buildingTypeError}
                helperText={buildingTypeError}
                required={true}
              />
            </Grid>
            <Grid size={6}>
              <FormControl fullWidth>
                <NovoTextField
                  fullWidth
                  variant='outlined'
                  id='house-units-input'
                  data-cy='house-units-input'
                  label='Anzahl Wohnungen'
                  required={true}
                  type='number'
                  error={!!unitsError}
                  helperText={unitsError}
                  slotProps={{
                    htmlInput: { min: 1, max: 20 },
                  }}
                  value={units}
                  onChange={(event) => {
                    setUnits(event.target.value);
                    setUnitsError(undefined);
                  }}
                />
              </FormControl>
            </Grid>
            {[1, 2].includes(+units) && (
              <Grid size={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      required={false}
                      data-cy='has-heated-basement-checkbox'
                      name='has-heated-basement-checkbox'
                      checked={hasHeatedBasement}
                      onChange={(_, checked) => setHasHeatedBasement(checked)}
                    />
                  }
                  label='Das Gebäude verfügt über einen beheizten Keller'
                />
              </Grid>
            )}
          </>
        )}
        {readingsAbsence && (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              title='Neuen Bedarfsausweis beantragen'
              message={
                "Hallo NOVO-Team,\r\n\r\nda meine Immobilie seit einiger Zeit überwiegend leer steht, möchte ich einen Bedarfsausweis beantragen und ein unverbindliches Angebot einholen."
              }
              consent={true}
            />
          </Grid>
        )}
        {!readingsAbsence && recentBuilding && (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              description={`Verbrauchsausweise können nur für Gebäude erstellt werden, die älter als ${maxAllowedConstructionYear} sind. Kontaktieren Sie uns per E-Mail und wir unterstützen Sie bei den nächsten Schritten.`}
              title='Neuen Bedarfsausweis beantragen'
              message={
                "Hallo NOVO-Team,\r\n\r\nda mir nicht genügend Verbrauchsdaten vorliegen, möchte ich einen Bedarfsausweis anfordern und ein unverbindliches Angebot einholen."
              }
            />
          </Grid>
        )}
        {!readingsAbsence && !recentBuilding && isOldAndUnreformed && (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              title='Neuen Bedarfsausweis beantragen'
              message={
                "Hallo Team NOVO,\r\n\r\nda meine Immobilie die Anforderungen der Wärmeschutzverordnung von 1977 wahrscheinlich nicht erfüllt, möchte ich einen Bedarfsausweis anfragen und bitte um ein unverbindliches Angebot."
              }
              consent={true}
            />
          </Grid>
        )}
        {!readingsAbsence && !recentBuilding && !isOldAndUnreformed && isForMixedUses && (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              description='Benötigen Sie einen Verbrauchsausweis für den Wohnteil eines gemischt genutzten Gebäudes? Dann schreiben Sie uns gerne eine E-Mail, und wir melden uns bei Ihnen zurück!'
              title='Neuen Verbrauchsausweis beantragen'
              message={
                "Hallo Team NOVO,\r\n\r\nIch benötige einen Verbrauchsausweis für den Wohnteil eines gemischt genutzten Gebäudes. Bitte senden Sie mir ein kostenloses Angebot."
              }
              consent={true}
            />
          </Grid>
        )}
        {!readingsAbsence && !recentBuilding && !isOldAndUnreformed && isForOtherUses && (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              description='Benötigen Sie einen Verbrauchsausweis für ein Gebäude, das nicht in die oben genannten Kategorien passt? Dann schreiben Sie uns gerne eine E-Mail, und wir melden uns bei Ihnen zurück!'
              title='Neuen Verbrauchsausweis beantragen'
              message={
                "Hallo Team NOVO,\r\n\r\nIch benötige einen Verbrauchsausweis für ein Gebäude, welches nicht in die Gebäudetypen-Kategorien passt. Ich möchte ein unverbindliches Angebot anfordern."
              }
              consent={true}
            />
          </Grid>
        )}
        {!readingsAbsence && !recentBuilding && !isOldAndUnreformed && !isForMixedUses && !isForOtherUses && hasMoreThanFourUnits && (
          <Grid size={12}>
            <ContactRequestRequired
              name={consumptionCertificate.leadName}
              email={consumptionCertificate.leadEmail}
              phone={consumptionCertificate.leadTelephone}
              description='Sie benötigen einen Verbauchsausweis für ein Gebäude mit mehr als 4 Wohneinheiten? Dann schreiben Sie uns gerne eine Email und wir melden uns bei Ihnen zurück! '
              title='Neuen Bedarfsausweis beantragen'
              message={
                "Hallo Team NOVO,\r\n\r\nda meine Immobilie mehr als 4 Wohnungen hat, möchte ich einen Bedarfsausweis anfragen und bitte um ein unverbindliches Angebot."
              }
              consent={true}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      hidePrev={notEligible}
      next={validateAndSave}
      hideNext={notEligible}
      isUpdateRunning={isUpdateRunning}
      progressLabel='2 von 10'
      progressValue={20}
      content={content}
    />
  );
}
