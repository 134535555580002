import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export type ConsumptionCertBuildingImage = {
  url?: string;
};

export function useFetchConsumptionCertBuildingImage() {
  const { authAxios, error, running, data } = useAuthAxios<ConsumptionCertBuildingImage>();

  const fetchBuildingImage = useCallback(
    async (id: string) => {
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${id}/building-photo`,
      });
    },
    [authAxios],
  );

  return { fetchBuildingImage, error, isLoading: running, buildingImage: data };
}
