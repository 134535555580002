import { useCallback, useContext, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { AuthContext } from "../Contexts";
import { retrieveRum } from "../AwsRum";
import { isSessionValid } from "./useAuth";
import { RequestError } from "../types/types";

export const useAuthAxios = <ReturnType>() => {
  const [responseStatus, setStatus] = useState<number>();
  const [running, setRunning] = useState<boolean>(false);
  const [error, setError] = useState<RequestError>();
  const [data, setData] = useState<ReturnType>();
  const { session, refreshSession } = useContext(AuthContext);

  const authAxios = useCallback(
    async (req: AxiosRequestConfig) => {
      setError(undefined);
      setData(undefined);
      setRunning(true);

      if (!isSessionValid(session)) {
        await refreshSession();
      }
      try {
        const { data, status } = await axios.request<ReturnType>({
          ...req,
          headers: {
            Authorization: `Bearer ${session?.getIdToken().getJwtToken()}`,
            ...req.headers,
          },
        });
        setStatus(status);
        setData(data);
        return data;
      } catch (error) {
        retrieveRum()?.recordError(error);
        const err = error as Error | AxiosError;
        let errorMsg;
        if (axios.isAxiosError(err)) {
          setStatus(err.response?.status);
          errorMsg = err.response?.data.message;
        }
        setError({
          ...error,
          errorMsg,
        });
        setData(undefined);
        return undefined;
      } finally {
        setRunning(false);
      }
    },
    [session, refreshSession],
  );

  const resetState = () => {
    setStatus(undefined);
    setRunning(false);
    setError(undefined);
    setData(undefined);
  };

  return { authAxios, data, responseStatus, error, running, resetState };
};
