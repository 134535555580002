import { useCallback } from "react";
import { ConsumptionCertificate } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export function useFetchConsumptionCertificate() {
  const { authAxios, data: consumptionCert, running: isLoading } = useAuthAxios<ConsumptionCertificate>();

  const fetchData = useCallback(
    async (id: string) => {
      await authAxios({
        method: "GET",
        baseURL: `${apiEndpoint()}/consumption-certificate/full/${id}`,
      });
    },
    [authAxios],
  );

  return {
    fetchData,
    consumptionCert,
    isLoading,
  };
}

export function useFetchConsumptionCertificates() {
  const { authAxios, data: consumptionCerts, running: isLoading, error } = useAuthAxios<ConsumptionCertificate[]>();

  const fetchData = useCallback(
    async (args?: { page?: number; pageSize?: number; search?: string; state?: string }) => {
      await authAxios({
        method: "GET",
        baseURL: `${apiEndpoint()}/consumption-certificates/`,
        params: {
          ...(args && Object.keys(args).length > 0 ? { ...args } : {}),
        },
      });
    },
    [authAxios],
  );

  return {
    consumptionCerts,
    isLoading,
    error,
    fetchData,
  };
}
