import { Box, BoxProps } from "@mui/material";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { stripeKey, apiEndpoint } from "../../../utils/params";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import Loading from "../Loading";

export type StripeCallback = {
  returnURI: string;
  params?: {
    [key: string]: string;
  };
};

export interface StripeEmbeddedCheckoutProps extends BoxProps {
  orderId: string;
  stripeCallback?: StripeCallback;
  onComplete?: () => void;
}

const stripePromise = loadStripe(stripeKey());

/**
 * This component supports both a fully embedded flow and a redirect flow.
 *
 * If you wish to redirect to a success page, pass a `StripeCallback` with the destination URI and any
 * query parameters you wish to pass to the destination URI
 *
 * If you wish to stay on the same page, pass a `onComplete` handler that is invoked after the payment
 * is sucessfully processed.
 *
 * @param orderId The order ID to be used for the payment
 * @param stripeCallback The configuration object for the Stripe redirect flow
 * @param onComplete The callback to be called when the payment is completed when doing an embedded flow
 * @param props The MUI props to be passed to the component
 * @returns
 */
export default function StripeEmbeddedCheckout({ orderId, stripeCallback, onComplete, ...props }: StripeEmbeddedCheckoutProps) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Init stripe session
    fetch(`${apiEndpoint()}/orders/${orderId}/payments/stripe`, {
      method: "POST",
      body: JSON.stringify({ ...stripeCallback }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.stripeClientSecret));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = { clientSecret, onComplete };

  return (
    <Box id='checkout' data-cy='stripe-checkout' {...props}>
      {!clientSecret && <Loading />}
      {!!clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Box>
  );
}
