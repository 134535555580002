import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import NovoFileUpload, { FileRef } from "../../../../components/common/NovoFileUpload";
import { useFinalizeIsfpProcess } from "../../../../hooks/useFinalizeIsfpProcess";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { RowDetailProps } from "./types";

export default function IsfpInProgress({ row, onChange }: RowDetailProps) {
  const [isFinalizeModalOpen, setFinalizeModalOpen] = useState(false);

  function openISFPFinalizeModal() {
    setFinalizeModalOpen(true);
  }

  const toggleFinalizeModal = () => {
    setFinalizeModalOpen(!isFinalizeModalOpen);
  };

  return (
    <Stack direction='row' alignItems='baseline' spacing={2} flexWrap='wrap'>
      {row.status == IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED && (
        <>
          <Typography>iSFP Dokument in Bearbeitung.</Typography>
          <ButtonWithSpinner
            data-cy={`manage-offer-accepted-bnt-${row.leadEmail}`}
            variant={"contained"}
            label='iSFP Prozess abschließen'
            onClick={openISFPFinalizeModal}
          />
          <FinalizeIsfpProcessDialog open={isFinalizeModalOpen} onClose={toggleFinalizeModal} row={row} onChange={onChange} />
        </>
      )}
    </Stack>
  );
}

type FinalizeIsfpProcessDialogProps = {
  open: boolean;
  onClose: () => void;
} & RowDetailProps;

const FinalizeIsfpProcessDialog = ({ row, open, onClose, onChange }: FinalizeIsfpProcessDialogProps) => {
  const { finalizeIsfpProcess, resetState, updatedIsfpProcess, isLoading, finalizeError } = useFinalizeIsfpProcess();
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (updatedIsfpProcess && onChange) {
      onChange(updatedIsfpProcess);
      onGoingToClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedIsfpProcess]);

  useEffect(() => {
    if (finalizeError) {
      setError("Es ist leider ein Fehler aufgetreten");
    } else {
      setError(undefined);
    }
  }, [finalizeError]);

  function handleFinalize(): void {
    if (!file) {
      setFileError(true);
      return;
    }
    finalizeIsfpProcess(file, row.id);
  }

  function handleFileChange(value: FileRef[]): void {
    if (value.length == 1) {
      setFileError(false);
      setFile(value[0].file);
    }
  }

  function onGoingToClose(): void {
    setFile(undefined);
    resetState();
    onClose();
  }
  return (
    <Dialog open={open} onClose={onGoingToClose} maxWidth={"xl"} data-cy='finalize-isfp-modal'>
      <DialogTitle>
        iSFP Prozess abschließen
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={onGoingToClose}
          data-cy='invite-lead-cancel-btn'
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Stack display={"inline"}>
          <Typography pb={2}>Beim Hochladen der iSPF-Datei wird der Prozess abgeschlossen</Typography>
          <Grid2 minWidth={800}>
            <NovoFileUpload maxFiles={1} error={fileError} required label='iSFP Dokument' direction='row' onChange={handleFileChange} />
            {fileError && (
              <Typography color='error.main' variant='caption'>
                Bitte wähle eine iSFP-Datei aus
              </Typography>
            )}
          </Grid2>
          {error && (
            <Alert data-cy='finalize-error' sx={{ marginTop: 2 }} severity='error'>
              {error}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={onGoingToClose} disabled={isLoading}>
          Abbrechen
        </Button>
        <ButtonWithSpinner data-cy='isfp-finish-process-btn' variant={"contained"} loading={isLoading} label='Abschließen' onClick={handleFinalize} />
      </DialogActions>
    </Dialog>
  );
};
