import { useEffect, useState } from "react";
import useFetchLeads, { SearchParams } from "../../../hooks/useFetchLeads";
import { Lead, Product } from "../../../types/cockpit/types";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import InviteTable, { OrderBy, OrderParams } from "./InviteTable";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { useReachedScrollBottom } from "../../../hooks/useReachedScrollBottom";

export interface InviteProps {
  id: string;
  name: string;
  email: string;
  product: Product;
  createdAt: Date;
}

export default function InviteList() {
  const pageSize = 20;

  const { getLeads, leads, isLoading, error } = useFetchLeads();
  const [leadsPages, setLeadsPages] = useState<InviteProps[][]>([]);
  const [lastFetchEmpty, setLastFetchEmpty] = useState(false);
  const [page, setPage] = useState(0);
  const reachedScreenBottom = useReachedScrollBottom(300);
  const [fetchParams, setFetchParams] = useState<{ orderBy: OrderBy; order: "asc" | "desc" } & SearchParams>({
    order: "asc",
    orderBy: "name",
    wasInvited: true,
  });

  useEffect(() => {
    if (leads?.length) {
      const updatedLeadsPages = [...leadsPages];

      updatedLeadsPages[page] = transformInvites(leads);
      setLeadsPages(updatedLeadsPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leads]);

  useEffect(() => {
    if (!isLoading) {
      setLastFetchEmpty(leads?.length != undefined && leads.length < pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // on fetch parameters change, reset view and redo the query, also setting the page to 0
  useEffect(() => {
    resetView();
    // getLeads({ ...fetchParams, pageSize, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParams]);

  useEffect(() => {
    if (!lastFetchEmpty) {
      getLeads({ ...fetchParams, pageSize, page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const resetView = () => {
    setLeadsPages([]);
    setLastFetchEmpty(false);

    if (page > 0) {
      // setting page to 0 in case it something else will retrigger the search
      setPage(0);
    } else {
      // otherwise, just load leads again
      getLeads({ ...fetchParams, page, pageSize });
    }
  };

  useEffect(() => {
    if (reachedScreenBottom) {
      setPage(page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reachedScreenBottom]);

  const handleSearch = (event: React.SyntheticEvent, value: string) => {
    if (value != fetchParams.searchString) {
      setFetchParams({ ...fetchParams, searchString: value });
    }
  };

  const orderChanged = (ordering: OrderParams) => {
    setFetchParams({ ...fetchParams, ...ordering });
  };

  const refreshHandler = () => {
    resetView();
  };

  const rows = leadsPages.reduce((a, b) => a.concat(b), []);

  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4, mx: 2 }}>
      <Stack spacing={0.5}>
        <TableTopActions onSearchChange={handleSearch} onRefresh={refreshHandler} />
        <InviteTable order={{ order: fetchParams.order, orderBy: fetchParams.orderBy }} orderChanged={orderChanged} invites={rows} />
        <Stack minHeight={450}>
          {!lastFetchEmpty && isLoading && <LoadingData />}
          {error && (
            <Typography align='center' pt={2} pb={5}>
              Es ist ein Fehler aufgetreten. Bitte versuche es erneut
            </Typography>
          )}
          {lastFetchEmpty && rows.length == 0 && (
            <Typography align='center' pt={2} pb={5}>
              Keine Ergebnisse gefunden
            </Typography>
          )}
          {lastFetchEmpty && !isLoading && leadsPages?.length != 0 && (
            <Typography align='center' pt={2} pb={5}>
              Keine weitere Einträge
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

function LoadingData() {
  return (
    <Stack spacing={2} pb={2}>
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
    </Stack>
  );
}

type TableTopActionsProps = {
  onSearchChange: (event: React.SyntheticEvent, value: string) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, onRefresh }: TableTopActionsProps) {
  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label='Suchen'
              placeholder='Name, E-mail, etc...'
              slotProps={{
                input: {
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          options={[]}
          onChange={onSearchChange}
          sx={{ flexGrow: 4, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-lead-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}

function transformInvites(leads?: Lead[]): InviteProps[] {
  if (!leads) {
    return [];
  }
  return (
    leads
      .map((lead) => {
        const productRequest = getCCInvite(lead) || getIsfpInvite(lead) || getOPInvite(lead);

        return {
          id: lead.id,
          name: lead.name,
          email: lead.email,
          product: productRequest?.product,
          createdAt: productRequest?.createdAt,
        };
      })
      .map((invite) => {
        let createdAt;
        if (!invite.createdAt) {
          createdAt = new Date(0);
        } else {
          createdAt = new Date(invite.createdAt);
        }

        return {
          id: invite.id,
          name: invite.name,
          email: invite.email,
          product: invite.product!,
          createdAt,
        };
      }) || []
  );
}

const getCCInvite = (lead: Lead) => {
  return lead.productInvites?.find((invite) => invite.product === Product.consumptionCertificate);
};

const getIsfpInvite = (lead: Lead) => {
  return lead.productInvites?.find((invite) => invite.product === Product.isfp);
};

const getOPInvite = (lead: Lead) => {
  return lead.productInvites?.find((invite) => invite.product === Product.onePager);
};
