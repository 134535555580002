import { Avatar, Stack, StackProps, Typography } from "@mui/material";

function stringToColor(email: string) {
  let hash = 0;
  for (let i = 0; i < email.length; i++) {
    const reverseIndex = email.length - 1 - i;
    hash = (hash << 7) - hash + email.charCodeAt(reverseIndex);
    hash |= 0;
  }
  return `hsl(${hash % 360}, 50%, 50%)`;
}

function stringAvatar(email: string) {
  const username = email.split("@")[0];
  const firstName = username.split(".")[0];
  const lastName = username.split(".")[1];
  const initials = firstName?.charAt(0).toUpperCase() + (lastName ? lastName.charAt(0).toUpperCase() : "");
  return {
    sx: {
      bgcolor: stringToColor(email),
    },
    children: initials,
  };
}

interface LeadCustodianProps extends StackProps {
  email: string;
  short?: boolean;
  strikethrough?: boolean;
}
export function LeadCustodian({ email, short = false, strikethrough = false, ...props }: LeadCustodianProps) {
  if (short) {
    return <Avatar {...stringAvatar(email)} />;
  }
  return (
    <Stack {...props} direction='row' spacing={2} alignItems='center'>
      <Avatar {...stringAvatar(email)} />
      <Typography
        sx={{
          textDecoration: strikethrough ? "line-through" : "inherit",
          fontWeight: strikethrough ? "600" : "inherit",
          color: strikethrough ? "#000000B0" : "inherit",
        }}
      >
        {email}
      </Typography>
    </Stack>
  );
}
