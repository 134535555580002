import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { ChangeEvent, useEffect, useState } from "react";
import Smartlook from "smartlook-client";
import NovoLogo from "../../components/common/NovoLogo";
import Grid from "@mui/material/Grid2";
import { Alert, Card, Checkbox, Chip, FormControl, FormControlLabel, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { NovoGermanPhoneInput } from "../../components/common/NovoGermanPhoneInput";
import NovoAddressAutocomplete, { AddressDetails, getAddressDetails } from "../../components/common/NovoAddressAutocomplete";
import { NovoTooltip } from "../../components/common/NovoTooltip";
import HelpIcon from "@mui/icons-material/Help";
import { ConsentLabel } from "../../components/ConsentLabel";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { isValidEmail } from "../auth/emailValidator";
import { phoneNumberIsValid } from "../auth/phoneNumberValidator";
import { useSendContactRequested } from "../../hooks/useSendContactRequested";

export default function OfferRequest() {
  const [product, setProduct] = useState<string>("");
  const [productError, setProductError] = useState(false);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [telephone, setTelephone] = useState<string>("+49");
  const [telephoneError, setTelephoneError] = useState(false);
  const [autocompleteAddress, setAutocompleteAddress] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [address, setAddress] = useState<string>("");
  const [addressError, setAddressError] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { sendContactRequested, error, running, data } = useSendContactRequested();

  const nameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setNameError(false);
  };

  const emailHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const telephoneHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTelephone(event.target.value);
    setTelephoneError(false);
  };

  const addressHandler = (autocompleteAddress: google.maps.places.AutocompletePrediction | null) => {
    if (autocompleteAddress) {
      getAddressDetails(autocompleteAddress, processAddressDetails);
    }
    setAutocompleteAddress(autocompleteAddress);
  };

  const processAddressDetails = (details: AddressDetails) => {
    const { address, postalCode, town, state } = details;
    setAddress(`${address} ${postalCode} ${town} ${state}`);
    setAddressError(false);
  };

  const messageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const consentHandler = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setConsent(checked);
    setConsentError(false);
  };

  const handleProductChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setProduct(event.target.value);
      setProductError(false);
    }
  };

  const validate = () => {
    if (!product) {
      setProductError(true);
    } else {
      setProductError(false);
    }
    if (!name || name.length < 3) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (!email || !isValidEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!telephone || !phoneNumberIsValid(telephone)) {
      setTelephoneError(true);
    } else {
      setTelephoneError(false);
    }
    if (!address || address.length < 3) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    setConsentError(!consent);
    return isValid();
  };

  const isValid = () => {
    return !nameError && !emailError && !telephoneError && !addressError && !consentError && !productError;
  };

  useEffect(() => {
    Smartlook.init("5e43c176485b8edcd59da14bec2d5d2e2f2c3ef0", { region: "eu" });
    Smartlook.record({ forms: true, numbers: true, emails: true, ips: true, api: true });
  }, []);

  useEffect(() => {
    if (error) {
      setShowErrorMessage(true);
    }
    if (data) {
      setShowSuccessMessage(true);
    }
  }, [error, data]);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
  };

  const submitRequest = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const form = event.currentTarget?.form;

    if (validate() && form && form.checkValidity()) {
      sendContactRequested({
        name,
        email,
        telephone,
        address,
        message,
        product,
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#f6f6f6", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1, cursor: "pointer" }} onClick={() => window.location.reload()} />
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} justifyContent={"center"} component={"form"} sx={{ mt: 5, maxWidth: "lg", mx: "auto" }}>
        <Grid size={{ xs: 12, md: 6 }} color={productError ? "#D0004B" : ""} component={Card}>
          <Stack direction='row' p={2} alignItems={"center"} style={{ height: "100%" }}>
            <Checkbox data-cy='isfp-checkbox' checked={product === "isfp"} value='isfp' onChange={handleProductChange} />
            <Stack direction='column' spacing={3} p={2} style={{ height: "100%" }}>
              <Chip size='small' label={"ab 995€"} color={"success"} style={{ fontWeight: 600 }} />
              <Typography style={{ fontSize: 20, fontWeight: 600 }}>Angebot für einen Sanierungsfahrplan (iSFP)</Typography>
              <Typography>ab 995€ Eigenanteil (zzgl. 650 € Förderung durch BAFA)</Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} color={productError ? "#D0004B" : ""} component={Card}>
          <Stack direction='row' p={2} alignItems={"center"}>
            <Checkbox data-cy='baubegleitung-checkbox' checked={product === "baubegleitung"} value='baubegleitung' onChange={handleProductChange} />
            <Stack direction='column' spacing={3} p={2}>
              <Chip size='small' label={"ab 250€"} color={"success"} style={{ fontWeight: 600 }} />
              <Typography style={{ fontSize: 20, fontWeight: 600 }}>Angebot für Baubegleitung</Typography>
              <Typography>ab 250€ (zzgl. individueller BAFA-Förderung)</Typography>
              <Typography>Du planst eine konkrete Sanierungsmaßnahme und benötigst eine begleitende Energieberatung für die Förderung.</Typography>
            </Stack>
          </Stack>
        </Grid>

        {productError && (
          <Grid size={12} color='#D0004B'>
            <Typography variant='caption'>Bitte wählen Sie eines der oben stehenden Produkte aus</Typography>
          </Grid>
        )}

        <Grid
          container
          spacing={2}
          width={{
            sm: 500,
            md: 600,
            lg: 900,
            xl: 900,
          }}
        >
          <Grid size={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                variant='outlined'
                id='name-input'
                data-cy='name-input'
                value={name}
                type='text'
                label='Vor- und Nachname'
                onChange={nameHandler}
                required={true}
                error={nameError}
                helperText={nameError ? "Bitte tragen Sie Ihren Namen ein" : ""}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <TextField
                variant='outlined'
                id='email-input'
                data-cy='email-input'
                value={email}
                type='email'
                inputMode='email'
                label='Email Adresse'
                onChange={emailHandler}
                required={true}
                error={emailError}
                helperText={emailError ? "Bitte tragen Sie Ihre E-Mail-Adresse ein" : ""}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <NovoGermanPhoneInput
                variant='outlined'
                id='telephone-input'
                data-cy='telephone-input'
                value={telephone}
                type='tel'
                inputMode='tel'
                label='Telefonnummer'
                onChange={telephoneHandler}
                required={true}
                error={!!telephoneError}
                helperText={telephoneError ? "Bitte geben Sie Ihre Telefonnummer ein" : ""}
              />
            </FormControl>
          </Grid>
          <Grid size={12}>
            <Stack direction='row' alignItems='center' spacing={2}>
              <NovoAddressAutocomplete
                id='street-address-input'
                data_cy='street-address-input'
                address={autocompleteAddress}
                updateAddress={addressHandler}
                addressError={addressError ? "Bitte wählen Sie die Adresse Ihrer Immobilie aus" : ""}
              />
              <NovoTooltip title='Wenn Ihre Adresse in den Vorschlägen nicht enthalten ist, geben Sie weitere Informationen ein, beispielsweise Stadt oder Stadtteil.'>
                <HelpIcon />
              </NovoTooltip>
            </Stack>
          </Grid>

          <Grid size={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                id='message-input'
                data-cy='message-input'
                value={message}
                label='Deine Anmerkungen für uns'
                minRows={4}
                onChange={messageHandler}
              />
            </FormControl>
          </Grid>

          <Grid size={12}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: consentError ? "error.main" : "default" }}
                  data-cy='terms-option-consent'
                  name='checkbox-consent'
                  required
                  checked={consent}
                  onChange={consentHandler}
                />
              }
              label={<ConsentLabel termsConsentError={consentError} />}
            />
          </Grid>
          <Grid size={12} mt={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Snackbar open={showErrorMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose}>
              <Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
                Es ist leider ein Fehler aufgetreten.
              </Alert>
            </Snackbar>
            <Snackbar open={showSuccessMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose}>
              <Alert onClose={handleClose} severity='success' sx={{ width: "100%" }}>
                Wir haben Ihre Nachricht erhalten und werden Sie in Kürze kontaktieren
              </Alert>
            </Snackbar>

            <ButtonWithSpinner
              data-cy='submit-contact-request'
              loading={running}
              variant={"contained"}
              label='Angebot anfordern'
              onClick={(e) => submitRequest(e)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
