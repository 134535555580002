import { Checkbox, Collapse, FormControl, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import NovoTextField from "../../../../components/common/NovoTextField";
import useImage from "../../../../hooks/useImage";
import FormScreen from "../../FormScreen";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";

export default function Screen3({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, next }: StatusQuoScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { yearOfBasementInsulation, yearOfWallsInsulation, yearOfRoofInsulation } = schnellcheck.answers?.houseInsulation ?? {};
  const roof = useImage("isfp-icons/roof.png");
  const walls = useImage("isfp-icons/walls.png");
  const basement = useImage("isfp-icons/basement.png");
  const [isRoofSelected, setRoofSelected] = useState<boolean>(!!yearOfRoofInsulation);
  const [roofYearError, setRoofYearError] = useState<string>();
  const [isWallsSelected, setWallsSelected] = useState<boolean>(!!yearOfWallsInsulation);
  const [wallsYearError, setWallsYearError] = useState<string>();
  const [isBasementSelected, setBasementSelected] = useState<boolean>(!!yearOfBasementInsulation);
  const [basementYearError, setBasementYearError] = useState<string>();

  const buildingYear = schnellcheck?.answers?.houseDetails?.yearOfConstruction || 1800;
  const currentYear = new Date().getFullYear();

  const toggleRoofSelected = () => {
    if (isRoofSelected) {
      updateRoofYear();
    }
    setRoofSelected(!isRoofSelected);
  };

  const updateRoofYear = (value?: string) => {
    const yearOfRoofInsulation = value && /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseInsulation: {
          ...schnellcheck.answers?.houseInsulation,
          yearOfRoofInsulation,
        },
      },
    });
    if (yearOfRoofInsulation) {
      setRoofYearError(undefined);
    }
  };

  const toggleWallsSelected = () => {
    if (isWallsSelected) {
      updateWallsYear();
    }
    setWallsSelected(!isWallsSelected);
  };

  const updateWallsYear = (value?: string) => {
    const yearOfWallsInsulation = value && /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseInsulation: {
          ...schnellcheck.answers?.houseInsulation,
          yearOfWallsInsulation,
        },
      },
    });
    if (yearOfWallsInsulation) {
      setWallsYearError(undefined);
    }
  };

  const toggleBasementSelected = () => {
    if (isBasementSelected) {
      updateBasementYear();
    }
    setBasementSelected(!isBasementSelected);
  };

  const updateBasementYear = (value?: string) => {
    const yearOfBasementInsulation = value && /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseInsulation: {
          ...schnellcheck.answers?.houseInsulation,
          yearOfBasementInsulation,
        },
      },
    });
    if (yearOfBasementInsulation) {
      setBasementYearError(undefined);
    }
  };

  const validate = () => {
    const { yearOfBasementInsulation, yearOfWallsInsulation, yearOfRoofInsulation } = schnellcheck.answers?.houseInsulation ?? {};
    if (isRoofSelected) {
      if (!yearOfRoofInsulation) {
        setRoofYearError("Pflichtfeld");
        return false;
      }
      if (yearOfRoofInsulation < buildingYear || yearOfRoofInsulation > currentYear) {
        setRoofYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
        return false;
      }
      setRoofYearError(undefined);
    }

    if (isWallsSelected) {
      if (!yearOfWallsInsulation) {
        setWallsYearError("Pflichtfeld");
        return false;
      }
      if (yearOfWallsInsulation < buildingYear || yearOfWallsInsulation > currentYear) {
        setWallsYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
        return false;
      }
      setWallsYearError(undefined);
    }

    if (isBasementSelected) {
      if (!yearOfBasementInsulation) {
        setBasementYearError("Pflichtfeld");
        return false;
      }
      if (yearOfBasementInsulation < buildingYear || yearOfBasementInsulation > currentYear) {
        setBasementYearError(`Das Jahr muss zwischen ${buildingYear} und ${currentYear} liegen`);
        return false;
      }
      setBasementYearError(undefined);
    }
    return true;
  };

  useEffect(() => {
    const { yearOfBasementInsulation, yearOfWallsInsulation, yearOfRoofInsulation } = schnellcheck.answers?.houseInsulation ?? {};
    setBasementSelected(!!yearOfBasementInsulation || isBasementSelected);
    setWallsSelected(!!yearOfWallsInsulation || isWallsSelected);
    setRoofSelected(!!yearOfRoofInsulation || isRoofSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schnellcheck]);

  const validateAndSave = () => {
    if (validate()) {
      next();
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: { xs: 2, lg: 2 } }}>
          <Typography variant='body1' fontSize={isMobile ? 14 : 16}>
            <strong>Schritt 3: </strong>Wurde Ihr Haus nachträglich gedämmt? (Bitte geben Sie das Datum der letzten Dämmmaßnahmen an)
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack
            component={Paper}
            direction={"row"}
            spacing={2}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: isRoofSelected ? "" : "inherit" }}
            onClick={toggleRoofSelected}
          >
            <Stack alignItems={"start"} spacing={isMobile ? 0 : 1}>
              <img src={roof.image} height={isMobile ? 48 : 60} width={isMobile ? 48 : 60} style={{ marginLeft: "auto", marginRight: "auto" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Dach
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}>
              <Collapse in={isRoofSelected}>
                <Stack sx={{ flexGrow: 1 }}>
                  <FormControl
                    onClick={(evt) => {
                      evt.stopPropagation();
                    }}
                  >
                    <NovoTextField
                      fullWidth
                      variant='outlined'
                      id='roof-year-input'
                      data-cy='roof-year-input'
                      value={schnellcheck.answers?.houseInsulation?.yearOfRoofInsulation ?? ""}
                      label='Jahr (Circa)'
                      onChange={(event) => updateRoofYear(event.target.value)}
                      required={true}
                      type='number'
                      error={!!roofYearError}
                      helperText={roofYearError}
                      sx={{
                        "& > label.Mui-focused": {
                          color: "text.primary",
                        },
                        "& > div": {
                          color: "text.primary",
                          backgroundColor: "rgba(255, 255, 255, 0.10)",
                        },
                        "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                          color: "text.primary",
                          borderColor: "rgba(0 ,0, 0, 0.87)",
                        },
                      }}
                      slotProps={{
                        htmlInput: { min: buildingYear, max: currentYear },
                        inputLabel: { shrink: !!schnellcheck.answers?.houseInsulation?.yearOfRoofInsulation },
                      }}
                    />
                  </FormControl>
                </Stack>
              </Collapse>
            </Stack>
            <Stack sx={{ alignItems: "flex-end" }}>
              <Checkbox data-cy='roof-input' sx={{ "&.Mui-checked": { color: "text.primary" } }} onChange={toggleRoofSelected} checked={isRoofSelected} />
            </Stack>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack
            component={Paper}
            direction={"row"}
            spacing={2}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: isWallsSelected ? "" : "inherit" }}
            onClick={toggleWallsSelected}
          >
            <Stack alignItems={"start"} spacing={isMobile ? 0 : 1}>
              <img src={walls.image} height={isMobile ? 48 : 60} width={isMobile ? 48 : 60} style={{ marginLeft: "auto", marginRight: "auto" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Wände
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}>
              <Collapse in={isWallsSelected}>
                <Stack sx={{ flexGrow: 1 }}>
                  <FormControl
                    onClick={(evt) => {
                      evt.stopPropagation();
                    }}
                  >
                    <NovoTextField
                      variant='outlined'
                      id='walls-year-input'
                      data-cy='walls-year-input'
                      value={schnellcheck.answers?.houseInsulation?.yearOfWallsInsulation ?? ""}
                      label='Jahr (Circa)'
                      onChange={(event) => updateWallsYear(event.target.value)}
                      required={true}
                      type='number'
                      error={!!wallsYearError}
                      helperText={wallsYearError}
                      sx={{
                        "& > label.Mui-focused": {
                          color: "text.primary",
                        },
                        "& > div": {
                          color: "text.primary",
                          backgroundColor: "rgba(255, 255, 255, 0.10)",
                        },
                        "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                          color: "text.primary",
                          borderColor: "rgba(0 ,0, 0, 0.87)",
                        },
                      }}
                      slotProps={{
                        htmlInput: { min: buildingYear, max: currentYear },
                        inputLabel: { shrink: !!schnellcheck.answers?.houseInsulation?.yearOfWallsInsulation },
                      }}
                    />
                  </FormControl>
                </Stack>
              </Collapse>
            </Stack>
            <Stack sx={{ alignItems: "flex-end" }}>
              <Checkbox data-cy='walls-input' sx={{ "&.Mui-checked": { color: "text.primary" } }} onChange={toggleWallsSelected} checked={isWallsSelected} />
            </Stack>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack
            component={Paper}
            direction={"row"}
            spacing={2}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: isBasementSelected ? "" : "inherit" }}
            onClick={toggleBasementSelected}
          >
            <Stack alignItems={"start"} spacing={isMobile ? 0 : 1}>
              <img src={basement.image} height={isMobile ? 48 : 60} width={isMobile ? 48 : 60} style={{ marginLeft: "auto", marginRight: "auto" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Kellerdecke
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}>
              <Collapse in={isBasementSelected}>
                <Stack sx={{ flexGrow: 1 }}>
                  <FormControl
                    onClick={(evt) => {
                      evt.stopPropagation();
                    }}
                  >
                    <NovoTextField
                      variant='outlined'
                      id='basement-year-input'
                      data-cy='basement-year-input'
                      value={schnellcheck.answers?.houseInsulation?.yearOfBasementInsulation ?? ""}
                      label='Jahr (Circa)'
                      onChange={(event) => updateBasementYear(event.target.value)}
                      required={true}
                      type='number'
                      error={!!basementYearError}
                      helperText={basementYearError}
                      sx={{
                        "& > label.Mui-focused": {
                          color: "text.primary",
                        },
                        "& > div": {
                          color: "text.primary",
                          backgroundColor: "rgba(255, 255, 255, 0.10)",
                        },
                        "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                          color: "text.primary",
                          borderColor: "rgba(0 ,0, 0, 0.87)",
                        },
                      }}
                      slotProps={{
                        htmlInput: { min: buildingYear, max: currentYear },
                        inputLabel: { shrink: !!schnellcheck.answers?.houseInsulation?.yearOfBasementInsulation },
                      }}
                    />
                  </FormControl>
                </Stack>
              </Collapse>
            </Stack>
            <Stack sx={{ alignItems: "flex-end" }}>
              <Checkbox
                data-cy='basement-input'
                sx={{ "&.Mui-checked": { color: "text.primary" } }}
                onChange={toggleBasementSelected}
                checked={isBasementSelected}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='40% geschafft'
      progressValue={40}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Dach, Wände und Boden bilden
      <br />
      zusammen eine sehr große Fläche,
      <br />
      über die die meiste Wärme verloren geht.
      <br />
      Wenn sie gut isoliert sind, genügt eine
      <br />
      kleinere Heizung.
      <br />
      <br />
      Weiterer Vorteil: Nie wieder kalte Füße!
      <br />
      Je weniger Wärme hinaus kann,
      <br />
      desto behaglicher ist das Haus.
    </Typography>
  );
}
