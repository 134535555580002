import { TableSortLabel } from "@mui/material";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import { Product } from "../../../types/cockpit/types";
import { InviteProps } from "./InviteList";

export type Order = "asc" | "desc";
export type OrderBy = "name" | "email" | "createdAt" | "product";

export interface OrderParams {
  order: Order;
  orderBy: OrderBy;
}

export interface InviteTableProps {
  invites?: InviteProps[];
  order: OrderParams;
  orderChanged: (orderParams: OrderParams) => void;
}

export default function InviteTable({ order, invites, orderChanged }: InviteTableProps) {
  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === order.orderBy) {
      orderChanged({ ...order, order: order.order === "asc" ? "desc" : "asc" });
    } else {
      orderChanged({ ...order, orderBy: newOrder });
    }
  };

  return (
    <Table aria-label='collapsible table' data-cy='invited-leads-list' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={order.orderBy === "name"}
              direction={order.order}
              onClick={() => {
                handleOrderChange("name");
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={order.orderBy === "email"}
              direction={order.order}
              onClick={() => {
                handleOrderChange("email");
              }}
            >
              Email
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={order.orderBy === "createdAt"}
              direction={order.order}
              onClick={() => {
                handleOrderChange("createdAt");
              }}
            >
              Einladungsdatum
            </TableSortLabel>
          </TableCell>
          <TableCell>Produkt</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{invites?.filter((row) => !!row).map((row) => <Row key={row.id} row={row} />)}</TableBody>
    </Table>
  );
}

function Row({ row }: { row: InviteProps }) {
  let product = "";
  switch (row.product) {
    case Product.consumptionCertificate:
      product = "Energieausweis";
      break;
    case Product.isfp:
      product = "Schnellcheck / iSFP";
      break;
    case Product.onePager:
      product = "Sanierungsgutachten";
      break;
    default:
      product = "";
      break;
  }
  return (
    <Fragment>
      <TableRow
        sx={{
          height: 75,
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
      >
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.createdAt.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}</TableCell>
        <TableCell>
          <Chip sx={{ mr: 1 }} label={product} />
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}
