export enum Steps {
  start = "",
  customer = "#customer",
  eligibility = "#eligibility-check",
  building = "#building",
  photo = "#photo",
  heating = "#heating",
  hotwater = "#hotwater",
  cooling = "#cooling",
  misc = "#misc",
  checkout = "#checkout",
  final = "#final",
}

export const fromHash = (value: string): Steps => {
  switch (value) {
    case "":
      return Steps.start;
    case "#customer":
      return Steps.customer;
    case "#eligibility-check":
      return Steps.eligibility;
    case "#building":
      return Steps.building;
    case "#photo":
      return Steps.photo;
    case "#heating":
      return Steps.heating;
    case "#hotwater":
      return Steps.hotwater;
    case "#cooling":
      return Steps.cooling;
    case "#misc":
      return Steps.misc;
    case "#checkout":
      return Steps.checkout;
    case "#final":
      return Steps.final;
    default:
      throw new Error(`"${value}" is not a valid value for Steps.`);
  }
};
