import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { Skeleton, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import NovoMultiSelect from "../../../../components/common/NovoMultiSelect";
import useFetchLeads, { ConsumptionCertSearchStatus, SearchParams } from "../../../../hooks/useFetchLeads";
import { useReachedScrollBottom } from "../../../../hooks/useReachedScrollBottom";
import { ConsumptionCertificate, ConsumptionCertificateGenState } from "../../../../types/ConsumptionCertificateState";
import { RenovationQuestionnaire } from "../../../../types/RenovationQuestionnaire";
import { SchnellcheckQuestionnaire } from "../../../../types/Schnellcheck";
import {
  BubbleFlow,
  CCState,
  IsfpProcessStatus,
  IsfpProperty,
  IsfpState,
  Lead,
  OPState,
  Product,
  ProductRequest,
  Property,
} from "../../../../types/cockpit/types";
import LeadTable, { OrderBy, OrderParams, TABLE_COLUMNS_COUNT } from "./LeadTable";
import { usePlaceholderLeadData } from "./PlaceholderLeadData";

export interface RowProps {
  id: string;
  name: string;
  address: string;
  properties?: Array<Property>;
  email: string;
  consultantDataReviewConsent?: boolean;
  productInvites?: ProductRequest[];
  referrer?: string;
  custodians?: string[];
  cc: {
    state: CCState;
    id?: string;
    ccData?: ConsumptionCertificate;
    address?: string;
    bubbleCdnUrl?: string;
  };
  isfp: {
    id?: string;
    state: IsfpState;
    address?: string;
    bubbleFlow?: BubbleFlow;
    bubbleCdnUrl?: string;
    schnellcheck?: SchnellcheckQuestionnaire;
    renovations?: RenovationQuestionnaire;
  };
  op: {
    state: OPState;
    bubbleCdnUrl?: string;
    sanierungscheckKfw?: string;
    sanierungscheckSanierungspflicht?: string;
  };
  columnsCount: number;
}

const ccStateToFetchFilterMapping: { [key: number]: ConsumptionCertSearchStatus } = {
  [CCState.INVITED]: "invited",
  [CCState.FINAL]: "final",
  [CCState.SUBMITTED]: "submitted",
};

const isfpStateToFetchFilterMapping: { [key: number]: IsfpProcessStatus } = {
  [IsfpState.INVITED]: IsfpProcessStatus.INVITE_SENT,
  [IsfpState.STATUS_QUO_STARTED]: IsfpProcessStatus.SCHNELLCHECK_STARTED,
  [IsfpState.STATUS_QUO_FINISHED]: IsfpProcessStatus.SCHNELLCHECK_FINISHED,
  [IsfpState.OFFER_REQUESTED]: IsfpProcessStatus.OFFER_REQUESTED,
  [IsfpState.OFFER_REJECTED]: IsfpProcessStatus.OFFER_REJECTED,
  [IsfpState.OFFER_ACCEPTED]: IsfpProcessStatus.OFFER_ACCEPTED,
  [IsfpState.RENOVATIONS_STARTED]: IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED,
  [IsfpState.RENOVATIONS_FINISHED]: IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED,
  [IsfpState.FINAL]: IsfpProcessStatus.FINISHED,
};

export default function LeadList() {
  const pageSize = 20;

  const { getLeads, leads, isLoading, error } = useFetchLeads();
  const [leadsPages, setLeadsPages] = useState<RowProps[][]>([]);
  const [lastFetchEmpty, setLastFetchEmpty] = useState(false);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const reachedScreenBottom = useReachedScrollBottom(300);
  const [searchParams] = useSearchParams();

  const initialFilterVal = decodeURIComponent(searchParams.get("filter") ?? "");

  const [fetchParams, setFetchParams] = useState<
    {
      orderBy: OrderBy;
      order: "asc" | "desc";
    } & SearchParams
  >({
    order: "asc",
    orderBy: "name",
    searchString: initialFilterVal,
  });

  const placeholderLeads = usePlaceholderLeadData();

  useEffect(() => {
    if (leads?.length) {
      const updatedLeadsPages = [...leadsPages];

      const processedRows = leads?.length === 0 ? enrichRows(TABLE_COLUMNS_COUNT, placeholderLeads) : enrichRows(TABLE_COLUMNS_COUNT, leads);
      updatedLeadsPages[page] = processedRows;
      setLeadsPages(updatedLeadsPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leads]);

  useEffect(() => {
    if (!isLoading) {
      setLastFetchEmpty(leads?.length != undefined && leads.length < pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // on fetch parameters change, reset view and redo the query, also setting the page to 0
  useEffect(() => {
    if (!isLoading) {
      resetView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParams]);

  useEffect(() => {
    if (!lastFetchEmpty) {
      getLeads({ ...fetchParams, pageSize, page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const resetView = () => {
    setLeadsPages([]);
    setLastFetchEmpty(false);

    if (page > 0) {
      // setting page to 0 in case it something else will retrigger the search
      setPage(0);
    } else {
      // otherwise, just load leads again
      getLeads({ ...fetchParams, page, pageSize });
    }
  };

  useEffect(() => {
    if (reachedScreenBottom && !isLoading) {
      setPage(page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reachedScreenBottom]);

  const refreshHandler = () => {
    if (!isLoading) {
      resetView();
    }
  };

  const handleSearch = (value: string) => {
    if (value != fetchParams.searchString) {
      setFetchParams({ ...fetchParams, searchString: value });
    }
  };

  const orderChanged = (ordering: OrderParams) => {
    setFetchParams({ ...fetchParams, ...ordering });
  };

  const onFilterUpdate = (value: Array<string>) => {
    const ccFilterValues = value.map((filterName) => CCState.fromString(filterName)).filter((state) => state !== undefined);
    const ccStatusFilters = ccFilterValues.map((ccFilter) => ccStateToFetchFilterMapping[ccFilter!]);

    const isfpFilterValues = value.map((filterName) => IsfpState.fromString(filterName)).filter((state) => state !== undefined);
    const isfpStatusFilters = isfpFilterValues.map((isfpFilter) => isfpStateToFetchFilterMapping[isfpFilter!]);

    setFetchParams({
      ...fetchParams,
      ccStatus: ccStatusFilters,
      isfpStatus: isfpStatusFilters,
    });
    setSelectedStatusFilters([...ccStatusFilters, ...isfpStatusFilters]);
  };

  const rows = leadsPages.reduce((a, b) => a.concat(b), []);

  return (
    <Box data-joyride={"leadlist-container"} sx={{ flexGrow: TABLE_COLUMNS_COUNT, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={0.5}>
        <TableTopActions onSearchChange={handleSearch} filters={selectedStatusFilters} onFilterUpdate={onFilterUpdate} onRefresh={refreshHandler} />
        <LeadTable rows={rows} order={{ order: fetchParams.order, orderBy: fetchParams.orderBy }} orderChanged={orderChanged} />
        <Stack minHeight={450}>
          {!lastFetchEmpty && isLoading && <LoadingData />}
          {error && (
            <Typography align='center' pt={2} pb={5}>
              Es ist ein Fehler aufgetreten. Bitte versuche es erneut
            </Typography>
          )}
          {lastFetchEmpty && rows.length == 0 && (
            <Typography align='center' pt={2} pb={5}>
              Keine Ergebnisse gefunden
            </Typography>
          )}
          {lastFetchEmpty && !isLoading && leadsPages?.length != 0 && (
            <Typography align='center' pt={2} pb={5}>
              Keine weitere Einträge
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

function LoadingData() {
  return (
    <Stack spacing={2} pb={2}>
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
    </Stack>
  );
}

type TableTopActionsProps = {
  onSearchChange: (value: string) => void;
  filters: string[];
  onFilterUpdate: (value: Array<string>) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, filters, onFilterUpdate, onRefresh }: TableTopActionsProps) {
  const [searchParams] = useSearchParams();
  const [autocompleteValue, setAutocompleteValue] = useState("");

  const initialFilterVal = decodeURIComponent(searchParams.get("filter") ?? "");

  useEffect(() => {
    if (initialFilterVal) {
      setAutocompleteValue(initialFilterVal);
    }
  }, [initialFilterVal]);

  useEffect(() => {
    onSearchChange(autocompleteValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteValue]);

  const options = [
    ...IsfpState.all()
      .filter((state) => state !== IsfpState.START)
      .map((state) => IsfpState.toString(state)),
    ...CCState.all()
      .filter((state) => state !== CCState.START)
      .map((state) => CCState.toString(state)),
    ...OPState.all()
      .filter((state) => state !== OPState.START)
      .map((state) => OPState.toString(state)),
  ];

  const onAutocompleteChange = (_: React.SyntheticEvent, value: string) => {
    setAutocompleteValue(value);
  };

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              data-cy='search-lead-input'
              {...params}
              label='Suchen'
              placeholder='Name, E-mail, etc...'
              slotProps={{
                input: {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          options={[]}
          onChange={onAutocompleteChange}
          value={autocompleteValue}
          sx={{ flexGrow: 4, bgcolor: "background.default" }}
        />
        <NovoMultiSelect
          label={"Filtern nach"}
          options={options}
          value={filters}
          onUpdate={onFilterUpdate}
          sx={{ flexGrow: 1, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-lead-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}

function enrichRows(columnsCount: number, leads?: Lead[]): RowProps[] {
  if (!leads) {
    return [];
  }
  return leads.map((lead) => {
    const cc = inflateCCData(lead);
    const isfp = inflateIsfpData(lead);
    const op = inflateOPData(lead);
    const address = isfp.address || cc.address || "";
    const consultantDataReviewConsent = lead.consultantDataReviewConsent === undefined || lead.consultantDataReviewConsent; //NOVO-533
    return {
      ...lead,
      consultantDataReviewConsent,
      address,
      isfp,
      cc,
      op,
      columnsCount,
    };
  });
}

interface IsfpCompletenessState {
  address: string;
  isfp?: IsfpProperty;
  schnellcheck?: SchnellcheckQuestionnaire;
  renovations?: RenovationQuestionnaire;
  bubbleFlow?: BubbleFlow;
  status?: IsfpProcessStatus;
}

const isfpState = (lead: Lead): IsfpCompletenessState => {
  const isfp = lead.properties?.find((property) => !!property.isfp)?.isfp;
  const schnellcheck = isfp?.schnellcheck;
  const renovations = isfp?.renovationQuestionnaire;
  const bubbleFlow = isfp?.bubbleFlow;

  return {
    address: schnellcheck?.answers.contact?.address ?? bubbleFlow?.statusQuoResponse?.address ?? "",
    status: isfp?.status,
    schnellcheck,
    renovations,
    bubbleFlow,
    isfp,
  };
};

const inflateIsfpData = (lead: Lead) => {
  const { isfp, address, bubbleFlow, schnellcheck, renovations, status } = isfpState(lead);

  switch (status) {
    case IsfpProcessStatus.FINISHED:
      return { state: IsfpState.FINAL, address, bubbleCdnUrl: isfp?.bubbleFlow?.renovationReport?.isfp, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED:
      return { state: IsfpState.RENOVATIONS_FINISHED, address, bubbleFlow, schnellcheck, renovations, id: isfp?.id };
    case IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED:
      return { state: IsfpState.RENOVATIONS_STARTED, address, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.OFFER_ACCEPTED:
      return { state: IsfpState.OFFER_ACCEPTED, address, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.OFFER_REJECTED:
      return { state: IsfpState.OFFER_REJECTED, address, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.OFFER_REQUESTED:
      return { state: IsfpState.OFFER_REQUESTED, address, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.SCHNELLCHECK_FINISHED:
      return { state: IsfpState.STATUS_QUO_FINISHED, address, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.SCHNELLCHECK_STARTED:
      return { state: IsfpState.STATUS_QUO_STARTED, address, bubbleFlow, schnellcheck, id: isfp?.id };
    case IsfpProcessStatus.INVITE_SENT:
      return { state: IsfpState.INVITED, id: isfp?.id };
    default:
      return { state: IsfpState.START };
  }
};

const inflateCCData = (lead: Lead) => {
  const consumptionCertificate = lead.properties?.find((property) => property.consumptionCertificate !== undefined)?.consumptionCertificate;
  const hasStartedPath = !!consumptionCertificate || !!lead.productInvites?.find((invite) => invite.product === Product.consumptionCertificate);
  const certificateReady = consumptionCertificate?.state === "final" && consumptionCertificate?.document.state === ConsumptionCertificateGenState.Ready;
  const properties = lead.properties ?? [];
  const document = properties.length > 0 ? properties[0].isfp?.bubbleFlow?.renovationReport?.energyCertificate : undefined;

  if (certificateReady || document) {
    return {
      state: CCState.FINAL,
      id: consumptionCertificate?.id,
      address: consumptionCertificate?.answers.address,
      ccData: consumptionCertificate,
      bubbleCdnUrl: document,
    };
  } else if (consumptionCertificate) {
    return { state: CCState.SUBMITTED, id: consumptionCertificate!.id, address: consumptionCertificate!.answers.address, ccData: consumptionCertificate };
  } else if (hasStartedPath) {
    return { state: CCState.INVITED };
  } else {
    return { state: CCState.START };
  }
};

const inflateOPData = (lead: Lead) => {
  /*
   * TODO
   * The whole Sanierungsgutachten workflow is not yet supported in the engine.
   * We can only track Invites but no Property objects are yet associated with the Lead
   */
  // const op = lead.properties?.find((property) => property.onePager !== undefined)?.onePager;
  const hasStartedPath = !!lead.productInvites?.find((invite) => invite.product === Product.onePager);
  const renovationReport = lead.properties && lead.properties.length > 0 ? lead.properties[0].isfp?.bubbleFlow?.renovationReport : undefined;
  const { isfpLight: bubbleCdnUrl, sanierungscheckKfw, sanierungscheckSanierungspflicht } = renovationReport ?? {};

  if (bubbleCdnUrl || sanierungscheckKfw || sanierungscheckSanierungspflicht) {
    return {
      state: OPState.FINAL,
      bubbleCdnUrl,
      sanierungscheckKfw,
      sanierungscheckSanierungspflicht,
    };
  }
  if (hasStartedPath) {
    return { state: OPState.INVITED };
  }
  return { state: OPState.START };
};
