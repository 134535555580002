import AssessmentIcon from "@mui/icons-material/Assessment";
import BoltIcon from "@mui/icons-material/Bolt";
import CloseIcon from "@mui/icons-material/Close";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import HelpIcon from "@mui/icons-material/Help";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AxiosError } from "axios";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import NovoCustomSelect from "../../../components/common/NovoCustomSelect";
import { NovoGermanPhoneInput } from "../../../components/common/NovoGermanPhoneInput";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import useFetchInviteEmailTemplate from "../../../hooks/useFetchInviteEmailTemplate";
import { leadErrorCodeToMessage, useCreateLead } from "../../../hooks/useLeadInviteHooks";
import { InviteEmailTemplate, Product } from "../../../types/cockpit/types";
import { isTelephoneValid } from "../../../utils/utils";
import { isValidEmail } from "../../auth/emailValidator";
import { CCInviteClarification } from "./leadlist/CCInviteClarification";
import Voucher from "./Voucher";

interface InviteNewLeadDialogProps {
  setShowSuccessInvitation: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  open: boolean;
}

export default function InviteNewLeadDialog({ open, onClose, setShowSuccessInvitation }: InviteNewLeadDialogProps) {
  // const { user } = useContext(AuthContext);
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState("");
  // const [selfInvite, setSelfInvite] = useState(false);
  const [cc, setCc] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [telephone, setTelephone] = useState("+49");
  const [telephoneError, setTelephoneError] = useState(false);
  const [product, setProduct] = useState("");
  const [productError, setProductError] = useState(false);
  const [free, setFree] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { createLead, error, isLoading, lead } = useCreateLead();
  const { getInviteEmailTemplate, template: fetchedTemplate } = useFetchInviteEmailTemplate();
  const [template, setTemplate] = useState<InviteEmailTemplate | undefined>();

  const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccessInvitation(false);
    setCc(!!event.target.checked);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccessInvitation(false);
    setErrorMessage("");
    setNameError(false);
    setName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccessInvitation(false);
    setErrorMessage("");
    setEmailError(false);
    setEmail(event.target.value.toLowerCase());
  };

  const handleTelephoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccessInvitation(false);
    setErrorMessage("");
    setTelephoneError(false);
    setTelephone(event.target.value);
  };

  const handleProductChange = (value: string) => {
    setFree(false);
    setShowSuccessInvitation(false);
    setErrorMessage("");
    setProductError(false);
    setProduct(value);
  };

  // const selfInviteChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setEmail(user?.email ?? "");
  //     setName("Max Mustermann");
  //     setProduct("Schnellcheck");
  //     setSelfInvite(true);
  //     setFree(false);
  //   } else {
  //     setEmail("");
  //     setName("");
  //     setProduct("");
  //     setSelfInvite(false);
  //   }
  //   setShowSuccessInvitation(false);
  //   setErrorMessage("");
  // };

  const handleFreeChange = (checked: boolean) => {
    setShowSuccessInvitation(false);
    setErrorMessage("");
    setFree(checked);
  };

  const handleClose = () => {
    setName("");
    setNameError(false);
    setEmail("");
    setEmailError(false);
    setTelephone("+49");
    setTelephoneError(false);
    setProduct("");
    setProductError(false);
    setFree(false);
    setErrorMessage("");
    onClose();
  };

  const handleSubmit = () => {
    if (!name) {
      setNameError(true);
      return;
    }
    if (!email || !isValidEmail(email)) {
      setEmailError(true);
      return;
    }
    if (telephone != "+49" && !isTelephoneValid(telephone)) {
      setTelephoneError(true);
      return;
    }
    if (!product) {
      setProductError(true);
      return;
    }
    const reqBody = {
      name,
      email,
      ...(telephone != "+49" ? { telephone } : {}),
      referrerProduct: {
        product: Product[productMapping.get(product)!],
        isFree: free,
      },
      cc,
    };
    setShowSuccessInvitation(false);
    setErrorMessage("");
    createLead(reqBody);
  };

  const productMapping = new Map<string, Product>();
  productMapping.set("Schnellcheck", Product.isfp);
  productMapping.set("Energieausweis", Product.consumptionCertificate);
  productMapping.set("Sanierungsgutachten", Product.onePager);

  const productOptions = [
    { value: "Schnellcheck", subTitle: "Kostenloser erster Schritt für die iSFP-Erstellung & Expertenberatung", icon: <EnergySavingsLeafIcon /> },
    {
      value: "Energieausweis",
      subTitle: "Offizieller Energieausweis auf Basis von Energieverbrauch oder -bedarf",
      icon: <BoltIcon />,
    },
    { value: "Sanierungsgutachten", subTitle: "Schätzung der Sanierungskosten für Kauf oder Anschlussfinanzierung", icon: <AssessmentIcon /> },
  ];

  useEffect(() => {
    const selectedProduct = product ? Product[productMapping.get(product)!] : undefined;
    getInviteEmailTemplate(selectedProduct, free);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [free, getInviteEmailTemplate, product]);

  useEffect(() => {
    if (error && error instanceof AxiosError) {
      setErrorMessage(leadErrorCodeToMessage(error.response?.data?.code));
    } else if (lead) {
      setShowSuccessInvitation(true);
      setName("");
      setEmail("");
      setProduct("");
      setTelephone("+49");
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead, error, setShowSuccessInvitation]);

  useEffect(() => {
    if (fetchedTemplate) {
      setTemplate(fetchedTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTemplate]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xl"} data-joyride='lead-invite-modal' data-cy='invite-lead-modal' fullWidth>
      <DialogTitle>
        Neuen Kunden einladen
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          data-joyride='lead-invite-modal-close'
          data-cy='invite-lead-cancel-btn'
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid size={6}>
            <Stack spacing={2}>
              <DialogContentText>Bitte gib den Namen und die E-mail-Adresse des Kunden sowie das Produkt an, das du ihm empfehlen möchtest.</DialogContentText>
              {/* Commented out for the moment, as consultants were inviting themselves but landed in bubble as consultants, not homeowners */}
              {/* <Tooltip title={"Du kannst dich selbst einladen, um das Kundenerlebnis zu testen"}>
                <FormControlLabel
                  control={<Switch value={selfInvite} onChange={selfInviteChange} />}
                  label={
                    <>
                      Mich selbst einladen <InfoIcon fontSize='inherit' sx={{ verticalAlign: "middle" }} />
                    </>
                  }
                />
              </Tooltip> */}
              <FormControl fullWidth>
                <TextField
                  data-cy='invite-lead-name-input'
                  variant='outlined'
                  id='name'
                  value={name}
                  label='Name'
                  onChange={handleNameChange}
                  error={nameError}
                  helperText={nameError ? "Pflichtfeld!" : ""}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  data-cy='invite-lead-email-input'
                  variant='outlined'
                  id='email'
                  type='email'
                  value={email}
                  label='E-mail'
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={!emailError ? "" : email ? "Bitte geben Sie eine gültige E-mail an" : "Pflichtfeld!"}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <NovoGermanPhoneInput
                  variant='outlined'
                  id='telephone-input'
                  data-cy='telephone-input'
                  value={telephone}
                  type='tel'
                  inputMode='tel'
                  label='Telefonnummer (optional)'
                  onChange={handleTelephoneChange}
                  error={telephoneError}
                  helperText={telephoneError ? "Bitte geben Sie eine gültige Telefonnummer an" : ""}
                />
              </FormControl>
              <FormControlLabel control={<Switch value={cc} onChange={handleCcChange} />} label='Kopie an meine E-Mail schicken' />
              <Stack direction='row' spacing={2} alignItems={"center"}>
                <NovoCustomSelect
                  data-cy='invite-lead-product-selector'
                  label='Produkt'
                  options={productOptions}
                  value={product}
                  onUpdate={handleProductChange}
                  error={productError}
                  helperText={productError ? "Pflichtfeld!" : ""}
                  required
                />
                {Product[productMapping.get(product)!] === Product.consumptionCertificate && (
                  <NovoTooltip title={<CCInviteClarification leadName={name} leadEmail={email} />}>
                    <HelpIcon />
                  </NovoTooltip>
                )}
              </Stack>
              {product && <Voucher product={Product[productMapping.get(product)!]} voucherSelected={handleFreeChange} />}
              <Fade in={!!errorMessage} timeout={500} unmountOnExit>
                <Alert sx={{ marginTop: 2 }} severity='error'>
                  {errorMessage}
                </Alert>
              </Fade>
              <DialogActions sx={{ p: 3 }}>
                <Button variant='outlined' color='secondary' onClick={handleClose}>
                  Abbrechen
                </Button>
                <ButtonWithSpinner data-cy='invite-lead-confirm-btn' variant={"contained"} loading={isLoading} label='Einladen' onClick={handleSubmit} />
              </DialogActions>
            </Stack>
          </Grid>
          <Grid size={6}>
            {template?.emailMessage && <div dangerouslySetInnerHTML={{ __html: template?.emailMessage.replace("{name}", name) }} />}
            {!template?.emailMessage && <Skeleton height={700} animation='wave' variant='rectangular' />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
