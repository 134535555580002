import { FormHelperText, Grow, InputAdornment, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import NovoSelect from "../../../../components/common/NovoSelect";
import { InsulationMaterials, ReverseInsulationMaterials } from "./utils";
import useImage from "../../../../hooks/useImage";
import { Insulation } from "../../../../types/RenovationQuestionnaire";
import { Dispatch, SetStateAction } from "react";
import NovoTextField from "../../../../components/common/NovoTextField";

type InsulationComponentProps = {
  question: string;
  picture: string;
  fadein?: boolean;
  insulation?: Insulation;
  setInsulation?: (value: Insulation) => void;
  selectedError?: string;
  setSelectedError?: Dispatch<SetStateAction<string | undefined>>;
  minYear?: number;
  maxYear?: number;
  yearError?: string;
  setYearError?: Dispatch<SetStateAction<string | undefined>>;
  thicknessError?: string;
  setThicknessError?: Dispatch<SetStateAction<string | undefined>>;
  materialError?: string;
  setMaterialError?: Dispatch<SetStateAction<string | undefined>>;
};

export default function InsulationComponent({
  question,
  picture,
  fadein = false,
  insulation,
  setInsulation,
  selectedError,
  setSelectedError,
  minYear = 1900,
  maxYear = new Date().getFullYear(),
  yearError,
  setYearError,
  thicknessError,
  setThicknessError,
  materialError,
  setMaterialError,
}: InsulationComponentProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const pictogram = useImage(picture);
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
    >
      <Grid size={12}>
        <Grow in timeout={fadein ? 800 : 0}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%' fontSize={{ xs: 14, lg: 16 }}>
              {question}
            </Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={insulation?.installed === undefined ? "" : insulation.installed ? "yes" : "no"}
              onChange={(event, value) => {
                if (value === "yes") {
                  setInsulation?.({ ...insulation, installed: true });
                } else if (value === "no") {
                  setInsulation?.({ ...insulation, installed: false });
                } else {
                  setInsulation?.({ ...insulation, installed: undefined });
                }
                setSelectedError?.(undefined);
              }}
              sx={{ flexGrow: 1 }}
            >
              <ToggleButton data-cy='insulation-installed-toggle-yes' value='yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Ja
              </ToggleButton>
              <ToggleButton data-cy='insulation-installed-toggle-no' value='no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Nein
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grow>
      </Grid>
      <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (selectedError ? `1px solid ${theme.palette.error.main}` : "") }}>
        {!!selectedError && <FormHelperText error>{selectedError}</FormHelperText>}
      </Grid>
      <Grid size={6}>
        <Grow in timeout={fadein ? 800 : 0}>
          <Stack alignItems='center' flexGrow={1}>
            <img src={pictogram.image} width={isMobile ? 120 : 150} height={isMobile ? 120 : 150} style={{ alignSelf: "center" }} />
          </Stack>
        </Grow>
      </Grid>
      <Grid size={6}>
        {insulation?.installed && (
          <Grow in timeout={800}>
            <Stack spacing={2} flexGrow={1}>
              <NovoTextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                data-cy='insulation-year-input'
                label='Jahr der Dämmung'
                required={true}
                type='number'
                value={insulation?.year || ""}
                onChange={(e) => {
                  setInsulation?.({ ...insulation, year: +e.target.value });
                  setYearError?.(undefined);
                }}
                slotProps={{
                  htmlInput: { min: minYear, max: maxYear },
                }}
                error={!!yearError}
                helperText={yearError}
              />
              <NovoTextField
                fullWidth
                variant='outlined'
                id='insulation-thickness-input'
                data-cy='insulation-thickness-input'
                label='Dämmdicke'
                type='number'
                value={insulation?.thickness || ""}
                onChange={(e) => {
                  setInsulation?.({ ...insulation, thickness: +e.target.value });
                  setThicknessError?.(undefined);
                }}
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                  },
                  htmlInput: { min: 1, max: 35 },
                }}
                error={!!thicknessError}
                helperText={thicknessError}
              />
              <NovoSelect
                label={"Dämmmaterial"}
                dataCy={{ field: "insulation-material-select", values: Object.values(InsulationMaterials) }}
                options={[...Object.values(InsulationMaterials)]}
                value={insulation.material ? InsulationMaterials[insulation.material as keyof typeof InsulationMaterials] : ""}
                onUpdate={(value) => {
                  setInsulation?.({ ...insulation, material: ReverseInsulationMaterials[value as keyof typeof ReverseInsulationMaterials] });
                  setMaterialError?.(undefined);
                }}
                error={!!materialError}
                helperText={materialError}
              />
            </Stack>
          </Grow>
        )}
      </Grid>
    </Grid>
  );
}
