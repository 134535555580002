import { Link, LinkProps } from "@mui/material";
import { calendarBookingLink } from "./links";

export interface NovoBookAppointmentLinkProps extends LinkProps {
  tenantName?: string;
  children: React.ReactNode;
}

export const NovoBookAppointmentLink = ({ tenantName, children }: NovoBookAppointmentLinkProps) => {
  const link = calendarBookingLink(tenantName);

  return (
    <Link target='_blank' href={link} style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }}>
      {children}
    </Link>
  );
};
